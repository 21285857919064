export const demandIlks = [
    {
      value: "hire",
      label: "求职招聘",
    },
    {
      value: "transfer",
      label: "转让",
    },
    {
        value: "rent",
        label: "出租",
      }
  ]

export const demandTypeMap = {
    'hire':{
        types:[
            {
              value: "recruit",
              label: "全职",
              tags:['周末双休','五险','五险一金','带薪年假','餐补','房补','保底工资','底薪加提成','年终奖','弹性工作制'],
              yearList:[
                {
                    label: '不限',
                    value: '不限'
                },
                {
                    label: '1年',
                    value: '1年'
                },
                {
                    label: '1-3年',
                    value: '1-3年'
                },
                {
                    label: '3-5年',
                    value: '3-5年'
                },
                {
                    label: '5-10年',
                    value: '5-10年'
                },
                {
                    label: '10年以上',
                    value: '10年以上'
                },
                
            ],
            },
            {
              value: "recruit_p",
              label: "兼职",
              tags:['周末双休','五险','五险一金','带薪年假','餐补','房补','保底工资','底薪加提成','年终奖','弹性工作制'],
              yearList:[
                {
                    label: '不限',
                    value: '不限'
                },
                {
                    label: '1年',
                    value: '1年'
                },
                {
                    label: '1-3年',
                    value: '1-3年'
                },
                {
                    label: '3-5年',
                    value: '3-5年'
                },
                {
                    label: '5-10年',
                    value: '5-10年'
                },
                {
                    label: '10年以上',
                    value: '10年以上'
                },
                
            ],
            }
          ]
    },
    'transfer':{
        types:[
            {
              value: "t_store",
              label: "转店",
              tags:['临近地铁','居民密集','停车位','黄金地段','中央空调','新房','临街','扶梯','社区底商','可空转','暖气'],
              demandTypes:[
                {
                    value: '临街店面',
                    label: '临街店面'
                },
                {
                    value: '社区底商',
                    label: '社区底商'
                },
                {
                    value: '商业街店铺',
                    label: '商业街店铺'
                },
                {
                    value: '购物中心店面',
                    label: '购物中心店面'
                },
                {
                    value: '写字楼配套',
                    label: '写字楼配套'
                }
              ]
            },
            {
              value: "t_device",
              label: "转让设备",
              tags:[]
            }
          ]
    },'rent':{
        types:[
            {
              value: "rent",
              label: "店铺出租",
              tags:['临近地铁','居民密集','停车位','黄金地段','中央空调','新房','临街','扶梯','社区底商','可空转','暖气'],
              demandTypes:[
                {
                    value: '临街店面',
                    label: '临街店面'
                },
                {
                    value: '社区底商',
                    label: '社区底商'
                },
                {
                    value: '商业街店铺',
                    label: '商业街店铺'
                },
                {
                    value: '购物中心店面',
                    label: '购物中心店面'
                },
                {
                    value: '写字楼配套',
                    label: '写字楼配套'
                }
              ]
            }
          ]
    }
}
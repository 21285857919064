<template>
  <!-- 商品新增，修改 -->
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" :rules="rules" label-width="120px" ref="form">

            <el-form-item label="课程总价">
              {{needInfo.amount}}元
            </el-form-item>
           <el-form-item label="拍卖标题" required prop="title">
              <el-input
                v-model="form.title"
                placeholder="拍卖标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="价格类型" required prop="price_type">
                <el-select v-model="form.price_type">
                     <el-option v-for="(item,i) in auctionPriceTypeList" :key="i" :label="item.label" :value="item.value"></el-option>
                 </el-select>
            </el-form-item>
            <el-form-item label="报名保证金" required prop="join_bond">
              <el-input
                v-model="form.join_bond"
                placeholder="报名保证金"
              ></el-input>
              <span>元</span>
            </el-form-item>
            <el-form-item label="起拍价" required prop="start_price">
              <el-input
                v-model="form.start_price"
                placeholder="起拍价"
              ></el-input>
              <span>{{$getLabel(auctionPriceTypeList,form.price_type,"suf")}}</span>
            </el-form-item>
            <el-form-item label="加价幅度" required prop="add_step">
              <el-input
                v-model="form.add_step"
                placeholder="加价幅度"
              ></el-input>
               <span>{{$getLabel(auctionPriceTypeList,form.price_type,"suf")}}</span>
            </el-form-item>
            <el-form-item label="报名时间" required prop="joinTime">
              <el-date-picker
                v-model="form.joinTime"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="拍卖时间" required prop="auctionTime">
               <el-date-picker
                v-model="form.auctionTime"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="loading"
          >更 新</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";
import { addRule } from "./addRule.js";
import {auctionPriceTypeList} from '../need.js'
export default {
  components: {
    citySelect,
    cooperatorSelect,
  },
  data() {
    return {
      auctionPriceTypeList:auctionPriceTypeList,
      rules: addRule,
      form: {},
      temp: {},
      dialogVisible: false,
      loading:false,
      need_id:'',
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
      needInfo:{}
    };
  },

  mounted() {
    this.need_id = this.$route.query.id
  },

  methods: {
    submitForm(){
         this.$refs.form.validate((valid) => {
            if (valid) {

                this.form.join_start_time = this.form.joinTime[0]
                this.form.join_end_time = this.form.joinTime[1]

                this.form.auction_start_time = this.form.auctionTime[0]
                this.form.auction_end_time = this.form.auctionTime[1]

                if(this.form.id){
                    this.updateForm()
                }else{
                    this.addForm()
                }
            }
      });
    },


    addForm() {
      this.form.status = 1
          this.loading = true
          let data = {
                target_type : 'need',
                target_id : this.need_id,
                admin_id:this.adminInfo.id,
                admin_name:this.adminInfo.name,
                status:1,
                ...this.form
          }
          this.$axios({
            url: "/user/auction/insert",
            method: "post",
            data,
          }).then((res) => {
            this.$notify({
              message: "新增成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            data.id = res.data.data
            this.$emit("success", data);
          }).catch(()=>{
            this.loading = false
          })
    },

    updateForm() {
       const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/auction/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", this.form);
          }).catch(()=>{
            this.loading = false
          })
    },

    open(info,opt) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = Object.assign({}, info);
      } else {
        this.form = {};
        this.temp = {}
        if(opt.title){
            this.form.title = opt.title
        }
        this.needInfo = opt
      }
      this.dialogVisible = true;
    }
  },
};
</script>

<style lang="less" scoped>
</style>
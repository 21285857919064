<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
     <template #category="{ row }">
        <div>
          <p>
            {{row.apply.category}}{{row.apply.course_num}}节
             <span :style="{color:typeMap[row.apply.type].color || ''}">{{typeMap[row.apply.type].name}}</span>
          </p>
        </div>
      </template>
      <template #name="{ row }">
        <div>
          <p>{{ row.apply.name }}</p>
          <p>{{ row.apply.phone }}</p>
        </div>
      </template>
      <template #addr="{ row }">
        <div>
          <p>{{ row.apply.mechanism_name }}</p>
          <p>{{ row.apply.address }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag :hit="false" size="small" effect="plain" :type="statusMap[row.apply.status].tag">{{statusMap[row.apply.status].name}}</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="details(row)"
            >处理任务</el-button
          >
          <!-- <el-button type="primary" size="small" @click="mytask(row)"
            >mytask</el-button
          > -->
          
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
import {statusMap,typeMap} from './enum.js'
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "任务", value: "description" },
        { name: "课程", value: "category" ,type: "custom"},
        { name: "联系人", value: "name" ,type: "custom"},
        { name: "地址", value: "addr", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "接取时间", value: "claimTime" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      finished: false,
       managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
       statusMap:statusMap,
       typeMap:typeMap
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    details(row){
      this.$router.push({name:'applyFlowDetail',query:{id:row.apply.id}})
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/disApplyFlow/queryMyTask",
        params: {
          currentPage: page,
          pageSize: 10,
          admin_id:this.managerInfo.id
        },
      }).then((res) => {
         let list = res.data.data.rows.filter(t=>t.apply)
        this.DataList = list;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.type-dis{
  color: rgb(83, 163, 238);
}
.type-spl{
 color: rgb(243, 160, 64);
}
</style>
<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import { fontSize } from "@/js/utils";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: [],
      option: {
        color: [
          "#0CD2E6",
          "#3751E6",
          "#FFC722",
          "#886EFF",
          "#008DEC",
          "#114C90",
          "#00BFA5",
        ],
        title: {
            top: fontSize(0.1),
            text: '',
            textStyle: {
                fontSize: fontSize(0.14),
                color: '#DDEEFF',
            },
        },
        grid: {
          top: "10%",
          left: '10%',
          right: "1%",
          bottom: '5%',
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          top: "center",
          right: fontSize(0.5),
           itemWidth: fontSize(0.12),
          itemHeight: fontSize(0.12),
          textStyle: {
            align: "left",
            verticalAlign: "middle",
            rich: {
              name: {
                color: "rgba(255,255,255,0.8)",
                fontSize: fontSize(0.12),
              },
              value: {
                color: "rgba(255,255,255,1)",
                fontSize: fontSize(0.12),
              },
              rate: {
                color: "rgba(255,255,255,1)",
                fontSize: fontSize(0.12),
              },
            },
          },
          data: [],
          formatter: (name,v,i) => {
            if (this.data.length) {
              const item = this.data.filter((item) => item.name === name)[0];
              return `{name|${name}：}{value| ${item.value}} {rate| ${item.rate}%}`;
            }
          },
        },
        series: [
          {
            name: "占比",
            type: "pie",
            center: ["30%", "50%"],
            radius: fontSize(0.45),
            label: {
              normal: {
                show: false,
                position: "center",
                formatter: "{value|{c}}\n{label|{b}}",
                rich: {
                  value: {
                    padding: 5,
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: fontSize(0.2),
                  },
                  label: {
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: fontSize(0.12),
                  },
                },
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: fontSize(0.12),
                },
              },
            },
            labelLine: {
              show: false,
              length: 0,
              length2: 0,
            },
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.option.title.text = this.opt.title || ''
    if(this.opt.radius){
        this.option.series[0].radius = this.opt.radius
    }
    if(this.opt.center){
        this.option.series[0].center = this.opt.center
    }
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
      this.handlerData();
      this.myChart.setOption(this.option);
    },
    handlerData() { 
      let total = 0
      let serData = []
      this.data.forEach(t=>total += Number(t.value))
      this.data.forEach(t=>{
        if(total > 0){
          t.rate = (Number(t.value) / total *100).toFixed(0)
        }else{
          t.rate = 0
        }
        
        serData.push(t)
      })
      
      this.option.series[0].data = serData
      this.option.legend.data = this.data.map((t) => t.name);
    },
    getEchartData() {
      this.handlerData();
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
<style lang="less" scoped>
</style>
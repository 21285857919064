<template>
  <div class="container">
    <div>
      <el-descriptions :title="info.title">
        <el-descriptions-item label="当前价格"
          >{{ info.map.curPrice || info.start_price
          }}{{
            $getLabel(auctionPriceTypeList, info.price_type, "suf")
          }}</el-descriptions-item
        >
        <el-descriptions-item label="状态"><el-tag type="info" size="small" >{{
          $getLabel(auctionStatusList, info.status)
        }}</el-tag></el-descriptions-item>
        <el-descriptions-item label="报名"
          >{{ info.map.joinCount || 0 }}人</el-descriptions-item
        >
        <el-descriptions-item label="报名保证金">
          {{ info.join_bond }}元
        </el-descriptions-item>
        <el-descriptions-item label="加价幅度">
          {{ info.add_step }}
        </el-descriptions-item>
        <el-descriptions-item label="竞拍时间">
          {{ info.auction_start_time }}~{{ info.auction_end_time }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <el-descriptions title="拍品信息">
        <el-descriptions-item label="课程总价"
          >{{ needInfo.amount || "" }}元</el-descriptions-item
        >
        <el-descriptions-item label="课程类目">{{
          needInfo.categories || ""
        }}</el-descriptions-item>
        <el-descriptions-item label="人数">{{
          needInfo.connect_peoplenum || needInfo.max_class_num || "-"
        }}</el-descriptions-item>
        <el-descriptions-item label="总课节数">
          {{ needInfo.course_num || "" }}节
        </el-descriptions-item>
        <el-descriptions-item label="单课时长">
          {{ needInfo.length_of_lesson || "" }}分钟
        </el-descriptions-item>
        <el-descriptions-item label="上课时间">
          {{ needInfo.time_slot || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="地址">
          {{ needInfo.address || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          {{ needInfo.remark || "" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
        <div class="l-title">
            出价记录
        </div>
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in bidList"
          :key="index"
          :timestamp="item.create_time"
        >
          <div class="flex al-c" v-if="item.userInfo">
            <img :src="item.userInfo.avatar" class="user-avatar" />
            <div class="name">
              {{ item.userInfo.nick_name }}
            </div>
            <div class="bid">出价</div>
            <div class="amount">
              {{ item.price
              }}{{ $getLabel(auctionPriceTypeList, info.price_type, "suf") }}
            </div>
          </div>
        </el-timeline-item>
         <el-timeline-item :timestamp="info.auction_start_time">
            <div class="name">拍卖开始</div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { auctionStatusList, auctionPriceTypeList } from "./need.js";
export default {
  data() {
    return {
      auctionPriceTypeList: auctionPriceTypeList,
      auctionStatusList: auctionStatusList,
      id: "",
      info: {},
      bidList: [],
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getInfo();
    this.getBidRecord();
  },

  methods: {
    getBidRecord() {
      this.$get("/user/auctionBid/queryUserList", { auction_id: this.id }).then(
        (res) => {
          this.bidList = res.data.data || [];
        }
      );
    },
    getInfo() {
      this.$get("/user/auction/getInfo", { id: this.id }).then((res) => {
        let info = res.data.data;
        this.info = info;
        this.needInfo = info.map.target;
      });
    },
  },
};
</script>
<style scoped>
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.name {
  font-size: 14px;
  padding: 0 10px;
}
.bid {
  font-size: 14px;
  color: #999;
  margin-right: 10px;
}
.amount {
  font-size: 14px;
  color: rgb(245, 85, 85);
}
.l-title{
  font-size: 16px;
  color: #333;
  padding: 10px 0;
  font-weight: 600;
}
</style>
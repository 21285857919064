<template>
  <div class="container" style="position:relative;">
    <content-title :nav="nav"></content-title>
    <div class="part">
      <p class="part-title">课程基本信息</p>

      <el-descriptions>
        <el-descriptions-item label="课程名称">{{
          form.title
        }}</el-descriptions-item>
        <el-descriptions-item label="总课时数">{{
          form.course_num
        }}</el-descriptions-item>
        <el-descriptions-item label="体验课时数">{{
          form.settlement_course
        }}</el-descriptions-item>
        <el-descriptions-item label="课程类目">{{
          form.categories
        }}</el-descriptions-item>
        <el-descriptions-item label="服务方式">{{
          service_type
        }}</el-descriptions-item>
        <el-descriptions-item label="单课时长"
          >{{ form.length_of_lesson }}min</el-descriptions-item
        >
        <el-descriptions-item label="排课方式">{{
          form.appointment_type == 1 ? "预约" : "固定"
        }}</el-descriptions-item>
        <el-descriptions-item label="课程封面">
          <img :src="form.face_url" class="pic" v-if="form.face_url"
        /></el-descriptions-item>
        <el-descriptions-item label="课程图片">
            <div  v-if="form.introduction_url"><img
            v-for="(item, index) in form.introduction_url.split(',')"
            :key="index"
            :src="item"
            class="pic"
        /></div>
          </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="part">
      <p class="part-title">课程支付设置</p>
      <el-descriptions>
        <el-descriptions-item label="用户支付方式"
          >{{ form.pay_way==1? '单节付': '全额购' }}</el-descriptions-item
        >
        <el-descriptions-item label="用户保证课节数"
        v-if="form.pay_way==1"
          >{{ form.user_bond }}节</el-descriptions-item
        >
        <el-descriptions-item label="教练保证课节数" 
        v-if="form.pay_way==1">
        {{form.coach_bond }}节</el-descriptions-item>
        <el-descriptions-item
          label="可用券数量"
          v-if="form.pay_way==2"
          >{{ form.usable_coupon_num || 0}}张</el-descriptions-item
        >
      </el-descriptions>
    </div>
    <div class="part">
      <p class="part-title">课程价格信息</p>
      <el-descriptions>
        <el-descriptions-item label="课程价格"
          >{{form.amount_range || form.amount }}元</el-descriptions-item
        >
        <el-descriptions-item label="权益点价格"
          >{{ form.pay_point }}权益点</el-descriptions-item
        >
        <el-descriptions-item label="分成方式">{{
          form.settlement_type == 1 ? "固定金额" : "比例结算"
        }}</el-descriptions-item>
        <el-descriptions-item
          label="教练结算价"
          v-if="form.settlement_type == 1"
          >{{ form.coach_settlement_cash }}元</el-descriptions-item
        >
        <el-descriptions-item
          label="场馆结算价"
          v-if="form.settlement_type == 1"
          >{{ form.mechanism_settlement_cash }}元</el-descriptions-item
        >
        <el-descriptions-item
          label="单节结算价"
          v-if="form.settlement_type == 2"
          >{{ form.settlement_cash }}元</el-descriptions-item
        >
        <el-descriptions-item
          label="教练结算比例"
          v-if="form.settlement_type == 2"
          >{{ form.coach_profit }}</el-descriptions-item
        >
        <el-descriptions-item
          label="场馆结算比例"
          v-if="form.settlement_type == 2"
          >{{ form.mechanism_profit }}</el-descriptions-item
        >
      </el-descriptions>
    </div>
    <div class="part">
      <p class="part-title">课程接单信息</p>
      <el-descriptions>
        <el-descriptions-item label="上课时间段">{{
          form.time_slot
        }}</el-descriptions-item>
        <el-descriptions-item label="城市">{{
          form.city
        }}</el-descriptions-item>
        <el-descriptions-item label="上课地点">{{
          form.address
        }}</el-descriptions-item>
        <el-descriptions-item label="是否允许接单">{{
          form.is_receiving == 1 ? "允许" : "不允许"
        }}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{
          form.start_time
        }}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{
          form.end_time
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="part">
      <p class="part-title">其他课程信息</p>
      <el-descriptions>
        <el-descriptions-item label="班级人数"
          >{{ form.connect_peoplenum }}人</el-descriptions-item
        >
        <el-descriptions-item label="基础学习要求">{{
          base_list[form.study_base - 1].name
        }}</el-descriptions-item>
        <el-descriptions-item label="适用年龄">{{
          form.apply_age
        }}</el-descriptions-item>
        <el-descriptions-item label="面向人群">{{
          age_grade_list[form.age_grade].name
        }}</el-descriptions-item>
        <el-descriptions-item label="课程简介">{{
          form.introduction_content
        }}</el-descriptions-item>
        <el-descriptions-item label="课程详情">{{
          form.introduction_text
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          form.remark
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
<!--  教练 -->
    <div class="part">
      <p class="part-title">教练信息</p>
      <div v-if="!coachInfo.id" class="noInfo">暂无教练</div>
      <div v-else>
        <el-tooltip class="item" effect="dark" content="点击头像可查看详情">
        <div class="flex coach-card">
          <img :src="coachInfo.head_portrait" class="avatar" @click="toCoachDetail" />
          <div class="user-info flex-col jc-e">
            <div style="font-size:16px">{{coachInfo.real_name}}&nbsp;&nbsp;{{coachInfo.sex==1?'男':'女'   }}</div>
            <div>教龄：{{coachInfo.teaching_age}}年</div>
            <div>类目：{{coachInfo.teaching_categories}}</div>
          </div>
        </div>
        </el-tooltip>
      </div>
    </div>
<!-- 用户 -->
    <div class="part">
      <p class="part-title">上课用户</p>
      <div v-if="userList.length == 0" class="noInfo">暂无信息</div>
      <div v-else>
        <div v-for="user in userList" :key="user.User_id" class="flex user-card">
          <img :src="user.avatar" class="avatar" />
          <div class="user-info flex-col jc-e">
            <div style="font-size:18px">{{user.nick_name}}</div>
            <div>{{user.mobile}}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <div class="btns flex"  v-if="form.status == 0">
        <el-button
              type="success"
              @click="update(form, 1)"
              >通过</el-button
            >
            <el-button
              type="danger"
              @click="refuse(form, 2)"
              style="margin-left:20px;"
              >拒绝</el-button
            >
    </div>

 
  </div>
</template>
    <script>
export default {
  data() {
    return {
      nav: { firstNav: "需求管理中心", secondNav: "课程需求详情" },
      readOnly: false,
      form: {},
      cityoptions: [],
      categories_child_list: [],
      age_grade_list: [
        { name: "不限人群", value: 0 },
        { name: "成人", value: 1 },
        { name: "学龄前", value: 2 },
        { name: "学龄", value: 3 },

        { name: "亲子", value: 4 },
      ],
      region: "",
      ms: "false",
      cityProps: {
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      imageUrl3: "",
      background_image: "",
      isClear: true,
      if_clear: true,
      city: "",
      base_list: [
        { name: "零基础", value: 1 },
        { name: "初入门", value: 2 },
        { name: "中级水平", value: 3 },
        { name: "高级水平", value: 4 },
      ],
      id: "",
      settlement_type: "1",
      appointment_type: "1",
      temp: {},
      userList: [],
      coachInfo:{}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getCourseInfo();
    }
  },
  computed: {
    service_type(){
 
      switch (this.form.service_type) {
        case 1:
          return '海马场地'
      case 2:
          return '上门教学'
      case 3:
          return '自有场地'
      }
       
    },
    settlement_Price() {
      let num = (this.form.course_num - (this.form.settlement_course || 0)) ;
      if (this.form.settlement_type == 1) {
        return (
          num *
          (parseInt(this.form.coach_settlement_cash) +
            parseInt(this.form.mechanism_settlement_cash))
        );
      } else {
        return num * parseInt(this.form.settlement_cash);
      }
    },

    profitCheck() {
      if (this.form.settlement_type == 2) {
        if (this.form.settlement_cash == 0) {
          this.$message({ message: "单节结算价不能为0" });
          return false;
        }
        if (
          Number(this.form.mechanism_profit) +
          Number(this.form.coach_profit) >
          1
        ) {
          this.$message({ message: "结算比例不能大于1" });
          return false;
        }else{
      return true;

        }
      }
      return true;
    },
    priceCheck() {
      let num = (this.form.course_num - (this.form.settlement_course || 0)) ;
      if (this.form.settlement_type == 1) {
        if (
          num *
            (Number(this.form.coach_settlement_cash) +
            Number(this.form.mechanism_settlement_cash)) >
          this.form.amount
        ) {
          return false;
        }
      } else {
        if (num * parseInt(this.form.settlement_cash) > this.form.amount) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    // 教练信息
    getCoachInfo() {
      let url = "/user/coach/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.form.coach_id
          },
        })
        .then((res) => {
          this.coachInfo = res.data.data;
        });
    },

    toCoachDetail(){
      this.$router.push({name:'coachDetails',query:{id:this.coachInfo.id}})
    },
    //审核
    update(row, status) {
      if (!this.priceCheck) {
        this.$message({ message: "结算总价不能大于课程价格" });
        return;
      }
      if (!this.profitCheck) {
        return;
      }
      if (this.form.pay_way == 1 && this.form.user_bond < 1) {
        this.$message({ message: "保证课节数不能为0" });
        return;
      }
      if (this.form.pay_way == 1 && this.form.cocah_bond < 1) {
        this.$message({ message: "保证课节数不能为0" });
        return;
      }
      if (this.form.service_type == 1 && this.form.mechanism_profit <= 0 ) {
        this.$message({ message: "请填写机构结算比例" });
        return;
      }
      let url = "/user/userCourseNeed/update";
      let data = {
        id: row.id,
        status,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.$router.back()
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
     //审核
     refuse(row, status) {
      let url = "/user/userCourseNeed/update";
      let data = {
        id: row.id,
        status,
      };
      this.$axios({
        url,
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ message: "成功" });
          this.$router.back()
        } else {
          this.$message({ message: res.data.message });
        }
      });
    },
    //班级学员
    getClassStuList() {
      this.$axios({
        url: "/user/userStudyCard/queryInfo",
        params: {

          mechanism_class_id: this.form.class_id,

        },
      }).then((res) => {
        this.userList = res.data.data;
      });
    },
    //需求用户信息列表
    getUserIdsInfo() {
      let url = "/user/userNeedConnected/query";
      this.$axios
        .get(url, {
          params: {
            need_id: this.id,
          },
        })
        .then((res) => {
          this.userList = res.data.data;
        });
    },
    // 课程需求信息
    getCourseInfo() {
      let url = "/user/userCourseNeed/findById";
      let params = {
        id: this.id,
      };
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
        
          this.form = res.data.data;
          this.temp = { ...res.data.data };
          this.cityList = [
            this.form.province,
            this.form.city,
            this.form.district,
          ];
          if(this.form.class_id > 0){
            this.getClassStuList()
          }else{
             this.getUserIdsInfo();
          }
          this.form.coach_id &&  this.getCoachInfo()
        });
    },
  },
};
</script>
    
    <style scoped lang="less">
.btns {
  padding: 10px 0 20px 30px;
  width: 100%;
  height: 70px;
  bottom: 0;
  background-color: #fff;
  justify-content: flex-end;
  box-sizing: border-box;
}
.title {
  padding: 4px 30px 0px 5px;
  font-size: 20px;
  margin-bottom: 0;
}
.row {
  width: 100%;
}
.part {
  background-color: #fff;
  padding: 0 30px;
}
.part-title {
  box-sizing: border-box;
  line-height: 50px;
  font-size: 16px;
  border-bottom: 1px solid rgb(226, 220, 220);
  margin-bottom: 20px;
  font-weight: 600;
}
.w80 {
  width: 500px !important;
}
.user-card{
    max-width: 260px;
    overflow: hidden;
    margin-left: 20px;
    margin-bottom: 10px;
    .avatar{
        width: 40px;
        height: 40px;
        border-radius:50% ;
    }
.user-info{
    margin-left: 10px;
    font-size: 16px;
}
}
.coach-card{
  width: fit-content;
    overflow: hidden;
    margin-left: 20px;
    margin-bottom: 10px;
    .avatar{
        width: 60px;
        height: 60px;
        border-radius:50% ;
    }
  .user-info{
      margin-left: 10px;
      font-size: 14px;
  }
}
.pic{
    width: 70px;
    height: 70px;
}
</style>
<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import * as echarts from "echarts";
import { fontSize } from "@/js/utils";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: ["#FFC722", "#886EFF", "#008DEC", "#114C90", "#00BFA5"],
      myChart: "",
      serData: [],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        title: {
          top: fontSize(0.1),
          text: "",
          textStyle: {
            fontSize: fontSize(0.14),
            color: "#DDEEFF",
          },
        },
        legend: {
          align: "right",
          right: '50%',
          top: fontSize(0.1),
          textStyle: {
            color: "#fff",
          },
          itemWidth: fontSize(0.12),
          itemHeight: fontSize(0.12),
          itemGap: fontSize(0.20),
        },
        grid: {
          left: "5%",
          right: "4%",
          bottom: "5%",
          top: fontSize(0.43),
          containLabel: true,
        },
        yAxis: [
          {
            type: "category",
            data: [],
            axisLine: {
              lineStyle: {
                color: "#00c7ff",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
          },
        ],
        xAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
               fontSize: fontSize(0.12),
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            data: [],
            barWidth: fontSize(0.12),
            barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#008cff",
                  },
                  {
                    offset: 1,
                    color: "#005193",
                  },
                ]),
                opacity: 1,
              },
            },
             label: {
              normal: {
                color: "#fff",
                show: true,
                position:['101%',0],
                textStyle: {
                  fontSize: fontSize(0.12),
                },
              },
            },
          },
          {
            name: "",
            type: "bar",
            data: [],
           barWidth: fontSize(0.12),
            barGap: 1, //柱子之间间距
            label: {
              normal: {
                color: "#fff",
                show: true,
                position:['101%',0],
                textStyle: {
                  fontSize: fontSize(0.12),
                },
              },
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#00da9c",
                  },
                  {
                    offset: 1,
                    color: "#007a55",
                  },
                ]),
                opacity: 1,
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.option.title.text = this.opt.title || "";
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
      this.handlerData();
      this.myChart.setOption(this.option);
    },
    handlerData() {
      this.option.xAxis[0].data = this.data.x;
      this.option.series[0].data = this.data.y[0].data;
      this.option.series[0].name = this.data.y[0].name;
      this.option.series[1].data = this.data.y[1].data;
      this.option.series[1].name = this.data.y[1].name;
    },
    getEchartData() {
      this.handlerData();
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="less" scoped>
.a {
  color: #a71a2b;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},_vm._l((_vm.typeOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.toAdd}},[_vm._v("发布")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(_vm._s(row.name))]),_c('p',[_vm._v(_vm._s(row.phone))])])]}},{key:"info",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"introduction"},[_vm._v(_vm._s(row.title))])])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getByValue(row.type,'typeOption'))+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tag',{attrs:{"type":_vm.options[row.status].type}},[_vm._v(_vm._s(_vm.getByValue(row.status,'options')))])],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status == 0)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.toDetails(row)}}},[_vm._v("审核")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.toUpdate(row)}}},[_vm._v("编辑")]),(row.status != 0)?_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.toDetails(row)}}},[_vm._v("详情")]):_vm._e(),(row.status == 4)?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.updateStatus(row,3)}}},[_vm._v("上架")]):_vm._e(),(row.status == 3)?_c('el-button',{attrs:{"type":"warning","size":"small"},on:{"click":function($event){return _vm.updateStatus(row,4)}}},[_vm._v("下架")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
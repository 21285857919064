<template>
  <div>
    <el-button
      type="primary"
      @click="openDialog()"
      >添加子类目</el-button
    >
     <el-button
      type="success"
      @click="refresh"
      >同步</el-button
    >
    <el-table
      :data="typeList"
      :header-cell-class-name="'table-header'"
      v-loading="is_loading"
      row-key="id"
      :tree-props="{ hasChildren: 'hasChildren', children: 'childList' }"
    >
      >

      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="名称" prop="name"> </el-table-column>s
      <el-table-column label="图片" prop="">
        <template slot-scope="scope">
          <el-image :src="scope.row.pic" style="width: 3rem"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="排序" prop="weights"> </el-table-column>
      <el-table-column label="添加时间" prop="create_time"></el-table-column>
      <el-table-column label="更新时间" prop="update_time"></el-table-column>

      <el-table-column label="操作" prop="" width="180px">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="if_delete(scope.row)"
            >删除</el-button
          >
          <el-button type="warning" size="small" @click="edit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="typeTotal"
      :size="10"
      :current-page="currentPage"
      @current-change="changeCurrentPage"
    >
    </el-pagination>

    <!-- 新增 -->
    <el-dialog title="添加类目" :visible.sync="addVisible" width="40%">
      <div class="dialog-body">
        <el-form :model="form" id="new-good-form" :rules="rules">
          <el-form-item label-width="8rem" label="分类名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="排序" prop="categories_child">
            <el-input
              v-model="form.weights"
              placeholder="值越大排序越前"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="图片">
            <avatar-uploader
              @getImgUrl="(v) => (form.url = v)"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label-width="8rem" label="类目级别">
            <el-radio v-model="form.type" :label="1">一级类目</el-radio>
            <el-radio v-model="form.type" :label="2">二级类目</el-radio>
          </el-form-item>
          <el-form-item label-width="8rem" label="父类目" v-if="form.type == 2">
            <el-select
              v-model="form.parent_id"
            >
              <el-option
                v-for="item in addTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="addNewType()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改类目 -->
    <el-dialog title="修改类目" :visible.sync="updateVisible" width="40%">
      <div class="dialog-body">
        <el-form :model="form" id="new-good-form" :rules="rules">
          <el-form-item label-width="8rem" label="类目名称" prop="name">
            <el-input v-model="form.name" :placeholder="temp.name"></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="类目图片" prop="name">
            <avatar-uploader
              @getImgUrl="(v) => (form.url = v)"
              :url="temp.pic"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label-width="8rem" label="排序" prop="categories_child">
            <el-input
              v-model="form.weights"
              placeholder="值越大排序越前"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="update()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      temp: {},
      is_loading: false,
      if_clear: false,
      addVisible: false,
      updateVisible: false,
      bindVisible: false,
      deleteId: null,
      confirmDelete: false,
      currentPage: 1,
      typeTotal: 0,
      typeList: [],
      categories_list: [],
      form: {},
      addTypeList: [],
      rules: {},
      haveCate: [],
    };
  },

  mounted() {},

  methods: {
    refresh(){
       let url = "/user/mechanismCategory/refreshData";
          this.$axios({url, params:{
           type: 2,
           source: this.source,
        }})
        .then((res) => {
           this.$message({ type: "warning", message: "同步成功!" });
            this.getTypeList();
        });
    },
    if_delete(row) {
      this.temp = row;
      this.$confirm('确认删除类目吗？').then(()=>{
        this.deleteType()
      })
    },
    deleteType() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.temp.id,
          status: 1,
        })
        .then((res) => {
           this.$message({ type: "warning", message: "删除成功!" });
            this.getTypeList();
        });
    },
    load() {
      this.getTypeList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getTypeList();
    },
    openDialog() {
      this.addVisible = true;
    },
    addNewType() {
      this.if_clear = false;
      let data = {
        name: this.form.name ? this.form.name : null,
        parent_id: this.form.parent_id ? this.form.parent_id : null,
        pic: this.form.url,
        type: this.form.type,
        status: 2,
         source: this.source,
      };
      let url = "/user/mechanismCategory/insert";
      this.$axios.post(url, data).then((res) => {
        this.$message({ type: "success", message: "添加成功!" });
        this.form = {};
        this.addVisible = false;
        this.if_clear = this.if_clear;
        this.refresh();
      });
    },
    getTypeList() {
      this.is_loading = true;
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 1,
            source: this.source,
          },
        })
        .then((res) => {
          this.is_loading = false;
          this.typeList = res.data.data;
          this.addTypeList = res.data.data
        });
    },
    update() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.temp.id,
          name: this.form.name ? this.form.name : null,
          pic: this.form.url ? this.form.url : null,
          weights: this.form.weights ? this.form.weights : null,
        })
        .then((res) => {
          this.$message({ type: "success", message: "更新成功!" });
          this.temp = {};
          this.form = {}
          this.updateVisible = false;
          this.refresh();
        });
    },
    edit(row) {
      this.updateVisible = true;
      this.temp = Object.assign({},row);
      this.form = Object.assign({},row);
    },
  },
};
</script>
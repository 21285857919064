export const memberTypeList = [
    {
      value: "1",
      label: "海马会员卡",
    },
    {
      value: "2",
      label: "天赋卡",
    },
    {
        value: "3",
        label: "聊天卡",
      },
      {
        value: "4",
        label: "定制卡",
      },
      {
        value: "5",
        label: "汇合空间用户会员卡",
      },
      {
        value: "6",
        label: "空间管理端会员",
      },
      {
        value: "7",
        label: "空间管理端超级会员",
      }
  ]

  
<template>
  <div class="container" >
     <div v-if="disInfo.id">
        <div class="m-t20">
        <el-descriptions title="课程信息详情" border>
      <template slot="extra">
          <el-button type="success" v-if="disInfo.status < 2" @click="openEdit">编辑信息</el-button>
        </template>
          
        <el-descriptions-item label="课程类型">
            {{ disInfo.category }}
            <span :style="{color:typeMap[disInfo.type].color || ''}">{{typeMap[disInfo.type].name}}</span>
        </el-descriptions-item>
         <el-descriptions-item label="课节数">
          {{ disInfo.course_num }}节
        </el-descriptions-item>
        <el-descriptions-item label="用户报价">
            <div v-if="disInfo.price_min === disInfo.price_max">
                {{ disInfo.price_min }}
            </div>
            <div v-else>
                {{ disInfo.price_min }}-{{ disInfo.price_max }}
            </div>
        </el-descriptions-item>
         <el-descriptions-item label="上课方式">
           {{teachTypeMap[disInfo.teach_type].name}}
        </el-descriptions-item>
        </el-descriptions>
        </div>

      <div class="m-t20">
            
      <el-descriptions title="机构信息" border >
         <el-descriptions-item label="城市">
          {{ disInfo.city }} {{ disInfo.district }}
        </el-descriptions-item>
        <el-descriptions-item label="场馆名称">
          {{ disInfo.mechanism_name }}
        </el-descriptions-item>
        <el-descriptions-item label="场馆地址">
          {{ disInfo.address }}
        </el-descriptions-item>
      </el-descriptions>
      </div>
      <div class="m-t20">
            
       <el-descriptions title="服务信息" >
        <el-descriptions-item label="提交人">
          {{ disInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="联系电话">
          {{ disInfo.phone }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
           <el-tag effect="plain" :type="statusMap[disInfo.status].tag">{{statusMap[disInfo.status].name}}</el-tag>
        </el-descriptions-item>
         <el-descriptions-item label="提交时间">
          {{ disInfo.create_time }}
        </el-descriptions-item>
        <el-descriptions-item label="备注说明">
          <p class="remark">
            {{ disInfo.remark }}
          </p>
          
        </el-descriptions-item>
      </el-descriptions>
       <div class="flex" v-if="disInfo.images">
          <el-image
          v-for="(p,i) in disInfo.images.split(',')" :key="i"
            style="width: 120px; height: 120px"
            :src="p"
             :preview-src-list="disInfo.images.split(',')"
           ></el-image>
        </div>
       </div>
    </div>


    <div>
      <div class="h-t">
        匹配课程
      </div>
      <div class="flex course" v-if="course.id">
        <img :src="course.face_url" class="c-img">
        <div>
          <div class="c-title">
            {{course.title}}
          </div>
           <div>
           	<span class="c-tips">
						{{course.categories_child}}
            </span>
            <span class="c-tips" v-if="course.school_age">
              {{course.school_age}}
            </span>
            <span class="c-tips" v-if="course.course_num">
              {{course.course_num}}节
            </span>
            <span class="c-tips" v-if="course.connect_peoplenum">
              {{course.connect_peoplenum}}人班
            </span>
          </div>
          <div>
            {{ course.venues_addr || course.mechanism_addr ||  course.mechanism_name || ''}}
          </div>
        </div>
      </div>
      <div v-else>
        暂未匹配课程
      </div>
    </div>

    <div class="m-t20">
        <el-steps :active="active" align-center>
            <el-step title="用户提交" description="请海马顾问认领任务，与机构核实价格"></el-step>
            <el-step title="核价中" description="正在与机构商谈，砍价、拆单节中"></el-step>
            <el-step title="核价完成" description="商谈完成"></el-step>
            <el-step title="生成课包" description="请生成课包并绑定"></el-step>
            <el-step title="用户购买" description="用户购买支付"></el-step>
        </el-steps>
        <div class="m-t20">
             <el-button v-if="disInfo.status === 0" type="primary" @click="claim()">认领核价任务</el-button>
             <el-button v-if="disInfo.status === 1" type="success" @click="checkSuccess">核价成功</el-button>
             <el-button v-if="disInfo.status === 1" type="danger" @click="openRefuse">核价失败</el-button>
             <el-button v-if="disInfo.status === 2" type="primary" @click="claim()">认领匹配课程任务</el-button>
        </div>
    </div>
    <setCourse @suc="getData" v-if="disInfo.status > 3" :apply="disInfo"></setCourse>

     <refusePop :show.sync="refuseShow" @confirm="refuseConfrim"></refusePop>

     <edit ref="edit" @success="successEdit"></edit>
  </div>
</template>

<script>
import refusePop from '@/components/refusePop/refusePop.vue';
import {statusMap,typeMap,teachTypeMap} from './enum.js'
import setCourse from './components/setCourse/setCourse.vue';
import edit from './components/edit/edit.vue';
export default {
   components:{
      refusePop,
      setCourse,
      edit
  },
  data() {
    return {
        refuseShow:false,
        id:'',
        disInfo:{},
        statusMap:statusMap,
        typeMap:typeMap,
        teachTypeMap:teachTypeMap,
        managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
        active:0,
        course:{}
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.getData()
  },

  methods: {
    successEdit(v){
      this.disInfo = v
    },
    openEdit(){
        this.$refs.edit.open(this.disInfo)
    },
     claim(){
      this.$confirm('确认认领任务吗').then(()=>{
            let data = {
                apply_id:this.id,
                admin_id:this.managerInfo.id,
                admin_name:this.managerInfo.name
            }
            this.$post('/user/disApplyFlow/claimTask',data).then(res=>{
                this.$alert('任务认领成功，请尽快与机构商谈相关信息，任务可在我的任务中查看')
                this.getData()
            })
      })
    },
    checkSuccess(){
        if(!this.disInfo.category){
          this.$message('请填写类目和报价信息')
          return
        }

        this.$confirm('确认价格核实完成').then(()=>{
           let data = {
            apply_id:this.id,
            admin_id:this.managerInfo.id,
            res:true,
        }
            this.$post('/user/disApplyFlow/checkDis',data).then(res=>{
                this.$alert('请尽快绑定或生成课包')
                this.getData()
            })
      })
    },
    openRefuse(){
        this.refuseShow = true
    },
    refuseConfrim(text){
        this.refuseShow = false
        let data = {
            apply_id:this.id,
            admin_id:this.managerInfo.id,
            res:false,
            refuse:text
        }
         this.$post('/user/disApplyFlow/checkDis',data).then(res=>{
             this.$notify({
                title: '提交成功',
                message: '核价失败，服务结束',
                type: 'success'
            });
            this.$router.back()
        })
    },
     getTags(){
      let tag = JSON.parse(this.disInfo.tags || "{}")
      let vs =  Object.entries(tag)
      let text = ''
      vs.forEach(item=>{
        let l = item[0] + ':' + item[1] + ' '
        text = text + l
      })
			return text
    },
     updateStatus(status){
        this.$post('/shared/sharedSpace/update',{id:this.id,status}).then(res=>{
          this.$message('成功')
          this.disInfo.status = status
        })
    },
    getData(){
         this.$get('/user/discountApply/findById',{id:this.id}).then(res=>{
            this.disInfo = res.data.data
            if(this.disInfo.course){
              this.course = this.disInfo.course
            }
            if(this.disInfo.status == 0){
                this.active = 0
            }else  if(this.disInfo.status == 1){
                this.active = 1
            }else if(this.disInfo.status == 2){
                this.active = 3
            }else if(this.disInfo.status == 3){
                this.active = 3
            }else if(this.disInfo.status == 4 ){
                this.active = 4
            }else{
                this.active = 4
            }
        })
    }
  },
};
</script>
<style lang="less" scoped>
.type-dis{
  color: rgb(83, 163, 238);
}
.type-spl{
 color: rgb(243, 160, 64);
}
.remark{
  color: rgb(255, 136, 0);
}

.h-t{
  font-size: 16px;
    font-weight: 700;
    padding: 20px 0;
}
.course{
  padding: 20px;
  background-color: #F4f4f4;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  .c-img{
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
  .c-title{
      font-size: 16px;

  }
  .c-tips{
      font-size: 13px;
      color:#666;
      margin-right: 10px;
  }
}
</style>
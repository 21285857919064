<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="商品名称">
        <el-input v-model="query.name" placeholder="输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="query.type">
          <el-option
            v-for="(item, i) in typeList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status">
          <el-option
            v-for="(item, i) in statusList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>{{ $getLabel(typeList,row.type) }}</div>
      </template>
      <template #ilk="{ row }">
        <div>{{ $getLabel(ilkList,row.ilk) }}</div>
      </template>
      <template #status="{ row }">
        <el-tag type="info" v-if="row.status === 0">待上架</el-tag>
        <el-tag type="success" v-if="row.status === 1">上架中</el-tag>
        <el-tag type="warning" v-if="row.status === 2">已下架</el-tag>
      </template>
      <template #handle="{ row }">
        <div v-if="row.type != 'consume'">
           <el-button size="small" type="primary" @click="update(row)"
          >编辑</el-button
        >
        <el-button
          size="small"
          v-if="row.status != 1"
          type="success"
          @click="updateStatus(row, 1)"
          >上架</el-button
        >
        <el-button
          size="small"
          v-if="row.status === 1"
          type="warning"
          @click="updateStatus(row, 2)"
          >下架</el-button
        >
        </div>
       
      </template>
    </auto-table>
  </div>
</template>

<script>
import { typeList, statusList ,ilkList} from "./components/type.js";
export default {
  data() {
    return {
      nav: { firstNav: "商品管理", secondNav: "商品列表" },
      DataList: [],
      query: {
        name: "",
        type: "",
      },
      typeList: typeList,
      ilkList: ilkList,
      statusList: statusList,
      Option: [
        { name: "名称", value: "name" },
        { name: "售价", value: "min_price" },
        { name: "类型", value: "type", type: "custom" },
        { name: "已售", value: "sale_num" },
        { name: "状态", value: "status", type: "custom" },
        { name: "上架模块", value: "ilk", type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    updateStatus(row, status) {
      this.$axios({
        url: "/user/product/update",
        method: "post",
        data: {
          id: row.id,
          status,
        },
      }).then((res) => {
        this.getList(1);
      });
    },

    add() {
      this.$router.push({ name: "createProduct" });
    },
    update(row) {
      this.$router.push({ name: "createProduct", query: { id: row.id } });
    },

    //记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/product/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          name: this.query.name || null,
          status: this.query.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="vis-container">
    <div class="vis-head">
        <div class="vis-title-wrap">
            <span class="vis-head-title">海马运动中心</span>
        </div>
      <div class="vis-head-icons">
        <i class="el-icon-full-screen head-icon" @click="toggleFull"></i>
      </div>
    </div>
    <div class="vis-main">
      <div class="vis-grid">
        <div class="vis-left">
            <!--  -->
            <div class="vis-box-l">
                <div class="flex jc-b al-c">
                    <div class="box-title">用户数据 </div>
                    <div class="total-text">总:{{userCount['total'] || 0}}</div>
                    
                </div>
                <div>
                    <ul class="count-ul-wrap">
                        <li class="count-li">
                            <div class="li-count-name">
                                在线用户
                            </div>
                            <div class="li-count-value">
                                {{userCount['onlineCount'] || 0}}
                            </div>  
                        </li>
                          <li class="count-li">
                            <div class="li-count-name">
                                新增用户
                            </div>
                            <div class="li-count-value">
                                 {{userCount['newCount'] || 0}}
                            </div>  
                        </li>
                          <li class="count-li">
                            <div class="li-count-name">
                                 新增会员
                            </div>
                            <div class="li-count-value">
                                 {{userCount['member'] || 0}}
                            </div>  
                        </li>
                    </ul>
                </div>

                <div style="height:11rem">
                     <pieEchartsVue ename="uc" :data="userSourceData" :opt="{title:'用户来源',radius:['30%','60%']}"></pieEchartsVue>
                </div>
                <div style="height:11rem">
                     <barRankEchart :data="cityOrderData" ename="cityOrder" :opt="{title:'热门城市收入(元)'}"></barRankEchart>
                </div>

            </div>
           <!--  -->
            <div class="vis-box-s">
                <div class="flex jc-b al-c">
                    <div class="box-title">收入数据</div>
                    
                </div>
                <div class="flex">
                    <div style="height:12rem;width:45%">
                         <rowBarSmall ename="mainorder" :data="mainOrderData" :opt="{title:'收入类型'}"></rowBarSmall>
                    </div>
                     <div style="height:12rem;width:55%">
                         <lineEchart ename="ordertime" :data="orderTimeData" :opt="{title:'收入数据'}"></lineEchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="vis-center">
            <!-- 数据统计 -->
            <div class="c-top-board">
                <div class="c-top-item" v-for="(item,i) in topData" :key="i">
                <p class="c-top-name">{{item.name}}</p>
                <p class="c-top-value">{{item.value}}</p>
                </div>
            </div>
            
            <!-- 地图 -->
            <div class="map-wrap">
                <div class="map-in-wrap">
                 <div class="map-tab">
                       <eTabs :list="timeTabs" :current="current" @change="timeTabChange"></eTabs>
                </div>
                  <mapEcharts ref="map" :query="queryParam" height="26rem" :opt="{title:'课程数量'}"></mapEcharts>
                </div>
             
            </div>
             <!--  -->
            <div class="vis-box-row">
                <div class="flex jc-b al-c">
                    <div class="box-title">场地数据</div>
                    <div class="total-text">总:{{venueCount['total'] || 0}}</div>
                    
                </div>
                <div class="flex">
                    <div style="height:12rem;width:50%">
                         <rowBarEchart ename="vcity" :data="venueCityData" :opt="{title:'各地区新增数量'}"></rowBarEchart>
                    </div>
                     <div style="height:12rem;width:50%">
                         <lineEchart ename="vtime" :data="venueTimeData" :opt="{title:'场地新增数量'}"></lineEchart>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="vis-right">
           <!--  -->
            <div class="vis-box-l">
                <div class="flex jc-b al-c">
                    <div class="box-title">教练数据</div>
                    <div class="total-text">总:{{coachCount['total'] || 0}}</div>
                    
                </div>
                <div>
                    <ul class="count-ul-wrap">
                        <li class="count-li">
                            <div class="li-count-name">
                                新增教练
                            </div>
                            <div class="li-count-value">
                                {{coachCount['new'] || 0}}
                            </div>  
                        </li>
                          <li class="count-li">
                            <div class="li-count-name">
                                在线教练
                            </div>
                            <div class="li-count-value">
                                 {{coachCount['online'] || 0}}
                            </div>  
                        </li>
                          <li class="count-li">
                            <div class="li-count-name">
                                新增会员
                            </div>
                            <div class="li-count-value">
                                 {{coachCount['member'] || 0}}
                            </div>  
                        </li>
                    </ul>
                </div>

                <div style="height:11rem">
                     <barRankEchart :data="coachCityData" ename="cc" :opt="{title:'各地区新增数量'}"></barRankEchart>
                </div>
                <div style="height:11rem">
                     <line-echart :data="coachTimeData" ename="ct" :opt="{title:'教练新增数量'}"></line-echart>
                </div>

            </div>
           <!--  -->
            <div class="vis-box-s">
                <div class="flex jc-b al-c">
                    <div class="box-title">联营社数据</div>
                    
                </div>
                  <div style="height:12rem">
                    <barRankEchart :data="unionData" ename="union" :opt="{title:'各地数量'}"></barRankEchart>
                </div>
                 
            </div>
           
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toggleFullScreen,isFullScreen} from '../../js/fullScreen.js'
import pieEchartsVue from './components/pieEcharts.vue';
import rowBarEchart from './components/rowBarEchart.vue';
import barRankEchart from './components/barRankEchart.vue';
import eTabs from './components/eTabs/index.vue';
import mapEcharts from './components/mapEcharts.vue';
import rowBarSmall from './components/rowBarSmall.vue';
import lineEchart from './components/lineEchart.vue';

export default {
  components: {
    mapEcharts,
    pieEchartsVue,
    rowBarEchart,
    lineEchart,
    rowBarSmall,
    barRankEchart,
    eTabs,
    
  },
  data() {
    return {
        userSourceData:[],
        cityOrderData:{},
        mainOrderData:[],
        orderTimeData:{},
        topData: [],
        coachCityData:{},
        coachTimeData:{},
        coachCount: {},
        userCount: {},
        venueCityData: [],
        venueTimeData: {},
        unionData:{x:['杭州','南昌','深圳','上海'],y:[{data:[0,0,0,0]}]},
        start_time: this.getCurrentMonthFirst(),
        end_time: new Date().Format("yyyy-MM-dd"),
        queryParam: {
             start_time:this.getCurrentMonthFirst() + " 00:00:00",
            end_time:  new Date().Format("yyyy-MM-dd") + " 23:59:59",
            mode:'day'
        },
        venueCount:{},
        current:2,
        timeTabs:[{name:'今日'},{name:'本周'},{name:'本月'},{name:'今年'}],
        isFull:false,
    }
  },

  mounted() {
    this.initData()
  },
  methods: {
    initData(){
         this.getUser_count()
        this.getUserSource()
        this.getOrderPriceEcByCity()
        this.getOrderPriceEcByTime()
        this.getMainOrderPriceEc()
        this.getAppointmentCountData()
        this.getCoachData()
        this.getVenuestatData()

    },
    timeTabChange(i){

        switch (this.timeTabs[i].name) {
        case "今日":
          this.start_time = new Date().Format("yyyy-MM-dd");
          this.queryParam.mode = 'hour'
          break;
        case "本周":
          this.start_time = this.getNowWeek();
           this.queryParam.mode = 'day'
          break;
        case "本月":
          this.start_time = this.getCurrentMonthFirst();
           this.queryParam.mode = 'day'
          break;
        case "本年":
          this.start_time = new Date().Format("yyyy-01-01");
           this.queryParam.mode = 'moth'
          break;
        default:
          break;
      }

       this.queryParam.start_time = this.start_time + " 00:00:00"
       this.initData()
       this.$refs.map.getMapValues()

    },
     //本月
    getCurrentMonthFirst() {
      var date = new Date();
      return new Date(date.setDate(1)).Format("yyyy-MM-dd");
    },
    // 获取本周
    getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      for (let i = 1 - curDay; i < 8 - curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        week[data.week] = data;
      }
      return week[1].time;
    },
    //注册用户统计
    getUser_count() {
      let url = "user/user/userNewAndOnline";

      let params = {
        ...this.queryParam
      };
      
      this.$get(url,params).then((res) => {
        this.userCount = res.data.data;
      });
    },
    //用户注册来源
     getUserSource() {
      let url = "/user/stat/userSource";
      let params = {
       ...this.queryParam
      };
      this.$get(url,params).then((res) => {
        let {count,name} = res.data.data
       
        let list = []
        for(let i = 0; i<name.length; i++){
            list.push({
                name:name[i],
                value:Number(count[i]) 
            })
        }
        this.userSourceData = list
      });
    },
    //城市订单数据
    getOrderPriceEcByCity() {
      let url = "/user/stat/orderPriceEc";
      let params = {
       ...this.queryParam,
       group_by : 'city',
       is_income : true
      };
      this.$get(url, params).then((res) => {
        let {names,values} = res.data.data
        if(names.length == 0){
            names = ['杭州','上海','北京','南昌']
            values = [0,0,0,0]
        }

        this.cityOrderData = {
            x:names,
            y:[{data:values}]
        }
       
      });
    },
    //各时间段订单数据
     getOrderPriceEcByTime() {
      let url = "/user/stat/orderPriceEcTime";
      let params = {
        ...this.queryParam,
        is_income : true
      };
      this.$get(url, params).then((res) => {
        let {names,values} = res.data.data

        if(names.length == 0){
            names = ['10:00','14:00','16:00','18:00']
            values = [0,0,0,0]
        }

        this.orderTimeData = {
            x:names,
            y:{data:values}
        }
        
      });
    },
    //主要订单类型数据
     getMainOrderPriceEc() {
      let url = "/user/stat/mainOrderPriceEc";
      let params = {
        ...this.queryParam,
        group_by : 'city',
        is_income : true
      };
      this.$get(url, params).then((res) => {
        let {names,values} = res.data.data

         let list = []
        for(let i = 0; i<names.length; i++){
            list.push({
                name:names[i],
                value:Number(values[i]) 
            })
        }
        this.mainOrderData = list
      });
    },
    //课程数据
      getAppointmentCountData() {
      let url = "/user/stat/appointCountData";
      let params = {
       ...this.queryParam
      };
      this.$get(url, params).then((res) => {
        let info = res.data.data
        this.topData = [{name:'课程预约数量',value:info.total},{name:'已上课程',value:info.end},{name:'待上课程',value:info.total - info.end},{name:'已结算金额',value:info.account}]
      });
    },


    //场地数据
     getVenuestatData() {
      let url = "/shared/sharedVenue/statData";
      let params = {
        ...this.queryParam
      };
      this.$get(url, params).then((res) => {
        this.venueCount.total =  res.data.data.total
        let cityData = res.data.data.cityData
          if(cityData.names.length == 0){
            cityData.names = ['杭州','上海','北京','南昌']
            cityData.values = [0,0,0,0]
        }
         let list = []
         cityData.names.forEach((t,i)=>{
            list.push({
                name: t,
                value:Number(cityData.values[i]) 
            })
         })
       
        this.venueCityData = list

        let timeData = res.data.data.timeData

          if(timeData.names.length == 0){
            timeData.names = ['10:00','14:00','16:00','18:00']
            timeData.values = [0,0,0,0]
        }
        this.venueTimeData = {
            x: timeData.names,
            y: {data: timeData.values}
        }


      });
    },
    //教练数据
     getCoachData() {
      let url = "/user/stat/coachData";
      let params = {
       ...this.queryParam
      };
      this.$get(url, params).then((res) => {
        let info = res.data.data
        this.coachCount = {
            total: info.total,
            new: info.new,
            online: info.online,
            member: info.member
        }
        let cityData = res.data.data.cityData
         if(cityData.names.length == 0){
            cityData.names = ['杭州','上海','北京','南昌']
            cityData.values = [0,0,0,0]
        }
        this.coachCityData = {
            x: cityData.names,
            y: [{data: cityData.values}]
        }

        let timeData = res.data.data.timeData
          if(timeData.names.length == 0){
            timeData.names = ['10:00','14:00','16:00','18:00']
            timeData.values = [0,0,0,0]
        }
        this.coachTimeData = {
            x: timeData.names,
            y: {data: timeData.values}
        }


      });
    },

    toggleFull(){
        toggleFullScreen()
    },

  },
};
</script>
<style lang="less" scoped>
.vis-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
   background-image: url("../../assets/visualizing/bgpic.png");
  background-size: 100vw 100vh;
  overflow: hidden;
}

.vis-head {
  text-align: center;
  position: relative;
  height: 4rem;
  background-image: url("../../assets/visualizing/header.png");
  background-size: 100vw 4rem;
  background-repeat: no-repeat;
}
.vis-title-wrap{
    text-align: center;
}
.vis-head-title {
  font-weight: bold;
  font-size: 2rem;
  line-height: 4rem;
  color: transparent;
  background: linear-gradient(5deg, #00A8FF 0%, #00EAFF 48%, #00A8FF 100%);
  background-clip: text;
  width: fit-content;
}

.vis-head-icons{
    position: absolute;
    top: 0.5rem;
    right: 2rem;
}
.head-icon{
    color: #19D6FF;
    font-size: 1.5rem;
    margin-right: 1rem;
}
.vis-main{
    margin: 0 auto;
    box-sizing: border-box;
    height: calc(100vh - 4rem);
    width: calc(100vw - 4rem);
    position: absolute;
    // background-color: #fff;
    position: relative;
}
.vis-grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.vis-left {

}
.vis-center {
  padding: 0 1.7rem;
}
.vis-right {
 
}

.vis-box {
}

.mtop{
    margin-top: 1rem;
}
.s-height{
    height: 6.5rem;
}

.vis-box-l{
    background-image: url("../../assets/visualizing/line-border.png");
  background-size: 100% 30rem;
  height: 30rem;
  background-repeat: no-repeat;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
}
.vis-box-s{
      background-image: url("../../assets/visualizing/line-border.png");
  background-size: 100% 16rem;
  height: 16rem;
  background-repeat: no-repeat;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-top: 1rem;
}
.vis-box-row{
      background-image: url("../../assets/visualizing/box-row-border.png");
  background-size: 100% 16rem;
  height: 16rem;
  background-repeat: no-repeat;
  color: #fff;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-top: 1rem;
}


.box-title {
  background-image: url("../../assets/visualizing/title-r.png");
  background-size: 12rem 2rem;
  height: 2rem;
  width: 12rem;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 0.86rem;
  font-weight: 600;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  line-height: 2.8 ;
}



.coach-count-wrap{
    display: flex;
    justify-content: center;
}
.coach-item{
    text-align: center;
    width: 40%;
}
.coach-item + .coach-item{
    border-left: 1px solid #19D6FF;
}
.coach-name{
    font-size: 0.7rem;
    color: #E4EDF7;
}
.coach-value{
    font-size: 0.9rem;
    color: #3FD0FF;
}

.time-line-wrap{
    margin-top: 0.5rem;
    height: 15rem;
    margin-bottom: 0.5rem;
}


.count-ul-wrap{
    width: 85%;
    height: 4rem;
    background: linear-gradient(0deg, rgba(22,207,251,0) 0%, #012D83 100%);
    border: 1px solid #5d83b4;
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin: 0 auto;
    .count-li{
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 3.2rem;
    }
    .count-li + .count-li{
        border-left: 1px solid #5d83b4;
    }
    .li-count-name{
        font-size: 0.7rem;
        color: #B9C3D6;
    }
    .li-count-value{
        font-size: 1.1rem;
        color: #3FD0FF;
    }
}




.user-count-wrap{
    display: flex;
     justify-content: space-evenly;
}

.user-item{
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-count-icon{
    background-image: url("../../assets/visualizing/uc-icon.png");
    background-size: 3.5rem 3rem;
    width: 3.5rem;
    height: 3rem;
    margin-right: 0.3rem;
}
.user-count-name{
    font-size: 0.7rem;
    color: #E4EDF7;
    line-height: 1.8;
}
.user-count-value{
   font-size: 1.1rem;
    color: #3FD0FF;
}


.c-top-board{
    background-image: url("../../assets/visualizing/c-top.png");
    background-size: 100% 7rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 7rem;
    align-items: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    margin-top: -2rem;
}
.c-top-item{
    text-align: center;
}
.c-top-name{
    font-weight: 400;
    font-size: 1rem;
    color: #3FD0FF;
}
.c-top-value{
    font-size: 1.2rem;
    color: #E4EDF7;
}

.income-wrap{
    background-image: url("../../assets/visualizing/c-bg1.png");
    background-size: 100% 7rem;
    width: 100%;
    height: 7rem;
    margin-top: 0.5rem;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
}
.income-item{
    background-image: url("../../assets/visualizing/circle-bottom-tip.png");
    background-size: 6rem 3rem;
    background-repeat: no-repeat;
    background-position: center 100%;
    height: 5rem;
    text-align: center;
    flex: 1;
}
.income-name{
    font-size: 1rem;
    color: #E4EDF7;
    line-height: 1.5;
}
.income-value{
    font-weight: 500;
    font-size: 1.25rem;
    color: #19D6FF;
    text-shadow: 1px 0 #bef3ff;
    letter-spacing: 0.1rem;
}

.map-wrap{
    background-image: url("../../assets/visualizing/map-bg.png");
    background-size: 100% 25rem;
    width: 100%;
    height: 25rem;
    box-sizing: border-box;
    position: relative;
}
.map-in-wrap{
      background-image: url("../../assets/visualizing/mapbg2.png");
    background-size: 100% 25rem;
    width: 100%;
    height: 25rem;
    box-sizing: border-box;
    position: relative;
}
.map-tab{
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 20;
}

.room-data-wrap{
    background-image: url("../../assets/visualizing/c-bg-big.png");
    background-size: 100% 20rem;
    width: 100%;
    height: 20rem;
    margin-top: 0.2rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    overflow: hidden;
}
.room-head{
    margin-bottom: 0.5rem;
}
.room-grid{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-auto-rows: 8rem;
    column-gap: 1rem;
    row-gap: 0.5rem;
}
.room-info{
    background-clip: border;
    border: 1px solid #A5FFFF;
    border-radius: 10px;
    padding: 0.8rem;
    box-sizing: border-box;
    justify-content: space-between;
}
.room-name{
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 0.9rem;
    color: #fff;
}
.room-desc{
    font-size: 0.7rem;
    color: #c4c4c4;
    text-align: center;
}
.room-desc + .room-desc{
    margin-top: 0.3rem;
}

.room-text1{
    font-size: 1.2rem;
    color: #06F7FF;
    font-weight: 500;
}
.room-text2{
    font-size: 1.2rem;
    color: #4DE3B1;
    font-weight: 500;
}

.ell{
       overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;

}


.attend-item{
    background-image: url("../../assets/visualizing/icon1.png");
    background-size: 5rem 5rem;
    background-repeat: no-repeat;
    height: 5rem;
    width: 5rem;
    position: relative;
}
.a-icon2{
     background-image: url("../../assets/visualizing/icon2.png");
}
.a-icon3{
  background-image: url("../../assets/visualizing/icon3.png");
}
.attend-info{
    position: absolute;
    top: 0;
    left: 3rem;
    width: 8rem;
}
.attend-name{
    font-weight: 500;
    font-size: 0.9rem;
    color: #E4EDF7;
    line-height: 1.5;
}
.attend-value{
    font-size: 0.8rem;
    color: #E4EDF7;
}

.attend-wrap{
    background-size: 100% 5rem;
    width: 100%;
    height: 5rem;
    margin-top: 1.3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

.hot-room-wrap{
    height: 13rem;
}


.today-course-wrap{
    background-image: url("../../assets/visualizing/attend-bg.png");
    background-size: 100% 5rem;
    background-repeat: no-repeat;
    width: 100%;
    height: 5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.today-total{
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: 600;
    padding-left: 1.6rem;
    margin-right: 1rem;
}
.today-info{
    flex: 1;
    text-align: center;
}
.today-name{
    font-size: 0.75rem;
    margin-bottom: 0.2rem;
    color: #FFFFFF;
}
.today-value{
    font-size: 1.1rem;
    color: #19D6FF;
}

.e-title{
    font-size: 0.8rem;
    color: #DDEEFF;
    font-weight: bold;
}

.total-text{
    color: #fff;
  font-size: 0.86rem;
  font-weight: 600;
  padding-right: 1rem;
}

</style>
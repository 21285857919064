export const platformList = [
    {
      value: "all",
      label: "全部平台",
    },
    {
      value: "coach",
      label: "教练端",
    },
    {
      value: "mechanism",
      label: "机构端",
    },
    {
        value: "space",
        label: "汇合空间",
      },
  ]

 export const  typeList = [
    {
      value: "notice",
      label: "资讯",
    },
    {
      value: "publice",
      label: "公告",
    },
  ]

  export const  ilkList = [
    {
      value: "index",
      label: "首页公告栏",
    },
    {
      value: "cert",
      label: "考证咨询",
    },
    {
        value: "promote",
        label: "培训提升",
      },
      {
        value: "clearService",
        label: "场地清理",
      },
      {
        value: "insure",
        label: "保险",
      },
      {
        value: "business",
        label: "商务合作",
      },
      {
        value: "storeCert",
        label: "开店办证",
      },
      {
        value: "cross",
        label: "交叉用户",
      }
  ]



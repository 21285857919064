
// 检查是否处于全屏模式的函数
export function isFullScreen() {
    return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
  }
  
  // 进入或退出全屏的函数
 export function toggleFullScreen() {
    let element = document.documentElement
  //   let element = document.getElementById('ms-test')
    if (!isFullScreen()) {
      // 如果不是全屏，则尝试进入全屏
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { // Safari
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    } else {
      // 如果已经是全屏，则退出全屏
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
    }

    // const event = new Event('resize', { bubbles: true, cancelable: true })
    // window.dispatchEvent(event)
  }
  
<template>
  <div class="container">
     <el-tabs tab-position="left" v-model="activeName">
        <el-tab-pane name="info"  label="基本信息">
            <infoEdit :info="info" @next="infoNext"></infoEdit>
        </el-tab-pane>
        <el-tab-pane name="sku"  label="价格信息">
            <skuEdit :info="skus" @next="skuNext"></skuEdit>
        </el-tab-pane>
         <el-tab-pane name="spec"  label="商品参数">
            <specEdit :info="specs" @next="specNext"></specEdit>
        </el-tab-pane>
        <el-tab-pane name="platform"  label="上架设置">
            <platformEdit :info="info" @save="saveProduct"></platformEdit>
        </el-tab-pane>
     </el-tabs>
  </div>
</template>

<script>
import infoEdit from './components/info/infoEdit.vue';
import platformEdit from './components/info/platformEdit.vue';
import skuEdit from './components/info/skuEdit.vue';
import specEdit from './components/info/specEdit.vue';
export default {
components:{
    infoEdit,
    skuEdit,
    platformEdit,
    specEdit
},
  data() {
    return {
      activeName:'info',
      infoData:{},
      skuData:[],
      platformData:{},
      specData:[],
      id:'',
      info:{},
      skus:[],
      specs:[]
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.id && this.getInfo()
  },

  methods: {
    getInfo(){
        this.$get('/user/product/queryInfo',{id:this.id}).then(res=>{
            this.info = res.data.data
            if(this.info.skus){
                this.skus = [...this.info.skus]
            }
             if(this.info.specs){
                this.specs = [...this.info.specs]
            }
        })
    },
    saveProduct(v){
      if(!this.infoData.cover_img || !this.infoData.name){
        this.$message('请填写商品信息')
        return
      }

      if(this.skuData.length == 0){
        this.$message('请填写商品价格信息')
        return
      }
      
        this.platformData = v.data
        let data = {
            skus:this.skuData,
            specs:this.specData,
            ...this.infoData,
            ...this.platformData
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$axios({
            url: "/user/product/save",
            method: "post",
            data,
          }).then((res) => {
             loading.close();
            this.$notify({
              message: "新增成功",
              duration: 2000,
              type: "success",
            });
           this.$router.back()
          }).catch(()=>{
             loading.close();
          })
    },
    infoNext(v){
        this.activeName = v.next
        this.infoData = v.data
    },
    specNext(v){
         this.activeName = v.next
        this.specData = v.data
    },
    skuNext(v){
        this.activeName = v.next
        this.skuData = v.data
    }
  },
};
</script>

<style lang="less" scoped>
</style>
<template>
  <!-- 商品新增，修改 -->
  <div>
    <div class="m-b10">
      <el-alert
        title="商品的参数信息，点击'添加项'可新增一条商品参数信息"
        type="warning"
        :closable="false"
      >
      </el-alert>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item label="商品参数" v-for="(item, i) in formList" :key="i">
        <div class="flex">
          <div class="m-r10">
            <el-input
              v-model="item.param_name"
              placeholder="参数名称"
            ></el-input>
          </div>

          <div class="m-r10">
            <el-input
              v-model="item.param_value"
              placeholder="参数值"
            ></el-input>
          </div>

          <div class="m-r10 flex al-c">
            <el-form-item label="" v-if="id">
              <el-button type="success" @click="updateForm(item)"
                >更新数据</el-button
              >
            </el-form-item>
            <i class="el-icon-delete del" @click="delItem(i, item)"></i>
          </div>
        </div>
      </el-form-item>
      <div class="add-wrap">
        <el-button type="primary" @click="add">添加项 + </el-button>
      </div>

      <el-form-item label="" v-if="!id">
        <el-button type="success" @click="next">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      formList: [
        {
          param_name: "品牌",
          param_value: "",
        },
        {
          param_name: "规格大小",
          param_value: "",
        },
      ],
      form: {},
      loading: false,
      id: "",
    };
  },
  watch: {
    info: {
      handler: function (nv) {
        if (nv.length > 0) {
          this.formList = [...nv];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    delItem(i, item) {
      if (item.id) {
        this.$confirm("确认删除吗？").then((res) => {
          let url = "/user/productSpec/delete";
          this.$get(url, { id: item.id }).then((res) => {
            this.formList.splice(i, 1);
          });
        });
      } else {
        this.formList.splice(i, 1);
      }
    },
    add() {
      this.formList.push({
        param_name: "",
        param_value: "",
      });
    },
    next() {
      let v = this.formList.filter((t) => {
        if (t.param_name && t.param_value) {
          return true;
        }
      });
      this.$emit("next", { next: "platform", data: v });
    },
    updateForm(item) {
      if (!item.param_name || !item.param_value) {
        this.$message("请填写信息");
        return;
      }
      let url = "/user/productSpec/insert";
      let params = {
        pid: this.id,
        param_name: item.param_name,
        param_value: item.param_value,
      };
      if (item.id) {
        url = "/user/productSpec/update";
        params.id = item.id;
      }

      this.$axios({
        url: url,
        method: "post",
        data: params,
      }).then((res) => {
        if (!item.id) {
          item.id = res.data.data;
        }
        this.$notify({
          message: "更新成功",
          duration: 2000,
          type: "success",
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.del {
  font-size: 20px;
  color: rgb(233, 98, 98);
  margin-left: 15px;
}
.del:hover {
  color: red;
  font-size: 26px;
}
.add-wrap {
  padding: 20px 0 40px 400px;
}
</style>
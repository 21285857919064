<template>
  <div class="container">
    <div class="submit">
      <el-button type="primary" @click="addNotice" v-if="id"
        >修改信息</el-button
      >
      <el-button type="primary" @click="addNotice" v-else>发布信息</el-button>
    </div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-row>
        <el-col :span="4">
          <el-form-item label="类型" required>
            <el-select v-model="form.ilk" placeholder="请选择" @change="changeIlk">
              <el-option
                v-for="item in ilks"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> 
           </el-form-item
        ></el-col>
        <el-col :span="4">
          <el-form-item label="类目" required>
            <el-select v-model="form.type" placeholder="请选择" @change="changeType">
              <el-option
                v-for="item in types"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
          
      </el-row>
      <el-form-item label="标题" required>
        <el-row>
          <el-col :span="24">
            <el-input
              v-model="form.title"
              placeholder="填写标题"
            ></el-input
          ></el-col>
        </el-row>
      </el-form-item>

    <el-row v-if="form.ilk == 'hire'">
          <el-col :span="5">
            <el-form-item label="类目" required>
                 <el-select
              v-model="form.categories"
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            </el-form-item>
         </el-col>
         <el-col :span="5">
            <el-form-item label="工作机构" required>
              <mechanismSelect @change="changeMec"></mechanismSelect>
            </el-form-item>
         </el-col>
         <el-col :span="6">
          
            <el-form-item label="薪资范围" required>
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="min"
                    placeholder="薪资范围"
                    ></el-input> 
                    -<el-input
                    type='number'
                    v-model="max"
                    placeholder="薪资范围"
                    ></el-input>K
                 </div>   
            </el-form-item>
         </el-col>
         <el-col :span="5">
          <el-form-item label="工作经验" required>
                <el-select v-model="form.time_slot" placeholder="请选择">
              <el-option
                v-for="item in yearList "
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
         </el-col>
    </el-row>


     <el-row v-else-if="form.type == 't_device'">
          <el-col :span="5">
            <el-form-item label="转让费用" required>
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="form.amount"
                    placeholder="转让费用"
                    ></el-input>元
                 </div>   
            </el-form-item>
         </el-col>
          <el-col :span="5">
            <el-form-item label="原价">
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="form.amount_range"
                    placeholder="原价"
                    ></el-input>元
                 </div>   
            </el-form-item>
         </el-col>
    </el-row>

     <el-row v-else-if="form.type == 't_store'">
          <el-col :span="5">
            <el-form-item label="店铺类型" required>
                <el-select v-model="form.demand_type" placeholder="请选择">
              <el-option
                v-for="item in demandTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
         </el-col>
         <el-col :span="5">
            <el-form-item label="店铺面积" required>
               <div class="flex">
                <el-input
                    type='number'
                    v-model="form.demand_num"
                    placeholder="填写店铺面积"
                    ></el-input>m²
               </div>
            </el-form-item>
         </el-col>
         <el-col :span="5">
          
            <el-form-item label="店铺租金" required>
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="form.amount"
                    placeholder="店铺租金"
                    ></el-input>/月
                 </div>   
            </el-form-item>
         </el-col>
         <el-col :span="5">
          
            <el-form-item label="转让费" required>
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="form.amount_range"
                    placeholder="填0为面谈"
                    ></el-input>元
                </div>
                
            </el-form-item>
         </el-col>
    </el-row>

    <el-row v-else-if="form.type == 'rent'">
          <el-col :span="5">
            <el-form-item label="店铺类型" required>
                <el-select v-model="form.demand_type" placeholder="请选择">
              <el-option
                v-for="item in demandTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            </el-form-item>
         </el-col>
         <el-col :span="5">
            <el-form-item label="店铺面积" required>
               <div class="flex">
                <el-input
                    type='number'
                    v-model="form.demand_num"
                    placeholder="填写店铺面积"
                    ></el-input>m²
               </div>
            </el-form-item>
         </el-col>
         <el-col :span="5">
          
            <el-form-item label="店铺租金" required>
                <div class="flex">
                    <el-input
                    type='number'
                    v-model="form.amount"
                    placeholder="店铺租金"
                    ></el-input>/月
                 </div>   
            </el-form-item>
         </el-col>
    </el-row>


    <el-row>
          <el-col :span="5">
            <el-form-item label="城市" required>
                <city-select @change="changeCity" :temp="cityList"></city-select>
            </el-form-item>
         </el-col>
         <el-col :span="5">
             <el-form-item label="详细地址" required>
              <el-input v-model="form.address">
                <addr-select
                  slot="append"
                  @getform="changeMap"
                  type="icon"
                ></addr-select>
              </el-input>
            </el-form-item>
         </el-col>
          <el-col :span="4">
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="form.longitude" placeholder="经度longitude"></el-input>
            </el-form-item>
         </el-col>
          <el-col :span="4">
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="form.latitude" placeholder="纬度latitude"></el-input>
            </el-form-item>
         </el-col>
         
    </el-row>

    <el-row>
          <el-col :span="5">
            <el-form-item label="联系人" required>
                <el-input
                    v-model="form.name"
                    placeholder="联系人称呼"
                    ></el-input>
            </el-form-item>
         </el-col>
         <el-col :span="5">
            <el-form-item label="联系电话" required>
                <el-input
                    v-model="form.phone"
                    placeholder="填写联系电话"
                    ></el-input>
            </el-form-item>
         </el-col>
    </el-row>


      <el-form-item label="详情" required>
        <el-row>
          <el-col :span="24">
            <el-input
              type="textarea"
              placeholder="填写详细说明信息"
              maxlength="400"
              show-word-limit
               :rows="4"
              v-model="form.introduction"
            ></el-input
          ></el-col>
        </el-row>
      </el-form-item>
    
       <el-form-item label="标签">
        <el-tag
        @click="changeTag(tag)"
        v-for="(tag,i) in tags"
        :key="i"
        :effect="selectTags.includes(tag) ? 'dark' : 'plain' ">
            {{ tag }}
        </el-tag>
      </el-form-item>

        <el-form-item label="图片" required>
        <images-uploader
                @getImgUrl="(v) => (form.introduction_url = v)"
                :urls="temp.introduction_url"
              >
              </images-uploader>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
import {demandIlks,demandTypeMap} from './demandMap.js'
import mechanismSelect from '../../components/select/mechanismSelect.vue';

export default {
    components:{
mechanismSelect
    },
  data() {
    return {
      ilks:demandIlks,
      types:[],
      demandTypes:[],
      curTypeMap:{},
      cityList:[],
      temp: {},
      form: {},
      selectTags:[],
      tags:[],
      manager: JSON.parse(localStorage.getItem("managerInfo")),
      mode: "add",
      id: "",
      yearList:[],
      categories_child_list:[],
      min:'',
      max:''
    };
  },
  mounted() {
    this.getCateList()
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.mode = "edit";
      this.getInfo();
    } else {
      this.mode = "add";
    }
  },

  methods: {
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "课程",
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    //选择标签
    changeTag(v){
            const i = this.selectTags.indexOf(v)
            if(i == -1){
                this.selectTags.push(v)
            }else{
                this.selectTags.splice(i,1)
            }
		},
    //选择类型
    changeIlk(v){
        this.types = demandTypeMap[v].types
    },
    //选择类目
    changeType(v){
       for(let i =0;i<this.types.length;i++){
            let type = this.types[i]
            if(type.value == v){
                this.demandTypes = type.demandTypes || []
                this.yearList = type.yearList || []
                this.tags = type.tags || []
            }
       }
    },
    //选择城市
  changeCity(v) {
      this.cityList = v;
      this.form.province = v[0];
      this.form.city = v[1];
      this.form.district = v[2];
    },
     changeMap(addr) {
      if (addr.address) {
        this.$set(this.form, "province", addr.province);
        this.$set(this.form, "city", addr.city);
        this.$set(this.form, "district", addr.district);
        this.$set(this.form, "address", addr.address);
        this.cityList = [];
        this.cityList.push(addr.province);
        this.cityList.push(addr.city);
        this.cityList.push(addr.district);
      }

      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
    },
    changeMec(v){
        this.form.mechanism_id = v.id
        this.$set(this.form, "province", v.province);
        this.$set(this.form, "city", v.city);
        this.$set(this.form, "district", v.district);
        this.$set(this.form, "address", v.mechanism_addr);
        this.$set(this.form, "latitude", v.latitude);
        this.$set(this.form, "longitude", v.longitude);
        this.cityList = [];
        this.cityList.push(v.province);
        this.cityList.push(v.city);
        this.cityList.push(v.district);
    },
    getInfo() {
      this.$axios({
        url: "/user/demandInfo/findById",
        params: { id: this.id },
      }).then((res) => {
        this.temp = Object.assign({}, res.data.data);
        this.form = res.data.data;
        this.changeIlk(this.temp.ilk)
        this.changeType(this.temp.type)
        this.selectTags = this.temp.tags.split(',')
         this.cityList = [];
        this.cityList.push(this.temp.province);
        this.cityList.push(this.temp.city);
        this.cityList.push(this.temp.district);
        if(this.form.ilk == 'hire'){
            let range = this.form.amount_range.replace('k','')
            this.min = range.split('-')[0]
             this.max = range.split('-')[1]
        }
      });
    },

    updateNotice() {
      this.form.tags = this.selectTags.join(',')
      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      data.id = this.id;
        this.$axios.post("/user/demandInfo/update", data).then(() => {
          this.$message("修改成功");
          this.$router.back();
        });
    },
    addNotice() {
      if (this.check()) {
        if(this.form.ilk == 'hire'){
            this.form.amount_range = `${this.min}-${this.max}k`
        }

        if(this.id){
            this.updateNotice()
            return
        }

        let data = {
          ...this.form,
           admin_name:this.manager.name,
            admin_id:this.manager.id,
            status:3,
            tags:this.selectTags.join(',')
        };
        this.$axios.post("/user/demandInfo/insert", data).then(() => {
          this.$message("添加成功");
          this.$router.back();
        });
      }
    },
    check() {
      if (!this.form.type) {
        this.$message("请选择类型");
        return false;
      }
      if (!this.form.title) {
        this.$message("请填写标题");
        return false;
      }
       if (!this.form.name) {
        this.$message("请填写联系人");
        return false;
      }
       if (!this.form.phone) {
        this.$message("请填写电话");
        return false;
      }
      if (this.form.ilk=='hire' && (!this.min || !this.max)) {
        this.$message("请填写薪资范围");
        return false;
      }
      if (['transfer','rent'].includes(this.form.ilk) && !this.form.introduction_url) {
        this.$message("请上传图片");
        return false;
      }
       if (!this.form.introduction) {
        this.$message("请填写详情");
        return false;
      }
       
      return true;
    },
  },
};
</script>
<style scoped>
.container {
  position: relative;
}
.submit {
  position: absolute;
  right: 50px;
  top: 30px;
  z-index: 9999;
}
.wang {
  height: 500px;
}
</style>
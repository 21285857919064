var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.auctionStatusList),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v(" 起拍："+_vm._s(row.start_price))]),_c('p',[_vm._v(" 加价幅度"+_vm._s(row.add_step))])])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$getLabel(_vm.targetTypeList,row.target_type))+" ")])]}},{key:"activity_time",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v("开始:"+_vm._s(row.auction_start_time))]),_c('p',[_vm._v("结束:"+_vm._s(row.auction_end_time))])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v(" "+_vm._s(_vm.$getLabel(_vm.auctionStatusList,row.status)))])],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.toDetail(row)}}},[_vm._v("详情")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
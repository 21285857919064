<template>
  <div class="vis-container">
    <div class="vis-head">
      <div class="vis-head-title">海马运动中心</div>
      <div class="vis-head-icons">
        <i class="el-icon-full-screen head-icon" @click="toggleFull"></i>
      </div>
    </div>
    <div class="vis-main">
      <div class="vis-grid">
        <div class="vis-left">
          <!-- 区域信息 -->
          <div class="vis-box">
            <div class="box-title">区域信息</div>
            <div class="area-item" v-for="(item,i) in areaData" :key="i">
               <p class="area-name">{{item.name}}</p>
               <p class="area-value">{{item.value}}</p>
            </div>
          </div>
           <!-- 热门区域 -->
          <div class="vis-box mtop">
            <div class="box-title">热门区域</div>
            <div class="area-ec">
                <rowBarEchart ename="areacount" :data="areaRowbarData"></rowBarEchart>
            </div>
          </div>
          <!-- 学员构成 -->
          <div class="vis-box mtop">
            <div class="box-title">学员构成</div>
            <div class="s-height">
                <pieEchartsVue ename="page" :data="ageData" :opt="{title:'人群分布'}"></pieEchartsVue>
            </div>
            <div class=" s-height">
                <sexBarEchart ename="sex" :data="sexData" :opt="{title:'性别分布'}"></sexBarEchart>
            </div>
          </div>
        </div>

        <div class="vis-center">
            <!-- 数据统计 -->
            <div class="c-top-board">
                <div class="c-top-item" v-for="(item,i) in topData" :key="i">
                <p class="c-top-name">{{item.name}}</p>
                <p class="c-top-value">{{item.value}}</p>
                </div>
            </div>
            <!-- 区域状态 -->
            <div class="room-status-wrap">
                <div class="status-tips">
                    <div v-for="(item,i) in roomStatus" :key="i" class="status-item flex al-c">
                        <div class="status-circle " :class="'status'+item.value"></div>
                        <div>{{item.name}}</div>
                    </div>
                </div>

                <div class="room-grid">
                    <div class="room-info flex al-c jc-c" v-for="(item,i) in roomList" :key="i">
                        <div class="status-circle " :class="'status'+item.value"></div>
                        <div class="ell">{{item.name}}</div>
                    </div>
                </div>
            </div>

            <!-- 区域地图 -->
            <div class="room-map-wrap">
                <div class="room-map-pic"></div>
            </div>
        </div>

        <div class="vis-right">
            <!-- 通知信息 -->
          <div class="vis-box">
            <div class="box-title">通知信息</div>
            <div class="note-wrap">
                <vue-seamless-scroll :data="noteList" :classOption="seamlessOption">
                    <div class="note-row" v-for="(item,i) in noteList" :key="i">
                        <div class="note-point"></div>
                        <div class="note-time">{{timeFormat()}}</div>
                        <div class="note-info">{{item.name}}</div>
                    </div>
                 </vue-seamless-scroll>
            </div>
          </div>

           <!-- 今日课程 -->
          <div class="vis-box mtop">
            <div class="box-title">今日课程</div>
            <div class="course-wrap">
                <vue-seamless-scroll :data="courseInfoList" :classOption="seamlessOption">
                    <div class="course-row" v-for="(item,i) in courseInfoList" :key="i">
                       <div class="course-info">
                            <div class="c-name">
                                {{item.name}}
                            </div>
                            <div class="c-desc">
                                教师：{{item.coach}}
                            </div>
                             <div class="c-desc">
                                时间：{{item.time}}
                            </div>
                       </div>
                       <div class="course-status">上课中</div>
                    </div>
                 </vue-seamless-scroll>
            </div>
          </div>

            <!-- 今日课程 -->
          <div class="vis-box">
            <div class="box-title">今日预约</div>
            <div class="appo-wrap">
                <vue-seamless-scroll :data="courseInfoList" :classOption="seamlessOption">
                    <div class="appo-row" v-for="(item,i) in courseInfoList" :key="i">
                       <div class="appo-name">{{item.class}}</div>
                       <div class="appo-time">{{item.time}}</div>
                       <div class="appo-user">{{item.coach}}</div>
                    </div>
                 </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toggleFullScreen,isFullScreen} from '../../js/fullScreen.js'
import pieEchartsVue from './components/pieEcharts.vue';
import rowBarEchart from './components/rowBarEchart.vue';
import sexBarEchart from './components/sexBarEchart.vue';
import VueSeamlessScroll from 'vue-seamless-scroll'
import eData from './data.js'
export default {
  components: {
    pieEchartsVue,
    rowBarEchart,
    sexBarEchart
  },
  data() {
    return {
        courseInfoList: eData.courseInfoList,
         topData: eData.topData,
        isFull:false,
        seamlessOption:{
                step: 0.5, 
                limitMoveNum: 6, // 开始无缝滚动的数据量
                hoverStop: true, 
                direction: 1, 
        },
        noteList: eData.noteList,
        areaData:[{name:'区域名称',value:'海马运动中心'},{name:'开放时间',value:'09:00-22:00'},{name:'负责人',value:'海马运动'},{name:'联系电话',value:'188****1234'}],
        areaRowbarData: eData.areaRowbarData,
        areaRowbarOption:{},
        ageData: eData.ageData,
        sexData: eData.sexData,

        roomStatus:[{name:'空闲',value:'1'},{name:'拥挤',value:'2'},{name:'爆满',value:'3'}],
        roomList: eData.roomList
    }
  },

  mounted() {

  },
  methods: {
    
    toggleFull(){
        toggleFullScreen()
    },
    timeFormat(){
        return new Date().Format('MM月dd日 hh:mm')
    },
  },
};
</script>
<style lang="less" scoped>
.vis-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/visualizing/bg.jpg");
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.vis-head {
  text-align: center;
  position: relative;
  height: 4rem;
}

.vis-head-title {
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #fff;
}

.vis-head-icons{
    position: absolute;
    top: 1rem;
    right: 2rem;
}
.head-icon{
    color: #19D6FF;
    font-size: 1.5rem;
    margin-right: 1rem;
}
.vis-main{
    margin: 0 auto;
    box-sizing: border-box;
    height: calc(100vh - 4rem);
    width: calc(100vw - 10rem);
    position: absolute;
    // background-color: #fff;
    position: relative;
}
.vis-grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
    width: 100%;
}
.vis-left {

}
.vis-center {
 
  padding: 0 1.7rem;
}
.vis-right {
 
}

.vis-box {
}


.mtop{
    margin-top: 1rem;
}

.box-title {
  background-image: url("../../assets/visualizing/title.png");
  background-size: 22rem 2.4rem;
  height: 2.4rem;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 1rem;
  padding-left: 2.7rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
    line-height: 1.8;
}


.area-item{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    font-weight: normal;
    font-size: 0.8rem;
    color: #19D6FF;
    .area-name{
        
    }
    .area-value{
        
    }
}
.area-item:nth-child(2n + 1){
    background: #19D6FF10;
}
.area-item + .area-item{
    margin-top: 0.3rem;
}
.area-ec{
    height: 12rem;
    border: 1px solid #19D6FF;
    padding: 0.15rem 0;
}

.c-top-board{
    background-image: url("../../assets/visualizing/c-top.png");
    background-size: 100% 8rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 8rem;
    align-items: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    margin-top: -1.5rem;
}
.c-top-item{
    text-align: center;
}
.c-top-name{
    font-weight: 400;
    font-size: 1rem;
    color: #3FD0FF;
}
.c-top-value{
    font-size: 1.2rem;
    color: #E4EDF7;
}

.room-status-wrap{
    background-image: url("../../assets/visualizing/c-bg1.png");
    background-size: 100% 14rem;
    width: 100%;
    height: 14rem;
    position: relative;
    margin-top: 1.3rem;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    .status-tips{
        position: absolute;
        text-align: center;
        display: flex;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        top: -1.4rem;
        
    }
    .status-item{
        font-size: 1rem;
        color: #FFFFFF;
        margin-right: 1rem;
    }

    .room-grid{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-row-gap: 1rem ;
        grid-column-gap: 0.5rem;
    }
    .room-info{
        font-weight: normal;
        font-size: 1rem;
        color: #8DBFEE;
        height: 3rem;
        font-style: italic;
    }
}


.status-circle{
      width: 0.8rem;
    height: 0.8rem;
     border-radius: 50%;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.status1{
    background-color: #00ff0c; 
}
.status2{
    background-color: #fcff00;
}
.status3{
    background-color: #fb0001;
}
.ell{
       overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;

}

.room-map-wrap{
     background-image: url("../../assets/visualizing/map-bg.png");
    background-size: 100% 22rem;
    width: 100%;
    height: 22rem;
    position: relative;
    margin-top: 1.3rem;
    padding: 2rem 2rem 1.5rem;
    box-sizing: border-box;
}
.room-map-pic{
    background-image: url("../../assets/visualizing/shopmap.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.note-wrap{
    widows: 100%;
    height: 11rem;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    border: 1px solid #19D6FF;
    overflow: hidden;
    background: rgba(0,224,226,0.08);
}
.note-row{
    height: 2rem;
    font-weight: normal;
    font-size: 0.8rem;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
} 
.note-point{
    background: #66E4FF;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    flex-shrink: 0;
}
.note-time{
    padding: 0 0.7rem;
     flex-shrink: 0;
}
.note-info + .note-info{
    margin-top: 1rem;
}


.course-wrap{
    widows: 100%;
    height: 12rem;
    box-sizing: border-box;
    padding:0 1rem;
    overflow: hidden;
    
}
.course-row{
    background: rgba(35,219,252,0.1);
    border: 1px solid #23DBFC60;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0.5rem 0.7rem;
     height: 5rem;
     box-sizing: border-box;
}

.course-row + .course-row{
    margin-top: 1rem;
}
.course-info{
    flex: 1;
}
.c-name{
    font-size: 0.96rem;
    color: #19D6FF;
    margin-bottom: 0.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.c-desc{
     font-size: 0.77rem;
    color: #FFFFFF;
    line-height:1.5;
}
.course-status{
    background-image: url("../../assets/visualizing/circle-bottom-tip.png");
    background-size: 5rem 2.5rem;
    background-repeat: no-repeat;
    background-position: 0 0.2rem;
    width: 5rem;
    height: 2.7rem;
     font-size: 0.85rem;
    color: #19D6FF;
    text-align: center;
}


.appo-wrap{
    height: 11.2rem;
    overflow: hidden;
}
.appo-row{
     background-image: url("../../assets/visualizing/appo-item.png");
    background-size: 100% 3rem;
    height: 3rem;
    width: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
}
.appo-row + .appo-row{
    margin-top: 0.8rem;
}
.appo-name{
    font-weight: normal;
    font-size: 0.94rem;
    color: #19D6FF;
    width: 11rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.appo-time{
   font-size: 0.9rem;
    color: #fff;
}
.appo-user{
    font-size: 0.94rem;
    color: #19D6FF;
}
.s-height{
    height: 7rem;
}
</style>
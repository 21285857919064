<template>
  <div class="container" >
     <div v-if="disInfo.id">
        <div class="m-t20">
        <el-descriptions title="信息详情" border>
           <el-descriptions-item label="标题">
            {{ disInfo.title }}
          </el-descriptions-item>
          <el-descriptions-item label="类型">
              {{typeMap[disInfo.type]}}
          </el-descriptions-item>
          <el-descriptions-item label="城市">
            {{ disInfo.city }} {{ disInfo.district }}
          </el-descriptions-item>
          <el-descriptions-item label="地址">
            {{ disInfo.address }}
          </el-descriptions-item>
          <el-descriptions-item label="联系人">
            {{ disInfo.name }}
          </el-descriptions-item>
          <el-descriptions-item label="联系电话">
            {{ disInfo.phone }}
          </el-descriptions-item>
          <el-descriptions-item label="提交时间">
            {{ disInfo.create_time }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="m-t20"></div>
        <el-descriptions title="招聘信息" border v-if="disInfo.ilk == 'hire'">
          <el-descriptions-item label="类目">
            {{ disInfo.categories }}
          </el-descriptions-item>
          <el-descriptions-item label="工作经验">
            {{ disInfo.time_slot }}
          </el-descriptions-item>
          <el-descriptions-item label="薪资">
            {{ disInfo.amount_range }}
          </el-descriptions-item>
           <el-descriptions-item label="福利标签">
            {{ disInfo.tags }}
          </el-descriptions-item>

        </el-descriptions>

        <el-descriptions title="转让信息" border  v-else-if="disInfo.type == 't_device'">
          <el-descriptions-item label="转让价格">
            {{ disInfo.amount }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="招聘信息" border  v-else-if="disInfo.type == 't_store'">
          <el-descriptions-item label="租金">
            {{ disInfo.amount }}/月
          </el-descriptions-item>
          <el-descriptions-item label="转让费">
            {{ disInfo.amount_range > 0 ?  disInfo.amount_range : '面谈' }}
          </el-descriptions-item>
          <el-descriptions-item label="店铺类型">
            {{ disInfo.demand_type }}
          </el-descriptions-item>
           <el-descriptions-item label="面积">
            {{ disInfo.demand_num }}平
          </el-descriptions-item>
           <el-descriptions-item label="标签">
            {{ disInfo.tags }}
          </el-descriptions-item>
        </el-descriptions>

        </div>
          <div class="m-t20">
          <el-button v-if="disInfo.status === 0" type="success" @click="checkSuccess">审核通过</el-button>
        <el-button v-if="disInfo.status === 0" type="danger" @click="openRefuse">审核拒绝</el-button>
    </div>
         <div class="m-t20">
             <div class="info" v-html="disInfo.introduction">
                
             </div>
        </div>
        <div class="m-t20 flex flex-wrap" v-if="disInfo.introduction_url">
          <div  v-for="(p,i) in disInfo.introduction_url.split(',')" :key="i">
            <el-image
              style="width: 150px; height: 150px"
              :src="p"
              fit="cover"
              :preview-src-list="[disInfo.introduction_url.split(',')]"
            ></el-image>
          </div>
             
        </div>
    </div>

     <refusePop :show="refuseShow" @confirm="refuseConfrim"></refusePop>
  </div>
</template>

<script>
import refusePop from '@/components/refusePop/refusePop.vue';
export default {
   components:{
      refusePop,
  },
  data() {
    return {
        refuseShow:false,
        id:'',
        disInfo:{},
        typeMap:{
            't_store':'转店',
            't_device':'转设备',
            'recruit':'招聘全职',
            'recruit_p':'招聘兼职',
            'rent':'店铺出租'
        },
        managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
        active:0,
        course:{},
    };
  },

  mounted() {
    this.id = this.$route.query.id
    this.getData()
  },

  methods: {
    checkSuccess(){
        this.$confirm('确认审核通过吗？').then(()=>{
           let data = {
            admin_name:this.managerInfo.name,
            id:this.id,
            admin_id:this.managerInfo.id,
            status:3,
        }
            this.$post('/user/demandInfo/update',data).then(res=>{
               this.$router.back()
            })
      })
    },
    openRefuse(){
        this.refuseShow = true
    },
    refuseConfrim(text){
        this.refuseShow = false
        let data = {
            admin_name:this.managerInfo.name,
            id:this.id,
            admin_id:this.managerInfo.id,
            status:2,
            refuse:text
        }
         this.$post('/user/demandInfo/update',data).then(res=>{
            this.$router.back()
        })
    },
    getData(){
         this.$get('/user/demandInfo/findById',{id:this.id}).then(res=>{
            this.disInfo = res.data.data
            
        })
    }
  },
};
</script>
<style lang="less" scoped>
.info{
    white-space: pre-wrap;
    font-size: 16px;
}
.type-dis{
  color: rgb(83, 163, 238);
}
.type-spl{
 color: rgb(243, 160, 64);
}

.h-t{
  font-size: 16px;
    font-weight: 700;
    padding: 20px 0;
}
.course{
  padding: 20px;
  background-color: #F4f4f4;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  .c-img{
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
  .c-title{
      font-size: 16px;

  }
  .c-tips{
      font-size: 13px;
      color:#666;
      margin-right: 10px;
  }
}
</style>
<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
    import * as echarts from "echarts";
    import {fontSize} from "@/js/utils"
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: [],
      option: {
        grid: {
          top: fontSize(0.16),
          bottom: fontSize(-0.2),
          right: fontSize(0.2),
          left: fontSize(0.2),
          containLabel: true,
        },
        xAxis: {
          show: false,
        },
        yAxis: [
          {
            triggerEvent: true,
            show: true,
            inverse: true,
            data: [],
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              interval: 0,
              color: "#fff",
              align: "left",
              margin: fontSize(0.8),
              fontSize: fontSize(0.12),
              formatter: function (value, index) {
                return "{title|" + value + "}";
              },
              rich: {
                title: {
                  width: fontSize(1.65),
                },
              },
            },
          },
          {
            triggerEvent: true,
            show: true,
            inverse: true,
            data: [],
            axisLine: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              color: "#fff",
              lineHeight: fontSize(0.2),
              fontSize: fontSize(0.12),
              formatter: (value, index) => {
                return this.data[index].value;
              },
            },
          },
        ],
        series: [
          {
            name: "XXX",
            type: "pictorialBar",
            symbol:
              "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==",
            symbolSize: [fontSize(0.5), fontSize(0.5)],
            symbolOffset: [fontSize(0.2), 0],
            z: 12,
            itemStyle: {
              normal: {
                color: "#14b1eb",
              },
            },
            data: [],
          },
          {
            name: "条",
            type: "bar",
            showBackground: true,
            barBorderRadius: fontSize(0.3),
            yAxisIndex: 0,
            data: [],
            barWidth: fontSize(0.1),
            // align: left,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#19D6FF80",
                    },
                    {
                      offset: 0.6,
                      color: "#19D6FF90",
                    },
                    {
                      offset: 1,
                      color: "#19D6FF",
                    },
                  ],
                  false
                ),
                barBorderRadius: fontSize(0.1),
              },
              // color: '#A71A2B',
              barBorderRadius: fontSize(0.1),
            },
            label: {
              normal: {
                color: "#fff",
                show: true,
                position: [0, -fontSize(0.16)],
                textStyle: {
                  fontSize: fontSize(0.12),
                },
                formatter: function (a, b) {
                  return a.name;
                },
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
     this.handlerData()
      this.myChart.setOption(this.option);
    },
    handlerData(){
        this.option.yAxis[0].data = this.data.map(t=>t.name)
        this.option.yAxis[1].data = this.data.map(t=>t.name)
        let arr = [];
        for (var i = 0; i < this.data.length; i++) {
            arr.push({
                value: this.data[i].value,
                symbolPosition: 'end'
            })
        }
        this.option.series[0].data = arr
         this.option.series[1].data = [...this.data]
    },
    getEchartData() {
      this.handlerData()
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="less" scoped>

    .a{
      color: #A71A2B;
    }
</style>
<template>
  <!-- 商品新增，修改 -->
  <div>
   <el-form :model="form" :rules="rules" label-width="120px" ref="form">
        <el-form-item label="商品名称" required prop="name">
              <el-input
                v-model="form.name"
                placeholder="商品名称"
              ></el-input>
            </el-form-item>
             <el-form-item label="类型" required prop="type">
              <el-select v-model="form.type">
              <el-option v-for="(item,i) in typeList" :key="i" :label="item.label" :value="item.value"></el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="主类目" required prop="main_category">
             <el-select v-model="form.main_category" @change="getCateList">
              <el-option v-for="(item,i) in mainList" :key="i" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="子类目" required>
            <el-cascader
                v-model="category_id"
                :props="cateProps"
                :options="categories_child_list"
                @change="categoryChange"
                ></el-cascader>
          </el-form-item>
           <el-form-item label="商品数量">
              <el-input
                v-model="form.course_num"
                placeholder="默认1，课程为10等"
              ></el-input>
              <div>单次购买商品得到的数量</div>
            </el-form-item>
            <el-form-item label="原价">
              <el-input
                v-model="form.origin_price"
                placeholder="划线价"
              ></el-input>
            </el-form-item>
            <el-form-item label="封面" required prop="cover_img">
              <avatar-uploader
                ref="imgs"
                @getImgUrl="v=>form.cover_img = v"
                :url="temp.cover_img"
              >
              </avatar-uploader>
            </el-form-item>
            <el-form-item label="服务摘要">
              <el-input
                type="textarea"
                v-model="form.abs_text"
                maxlength="40"
                placeholder="摘要说明，简介说明服务内容，30字以内"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                type="textarea"
                v-model="form.introduce"
                placeholder="商品介绍"
                 maxlength="300"
                 :rows="4"
                show-word-limit
              ></el-input>
            </el-form-item>
             <el-form-item label="顶部轮播">
              <images-uploader
                ref="imgs"
                @getImgUrl="v=>form.banner_url = v"
                :urls="temp.banner_url"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="详情图片">
              <images-uploader
                ref="imgs"
                @getImgUrl="v=>form.introduce_img = v"
                :urls="temp.introduce_img"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="" v-if="!id">
             <el-button type="primary" @click="next">下一步</el-button>
            </el-form-item>
             <el-form-item label="" v-if="id">
             <el-button type="primary" v-loading="loading" @click="updateForm">更新</el-button>
            </el-form-item>
      </el-form>
      
  </div>
</template>
<script>
import {typeList,mainList} from '../type.js'
import { ObjectChangedValue } from "@/js/utils.js";
import { infoRule } from "./rules.js";
export default {
    props:{
        info : {
            type:Object,
            default:()=>{return {}}
        }
    },
  data() {
    return {
      form: {},
      temp: {},
      id:'',
      typeList: typeList,
      mainList:mainList,
      rules: infoRule,
      loading:false,
      category_id:[],
      cateProps:{
            value:'id',
            label:'name',
            children:'childList'
      },
     categories_child_list:[],

    };
  },
  watch:{
    info:{
        handler:function(nv){
            if(nv.id){
                this.form = {...nv}
                this.temp = {...nv}
                this.getCateList()
                this.category_id = [nv.category_id,nv.category_id2]
            }
        },
        immediate:true,
        deep:true
    }
  },
  mounted() {
    this.id = this.$route.query.id
  },

  methods: {
    categoryChange(v){
        this.form.category_id = v[0]
        this.form.category_id2 = v[1]
    },
    next(){
        if(!this.form.cover_img){
            this.$message('请上传封面')
        }
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.$emit('next',{next:'sku',data:this.form})
            }
        })     
    },
    getCateList() {
        if(!this.form.main_category){
            return
        }
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        source: this.form.main_category,
      };
      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    
    updateForm() {
        if(!this.form.cover_img){
            this.$message('请上传封面')
        }
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/product/update",
            method: "post",
            data: {
              id: this.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.temp = {...this.form}
            this.loading = false
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
</style>
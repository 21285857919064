<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import * as echarts from "echarts";
import { fontSize } from "@/js/utils";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: [],
      option: {
        grid: {
          left: "5%",
          right: "7%",
          bottom: "2%",
          top: "20%",
          containLabel: true,
        },
         title: {
          top: fontSize(0.1),
          text: "",
          textStyle: {
            fontSize: fontSize(0.12),
            color: "#DDEEFF",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: [],
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: fontSize(0.1),
              },
               formatter: (value, index) => {
                return this.data[index].rate + '%';
              },
            },
            data: [],
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            itemStyle: {
              normal: {
                barBorderRadius: fontSize(0.16),
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: "rgb(57,89,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgb(46,200,207,1)",
                  },
                ]),
              },
            },
            barWidth: fontSize(0.16),
            data: [],
          },
          {
            name: "背景",
            type: "bar",
            barWidth: fontSize(0.2),
            barGap: "-100%",
            data: [],
            itemStyle: {
              normal: {
                color: "#333",
                barBorderRadius: fontSize(0.2),
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
      this.option.title.text = this.opt.title || ''
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
      this.handlerData();
      this.myChart.setOption(this.option);
    },
    handlerData() {
       let total = 0
      let serData = []
      this.data.forEach(t=>total += Number(t.value))
      this.data.forEach(t=>{
        if(total == 0){
            t.rate = 0
        }else{
            t.rate = (t.value/total*100).toFixed(0)
        }
        
        serData.push(t)
      })
      this.serData = serData
      this.option.yAxis[0].data = this.data.map((t) => t.name);
      this.option.series[0].data = this.data.map((t) => t.value);
      this.option.yAxis[1].data = this.data.map((t) => t.value);


    },
    getEchartData() {
      this.handlerData();
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="less" scoped>
.a {
  color: #a71a2b;
}
</style>
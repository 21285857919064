<template>
  <div class="container">
    <div class="fb">
      <el-button
        @click="addDialog = true"
        style="background-color: #ff7e00; color: #ffffff; border: 0"
        >新增</el-button
      >
    </div>
    <el-table :data="cardList">
      <el-table-column label="ID" prop="id"></el-table-column>

      <el-table-column label="会员卡名称" prop="name"></el-table-column>

      <el-table-column label="有效期/月" prop="duration"> </el-table-column>
      <el-table-column label="售价" prop="price"></el-table-column>

      <el-table-column label="赠送权益点" prop="give_point"></el-table-column>
      <el-table-column label="赠送消费金" prop="give_consume"></el-table-column>
      <el-table-column width="200" label="更新日期" prop="update_time">
        <!-- <template slot-scope="scope">
            {{end_time(scope.row.create_time)}}
          </template> -->
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: green">已生效</span>
          <span v-else-if="scope.row.status == 0" style="color: red"
            >未生效</span
          >
        </template>
      </el-table-column>

      <el-table-column width="280" label="操作">
        <template slot-scope="scope">
          <el-button type="warning" size="small" @click="openUpdate(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="cardFrozen(scope.row, 0)"
            v-if="scope.row.status == 1"
            >下架
          </el-button>
          <el-button
            type="success"
            size="small"
            @click="cardFrozen(scope.row, 1)"
            v-if="scope.row.status == 0"
            >启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <paging-fy
      @currentPageChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="cardTotal"
    ></paging-fy>

    <el-dialog
      :visible="addDialog || updateDialog"
      :title="addDialog ? '新增会员卡' : updateDialog ? '修改会员卡' : ''"
      :before-close="handleClose"
    >
      <el-form
        v-model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around"
      >
        <div>
          <el-form-item label="会员卡类型">
            <el-select v-model="form.type" placeholder="请选择" @change="changeCardType">
              <el-option v-for="(item,i) in memberTypeList" :key="i" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="会员卡名称">
            <el-input v-model="form.name" placeholder="会员卡名称"></el-input>
          </el-form-item>
          <el-form-item label="原价">
            <el-input v-model="form.price" placeholder="原价"></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="售价">
            <el-input
              v-model="form.discount_price"
              placeholder="售价"
            ></el-input
            ><span class="form_text">元</span>
          </el-form-item>
          <el-form-item label="赠送权益点" v-if="form.type == 1">
            <el-input v-model="form.give_point" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="赠送消费金">
            <el-input v-model="form.give_consume" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="有效期">
            <el-input v-model="form.duration" placeholder="有效期"></el-input
            ><span class="form_text">月</span>
          </el-form-item>
           <el-form-item label="优惠方式" v-if="form.type == 4">
            <el-radio-group v-model="form.use_way">
              <el-radio :label="1">课节数</el-radio>
              <el-radio :label="2">金额</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="可用课程" v-if="form.type == 4">
            <el-checkbox-group v-model="useList">
              <el-checkbox label="home">上门课程</el-checkbox>
              <el-checkbox label="mechanism">到店课程</el-checkbox>
              <el-checkbox label="venue">场馆</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="优惠数量"  v-if="form.type == 4 && form.use_way == 1">
            <el-input v-model="form.t_count" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="优惠金额"  v-if="form.type == 4 && form.use_way == 2">
            <el-input v-model="form.t_cash" placeholder=""></el-input>
          </el-form-item>

           <el-form-item label="权益">
             <el-checkbox-group v-model="changeRights">
              <el-checkbox :label="v.id" v-for="(v,i) in rights" :key="i">{{v.name}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="封面">
            <avatar-uploader
                @getImgUrl="(v) => form.card_pic = v"
                :url="form.card_pic"
                :clear="clear"
              ></avatar-uploader>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addDialog" @click="addCard()"
          >确 定</el-button
        >
        <el-button type="primary" v-if="updateDialog" @click="update()"
          >确 定</el-button
        >
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import {memberTypeList} from '../type.js'
export default {
  data() {
    return {
      memberTypeList:memberTypeList,
      ks: "",
      activeName2: "first",
      currentPage: 1,
      cardList: [],
      cardTotal: 0,
      allCardLevel: [],
      cardLevel: [],
      level_id: null,
      firstLevelCard: [],
      temp: {},
      form: {
        platform: "体育宝",
        is_teach_paypal: true,
        status: 1,
      },
      addTypeDialog: false,
      addDialog: false,
      addCardDialog: false,
      updateTypeDialog: false,
      updateCardDialog: false,
      updateDialog: false,
      cardType: 1,
      card_status: "1",
      level_status: "1",
      clear:false,
      rights:[],
      changeRights:[],
      useList:[]
    };
  },
  mounted() {
    this.getCardList();
    // this.getCardRights()
  },

  methods: {
    changeCardType(v){
      this.getCardRights()
    },
    getCardRights(){
        this.$get('/user/memberRight/query',{card_type:this.form.type}).then(res=>{
            this.rights = res.data.data
        })
    },
    getCardList() {
      let url = "/user/member/card/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
          },
        })
        .then((res) => {
          this.cardList = res.data.data.rows;
          this.cardTotal = res.data.data.total;
        });
    },
    handleCurrentChange(v) {
      this.currentPage = v;
      this.getCardList();
    },
    addCard() {
      let url = "/user/member/card/save";
      this.form.rights = this.changeRights.join(',')
      this.form.available = this.useList.join(',')
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "添加成功" });
            this.addDialog = false;
            this.getCardList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    openUpdate(row) {
      this.temp = row;
      if(row.rights){
        this.changeRights = row.rights.split(',').map(t=>{return Number(t)})
      }else{
        this.changeRights  = []
      }
       if(row.available){
        this.useList = row.available.split(',')
      }else{
        this.useList  = []
      }
    
      this.form = row;
      this.updateDialog = true;
    },
    update() {
       this.form.rights = this.changeRights.join(',')
        this.form.available = this.useList.join(',')
      let url = "/user/member/card/update";
      this.$axios.post(url, { ...this.form }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.updateDialog = false;
          this.temp = {};
          this.getCardList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    cardFrozen(row, status) {
      let url = "/user/member/card/update";
      this.$axios.post(url, { id: row.id, status: status }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.getCardList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addTypeDialog = false;
          this.addDialog = false;
          this.updateCardDialog = false;
          this.updateDialog = false;
          this.temp = {};
          this.level_id = null;
          this.clear = !this.clear
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>
<style>
.form_text {
  margin-left: 1rem;
}

.fb {
  display: flex;
  justify-content: space-between;
}
</style>
  
<template>
  <!-- 合作商新增，修改 -->
  <div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px" ref="form">
            <el-form-item label="课程类目" required >
             <el-select
            v-model="form.category"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
            </el-form-item>
            <el-form-item label="用户报价" required>
              <el-input v-model="form.price_min" placeholder="用户报价,或者用户提交的机构价格"></el-input>
            </el-form-item>
            <el-form-item label="课节数" required>
              <el-input
                v-model="form.course_num"
                placeholder="课节数"
              ></el-input>
            </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm" :loading="loading"
          >更 新</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  components: {
    citySelect,
  },
  data() {
    return {
      form: {},
      temp: {},
      cityList: [],
      dialogVisible: false,
      loading:false,
      listPageChild:[]
    };
  },

  mounted() {
    this.getCategory()
  },

  methods: {
    getCategory() {
      let url = this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    updateForm() {
        this.form.price_max = this.form.price_min
      const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/discountApply/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.dialogVisible = false;
            this.loading = false
            this.$emit("success", this.form);
          }).catch(()=>{
            this.loading = false
          })
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = Object.assign({}, info);
      }
      this.dialogVisible = true;
    },
    //选择地址
    changeMapAddr(addr) {
      this.$set(this.form, "province", addr.province);
      this.$set(this.form, "city", addr.city);
      this.$set(this.form, "district", addr.district);
      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
      this.$set(this.form, "address", addr.address);
      this.cityList.push(addr.province);
      this.cityList.push(addr.city);
      this.cityList.push(addr.district);
    },
    changeCity(v) {
      this.$set(this.form, "province", v[0]);
      this.$set(this.form, "city", v[1]);
      this.$set(this.form, "district", v[2]);
      this.cityList = v;
    },
    getProductImg(v) {
      this.form.introduction_img = v;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
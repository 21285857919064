export let infoRule = {
    type: [
        { required: true, message: "请选择类型", trigger: "change" },
    ],
    name: [
        { required: true, message: "请填写名称", trigger: "change" },
    ],
    main_category: [
        { required: true, message: "请选择类目", trigger: "change" },
    ],

}


export let platFormRule = {
    ilk: [
        { required: true, message: "请选择上架模块", trigger: "change" },
    ],
    address_if: [
        { required: true, message: "请选择", trigger: "change" },
    ],
    pay_way: [
        { required: true, message: "请选择", trigger: "change" },
    ],

    


}

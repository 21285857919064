<template>
  <div class="re">
    <div class="flex city-position">
      <div
        class="ad-item"
        v-for="(ad, i) in adList"
        :key="i"
        @click="toPreAddress(ad, i)"
      >
        {{ ad.name }}
      </div>
    </div>

    <ul class="count-list">
      <li class="title-li">{{opt.title || ''}}</li>
      <li class="count-value" v-for="(v,i) in list" :key="i">
          {{v.custom}}&nbsp;&nbsp;{{v.count}}
      </li>
    </ul>
    <div ref="map" class="map-wrap" :style="{ height: height }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import zhejiang from "@/assets/js/zhejiang.json";
import { fontSize } from "@/js/utils";

export default {
  props: {
    height: {
      type: String,
      default: "200px",
    },
    query:{
      type: Object,
      default: ()=>{return {}}
    },
    opt:{
      type: Object,
      default: ()=>{return {}}
    }
  },
  data() {
    return {
      myChart: "",
      option: {},
      curJSON: "",
      adList: [],
      list:[]
    };
  },

  mounted() {
    this.initMap();
  },

  methods: {
    //初始化
    initMap() {
      this.curJSON = zhejiang;
      this.adList.push({
        name: "浙江省",
        adcode: 300000,
        level: "province",
        mapJSON: zhejiang,
      });
      echarts.registerMap("China", zhejiang);
      this.handlerMapChat();

      // var uploadedDataURL =
      //   "https://geo.datav.aliyun.com/areas_v3/bound/100000_full.json";

      // fetch(uploadedDataURL).then((res) => {
      //   if (res.status === 200) {
      //     res.json().then((result) => {

      //       result.features.forEach(t=>{
      //         console.log(t.properties.name);
      //       })
      //       this.curJSON = result;
      //       this.adList.push({
      //         name: "全国",
      //         adcode: 100000,
      //         level: "country",
      //         mapJSON: result,
      //       });
      //       echarts.registerMap("China", result);
      //       this.handlerMapChat();
      //     });
      //   }
      // });
    },
    //初始化地图配置
    handlerMapChat() {
      this.option = {
        title: {
          top: 20,
          text: "",
          subtext: "",
          x: 20,
          textStyle: {
            color: "#ccc",
          },
        },

        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.data && params.data.value[2]) {
              return params.name + " : " + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        legend: {
            orient: 'vertical',
            // top: '20%',
            // left: 'left',
            data: ['asd','joasd'],
            textStyle: {
                color: '#fff'
            }
        },
        visualMap: {
          show: false,
          min: 0,
          max: fontSize(20),
          left: "left",
          top: 0,
          calculable: true,
          seriesIndex: [1],
          inRange: {},
        },
        geo: {
          map: "China",
          show: true,
          roam: true,
          label: {
            normal: {
              show: true,
              textStyle: {
                  //字体颜色
                  color: "#fff",
                  fontSize: fontSize(0.12),
              },
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#3a7fd5",
              borderColor: "#0a53e9",
              shadowColor: "#092f8f",
              shadowBlur: fontSize(0.1),
            },
            emphasis: {
              areaColor: "#0a2dae", //悬浮区背景
            },
          },
        },
        series: [
          {
            type: "map",
            map: "china",
            geoIndex: 0,
            aspectScale: 1, //长宽比
            // showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true, // 是否显示对应地名
                textStyle: {
                  //字体颜色
                  color: "#797979",
                },
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff",
                },
              },
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: "#031525",
                borderColor: "#FFFFFF",
              },
              emphasis: {
                areaColor: "#2B91B7",
              },
            },
            animation: false,
            data: [],
          },
          {
            symbolSize: 5,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: false,
                color: "#ffff",
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#ffff",
              },
            },
            name: "light",
            type: "scatter",
            coordinateSystem: "geo",
            data: [],
          },
          {
            name: "气泡",
            type: "scatter",
            coordinateSystem: "geo",
            symbol: "pin",
            symbolSize: [40, 40],
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: fontSize(0.1),
                },
                formatter(value) {
                  return value.data.value[2];
                },
              },
            },
            itemStyle: {
               
              normal: {
                color: "#D8BC37", //标志颜色
              },
            },
            data: [],
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            zlevel: 1,
          },
        ],
      };

      this.getMapValues().then(() => {
        const chart = this.$refs.map;
        if (chart) {
          this.myChart = this.$echarts.init(chart);
          this.myChart.setOption(this.option);
          this.myChart.on("click", this.getClickInfo);
        }
      });
    },
    //获取后台数据
    getMapValues() {
      let resMap = {};
      var res = [];
      var mapData = [];

      let params = {
        start_time : this.query.start_time || null,
        end_time : this.query.end_time || null,
      };
      this.adList.forEach((item) => {
        if (item.level === "country") {
          params.custom = "province";
        }
        if (item.level === "province") {
          params.custom = "city";
          params.province = item.name;
        }
        if (item.level === "city") {
          params.custom = "district";
          params.city = item.name;
        }
      });


      let url = '/user/masterAppointment/queryCountMap'
      if(this.opt.url){
        url = this.opt.url
      }
      return new Promise((resolve, reject) => {
        this.$axios({
          url,
          params,
        })
          .then((r) => {
            this.list = r.data.data
            r.data.data.forEach((item) => {
              resMap[item.custom] = item.count;
            });
            for (var i = 0; i < this.curJSON.features.length; i++) {
              var addr = this.curJSON.features[i].properties;
              mapData.push({
                name: addr.name,
                value: addr.center || [],
                adcode: addr.adcode,
                level: addr.level,
              });
              if (addr.center && resMap[addr.name]) {
                res.push({
                  name: addr.name,
                  value: [...addr.center, resMap[addr.name]],
                  adcode: addr.adcode,
                  level: addr.level,
                });
              }
            }
           
            this.option.series[0].data = mapData;
            this.option.series[1].data = res;
            this.option.series[2].data = res;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 点击地图，下钻到对应区域
    getClickInfo(e) {
      if (e.data.level === "district") {
        return;
      }
      let mapname = "m" + e.data.adcode;
      var uploadedDataURL = `https://geo.datav.aliyun.com/areas_v3/bound/${e.data.adcode}_full.json`;

      fetch(uploadedDataURL).then((res) => {
        if (res.status === 200) {
          res.json().then((result) => {
            this.adList.push({
              name: e.data.name,
              adcode: e.data.adcode,
              level: e.data.level,
              mapJSON: result,
            });
            this.curJSON = result;
            echarts.registerMap(mapname, result);
            this.option.geo.map = mapname;
            this.option.series[0].map = mapname;
            //然后重新绘制地图
            this.getMapValues().then(() => {
              this.myChart.setOption(this.option);
            });
          });
        }
      });
    },
    //点击城市导航列表
    toPreAddress(ad, i) {
      if (i + 1 === this.adList.length) {
        return;
      }
      let mapname = "m" + ad.adcode;
      this.curJSON = ad.mapJSON;
      if (i > 0) {
        this.adList = this.adList.slice(0, i + 1);
      } else {
        this.adList = this.adList.slice(0, 1);
      }
      echarts.registerMap(mapname, this.curJSON);
      this.option.geo.map = mapname;
      this.option.series[0].map = mapname;
      this.getMapValues().then(() => {
        this.myChart.setOption(this.option);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.map-wrap {
  width: 100%;
}
.re{
  position: relative;
}
.city-position{
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 20;
}
.ad-item {
  margin-right: 20px;
  font-size: 1rem;
  color: #00A8FF;
  font-weight: 600;
  cursor: pointer;
}

.count-list{
   position: absolute;
  left: 2rem;
  top: 4rem;
}
.title-li{
  font-size: 0.8rem;
  color: #fff;
}
.count-value{
  font-size: 0.7rem;
  color: #fff;
  margin-top: 0.25rem;
}
</style>
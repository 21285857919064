<template>
  <div class="vis-container">
    <div class="vis-head">
      <div class="vis-head-title">海马运动中心</div>
      <div class="vis-head-icons">
        <i class="el-icon-full-screen head-icon" @click="toggleFull"></i>
      </div>
    </div>
    <div class="vis-main">
      <div class="vis-grid">
        <div class="vis-left">
          <!-- 学员构成 -->
          <div class="vis-box">
            <div class="box-title">学员构成</div>
            <div class="s-height">
                <pieEchartsVue ename="page" :data="ageData" :opt="{title:'人群分布'}"></pieEchartsVue>
            </div>
            <div class="s-height">
                <sexBarEchart ename="sex" :data="sexData" :opt="{title:'性别分布'}"></sexBarEchart>
            </div>
          </div>
           <!-- 学员构成 -->
          <div class="vis-box">
            <div class="box-title">教练数据</div>
            <div class="coach-count-wrap">
                <div class="coach-item">
                    <p class="coach-name">历史预约教练总数</p>
                    <p class="coach-value">{{coachCount.total}}</p>
                </div>
               <div class="coach-item">
                    <p class="coach-name">今日预约教练总数</p>
                    <p class="coach-value">{{coachCount.today}}</p>
                </div>
            </div>
            <div class="time-line-wrap">
                <line2timeEchart :data="timeCountData" ename="tline" :opt="{title:'各时间段预约人数'}"></line2timeEchart>
            </div>
          </div>
           <!-- 学员构成 -->
          <div class="vis-box">
            <div class="box-title">今日用户</div>
            <div class="user-count-wrap">
                <div class="user-item">
                    <p class="user-count-icon"></p>
                     <div>
                          <p class="user-count-name">今日预约人数</p>
                    <p class="user-count-value">{{userCount.today}}</p>
                    </div>
                </div>
               <div class="user-item">
                    <p class="user-count-icon"></p>
                    <div>
                          <p class="user-count-name">当前人数</p>
                    <p class="user-count-value">{{userCount.cur}}</p>
                    </div>
                  
                </div>
            </div>
          </div>
        </div>

        <div class="vis-center">
            <!-- 数据统计 -->
            <div class="c-top-board">
                <div class="c-top-item" v-for="(item,i) in topData" :key="i">
                <p class="c-top-name">{{item.name}}</p>
                <p class="c-top-value">{{item.value}}</p>
                </div>
            </div>
             <div class="income-wrap">
                <div class="income-item"  v-for="(item,i) in incomeData" :key="i">
                    <p class="income-name">
                       {{item.name}}
                    </p>
                     <p class="income-value">
                        {{item.value}}
                    </p>
                </div>
             </div>


            <!-- 人流量 -->
            <div class="person-wrap">
                <div class="person-tab">
                       <eTabs :list="areaTabs" @change="areaTypeChange"></eTabs>
                </div>
                
                <row2BarEcharts v-if="personType == 'total'" :data="areaTotalCountData" ename="persontbar" :opt="{title:'总人流量'}"></row2BarEcharts>

                <bar2Echart v-if="personType == 'area'" :data="areaCountData" ename="personbar" :opt="{title:'各区域人流量'}"></bar2Echart>
            </div>

             <!-- 区域数据 -->
            <div class="room-data-wrap">
                <div class="flex jc-b al room-head">
                    <div class="e-title">
                        各区域数据
                    </div>
                    <eTabs :list="roomTabs"></eTabs>
                </div>
                <div class="room-grid">
                    <div class="room-info flex-col" v-for="(item,i) in roomData" :key="i">
                        <div class="room-name">{{item.name}}</div>
                        <div>
                            <div class="room-desc">今日使用：<span class="room-text1">{{item.use}}人</span></div>
                            <div class="room-desc">今日收入：<span class="room-text2">{{item.income}}</span></div>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>

        <div class="vis-right">
            <!-- 上课总数 -->
          <div class="vis-box">
            <div class="box-title">上课总数</div>
           <div class="attend-wrap">
                <div class="attend-item">
                    <div class="attend-info">
                        <p class="attend-name">
                          17 节
                        </p>
                        <p class="attend-value">
                            已上课程
                        </p>
                    </div>
                </div>
                <div class="attend-item a-icon2">
                     <div class="attend-info">
                        <p class="attend-name">
                           18 节
                        </p>
                        <p class="attend-value">
                            待上课程
                        </p>
                    </div>
                </div>
                 <div class="attend-item a-icon3">
                   <div class="attend-info">
                        <p class="attend-name">
                           0 节
                        </p>
                        <p class="attend-value">
                            未上课程
                        </p>
                    </div>
                </div>
             </div>
          </div>

        <!-- 热门教室 -->
          <div class="vis-box mtop">
            <div class="e-title">
                热门教室预约人数
            </div>
            <div class="hot-room-wrap">
                <rowBarEchart ename="areacount" :data="areaRowbarData" :opt="{title:'热门教室预约人数'}"></rowBarEchart>
            </div>
          </div>

              <!-- 热门教室 -->
          <div class="vis-box">
            <div class="hot-room-wrap">
                  <barRankEchart :data="roomIncomeBarData" ename="personibar" :opt="{title:'热门教室收入'}"></barRankEchart>
            </div>
          </div>

            <!-- 今日上课 -->
          <div class="vis-box">
            <div class="box-title">今日预约</div>
            <div class="today-course-wrap">
                <div class="today-total">
                    143
                </div>
                 <div class="today-info">
                    <p class="today-name">
                        今日已核销
                    </p>
                     <p class="today-value">
                        86
                    </p>
                </div>
                 <div class="today-info">
                    <p class="today-name">
                        今日待核销
                    </p>
                     <p class="today-value">
                        57
                    </p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toggleFullScreen,isFullScreen} from '../../js/fullScreen.js'
import pieEchartsVue from './components/pieEcharts.vue';
import rowBarEchart from './components/rowBarEchart.vue';
import sexBarEchart from './components/sexBarEchart.vue';
import VueSeamlessScroll from 'vue-seamless-scroll'
import line2timeEchart from './components/line2timeEchart.vue';
import bar2Echart from './components/bar2Echart.vue';
import barRankEchart from './components/barRankEchart.vue';
import eData from './data.js'
import eTabs from './components/eTabs/index.vue';
import row2BarEcharts from './components/row2BarEcharts.vue';

export default {
  components: {
    pieEchartsVue,
    rowBarEchart,
    sexBarEchart,
    line2timeEchart,
    bar2Echart,
    barRankEchart,
    eTabs,
    row2BarEcharts
  },
  data() {
    return {
        personType:'area',
        roomTabs:[{name:'今日'},{name:'本周'},{name:'本月'}],
        areaTabs:[{name:'各区人流',type:'area'},{name:'总人流',type:'total'}],
        roomIncomeBarData: eData.roomIncomeBarData,
        areaTotalCountData: eData.areaTotalCountData,
        roomData: eData.roomData,
        areaCountData: eData.areaCountData,
        incomeData: eData.incomeData,
        topData: eData.topData,
        coachCount: eData.coachCount,
        userCount: eData.userCount,
        isFull:false,
        seamlessOption:{
                step: 0.5, 
                limitMoveNum: 6, // 开始无缝滚动的数据量
                hoverStop: true, 
                direction: 1, 
        },
        timeCountData: eData.timeCountData,
        areaRowbarData: eData.areaRowbarData,
        areaRowbarOption:{},
        ageData: eData.ageData,
        sexData: eData.sexData,

    }
  },

  mounted() {

  },
  methods: {
    areaTypeChange(i){
        this.personType = this.areaTabs[i].type
    },
    toggleFull(){
        toggleFullScreen()
    },
    timeFormat(){
        return new Date().Format('MM月dd日 hh:mm')
    },
  },
};
</script>
<style lang="less" scoped>
.vis-container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/visualizing/bg.jpg");
  background-size: 100vw 100vh;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

.vis-head {
  text-align: center;
  position: relative;
  height: 4rem;
}

.vis-head-title {
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #fff;
}

.vis-head-icons{
    position: absolute;
    top: 1rem;
    right: 2rem;
}
.head-icon{
    color: #19D6FF;
    font-size: 1.5rem;
    margin-right: 1rem;
}
.vis-main{
    margin: 0 auto;
    box-sizing: border-box;
    height: calc(100vh - 4rem);
    width: calc(100vw - 10rem);
    position: absolute;
    // background-color: #fff;
    position: relative;
}
.vis-grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 24% 52% 24%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.vis-left {

}
.vis-center {
  padding: 0 1.7rem;
}
.vis-right {
 
}

.vis-box {
}

.mtop{
    margin-top: 1rem;
}
.s-height{
    height: 6.5rem;
}
.box-title {
  background-image: url("../../assets/visualizing/title.png");
  background-size: 22rem 2.4rem;
  height: 2.4rem;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 1rem;
  padding-left: 2.7rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
    line-height: 1.8;
}

.coach-count-wrap{
    display: flex;
    justify-content: center;
}
.coach-item{
    text-align: center;
    width: 40%;
}
.coach-item + .coach-item{
    border-left: 1px solid #19D6FF;
}
.coach-name{
    font-size: 0.7rem;
    color: #E4EDF7;
}
.coach-value{
    font-size: 0.9rem;
    color: #3FD0FF;
}

.time-line-wrap{
    margin-top: 0.5rem;
    height: 15rem;
    margin-bottom: 0.5rem;
}


.user-count-wrap{
    display: flex;
     justify-content: space-evenly;
}

.user-item{
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-count-icon{
    background-image: url("../../assets/visualizing/uc-icon.png");
    background-size: 3.5rem 3rem;
    width: 3.5rem;
    height: 3rem;
    margin-right: 0.3rem;
}
.user-count-name{
    font-size: 0.7rem;
    color: #E4EDF7;
    line-height: 1.8;
}
.user-count-value{
   font-size: 1.1rem;
    color: #3FD0FF;
}


.c-top-board{
    background-image: url("../../assets/visualizing/c-top.png");
    background-size: 100% 8rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 8rem;
    align-items: center;
    padding: 0 1.5rem;
    box-sizing: border-box;
    margin-top: -2rem;
}
.c-top-item{
    text-align: center;
}
.c-top-name{
    font-weight: 400;
    font-size: 1rem;
    color: #3FD0FF;
}
.c-top-value{
    font-size: 1.2rem;
    color: #E4EDF7;
}

.income-wrap{
    background-image: url("../../assets/visualizing/c-bg1.png");
    background-size: 100% 7rem;
    width: 100%;
    height: 7rem;
    margin-top: 0.5rem;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
}
.income-item{
    background-image: url("../../assets/visualizing/circle-bottom-tip.png");
    background-size: 6rem 3rem;
    background-repeat: no-repeat;
    background-position: center 100%;
    height: 5rem;
    text-align: center;
    flex: 1;
}
.income-name{
    font-size: 1rem;
    color: #E4EDF7;
    line-height: 1.5;
}
.income-value{
    font-weight: 500;
    font-size: 1.25rem;
    color: #19D6FF;
    text-shadow: 1px 0 #bef3ff;
    letter-spacing: 0.1rem;
}

.person-wrap{
    background-image: url("../../assets/visualizing/box-bg.png");
    background-size: 100% 11rem;
    width: 100%;
    height: 11rem;
    margin-top: 0.5rem;
    padding: 0 0.7rem 0 1.5rem;
    box-sizing: border-box;
    position: relative;
}
.person-tab{
    position: absolute;
    right: 2rem;
    top: 1rem;
    z-index: 20;
}

.room-data-wrap{
    background-image: url("../../assets/visualizing/c-bg-big.png");
    background-size: 100% 20rem;
    width: 100%;
    height: 20rem;
    margin-top: 0.2rem;
    padding: 1rem 2rem;
    box-sizing: border-box;
    overflow: hidden;
}
.room-head{
    margin-bottom: 0.5rem;
}
.room-grid{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-auto-rows: 8rem;
    column-gap: 1rem;
    row-gap: 0.5rem;
}
.room-info{
    background-clip: border;
    border: 1px solid #A5FFFF;
    border-radius: 10px;
    padding: 0.8rem;
    box-sizing: border-box;
    justify-content: space-between;
}
.room-name{
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 0.9rem;
    color: #fff;
}
.room-desc{
    font-size: 0.7rem;
    color: #c4c4c4;
    text-align: center;
}
.room-desc + .room-desc{
    margin-top: 0.3rem;
}

.room-text1{
    font-size: 1.2rem;
    color: #06F7FF;
    font-weight: 500;
}
.room-text2{
    font-size: 1.2rem;
    color: #4DE3B1;
    font-weight: 500;
}

.ell{
       overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal !important;
    -webkit-box-orient: vertical;

}


.attend-item{
    background-image: url("../../assets/visualizing/icon1.png");
    background-size: 5rem 5rem;
    background-repeat: no-repeat;
    height: 5rem;
    width: 5rem;
    position: relative;
}
.a-icon2{
     background-image: url("../../assets/visualizing/icon2.png");
}
.a-icon3{
  background-image: url("../../assets/visualizing/icon3.png");
}
.attend-info{
    position: absolute;
    top: 0;
    left: 3rem;
    width: 8rem;
}
.attend-name{
    font-weight: 500;
    font-size: 0.9rem;
    color: #E4EDF7;
    line-height: 1.5;
}
.attend-value{
    font-size: 0.8rem;
    color: #E4EDF7;
}

.attend-wrap{
    background-size: 100% 5rem;
    width: 100%;
    height: 5rem;
    margin-top: 1.3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

.hot-room-wrap{
    height: 13rem;
}


.today-course-wrap{
    background-image: url("../../assets/visualizing/attend-bg.png");
    background-size: 100% 5rem;
    background-repeat: no-repeat;
    width: 100%;
    height: 5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.today-total{
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: 600;
    padding-left: 1.6rem;
    margin-right: 1rem;
}
.today-info{
    flex: 1;
    text-align: center;
}
.today-name{
    font-size: 0.75rem;
    margin-bottom: 0.2rem;
    color: #FFFFFF;
}
.today-value{
    font-size: 1.1rem;
    color: #19D6FF;
}

.e-title{
    font-size: 0.8rem;
    color: #DDEEFF;
    font-weight: bold;
}

</style>
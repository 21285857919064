export let addRule = {
    title: [
        { required: true, message: "请填写标题", trigger: "change" },
    ],
    price_type: [
        { required: true, message: "请选择", trigger: "change" },
    ],
    join_bond: [
        { required: true, message: "请填写保证金", trigger: "change" },
    ],
    start_price: [
        { required: true, message: "请填写起拍价", trigger: "change" },
    ],
    add_step: [
        { required: true, message: "请填写加价幅度", trigger: "change" },
    ],
    joinTime: [
        { required: true, message: "请选择报名时间", trigger: "change" },
    ],
    auctionTime: [
        { required: true, message: "请选择竞价时间", trigger: "change" },
    ]
}

<template>
  <div class="container">
    <div class="wrap">
        <div style="font-size: 1.1rem">待办数据</div>
        <div class="num-wrap m-t20">
          <div class="num-item" v-for="(item,i) in list" :key="i" @click="toPage(item)">
            <div class="num-name">{{item.name}}</div>
            <div class="num">{{ item.count || 0 }}</div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
        list:[]
    };
  },
  mounted() {
    this.getPending();
  },
  methods: {
    getPending() {
       this.$get('/user/user/pendingCount').then(res=>{
        this.list = res.data.data
       })
    },
    toPage(tab){
        if(tab.path){
            this.$router.push({path:'/main'+tab.path})
        }
    }
   
  },
};
</script>

<style lang="less" scoped>

.num-wrap {
   display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 1rem;
}
.num-item {
  flex: 1;
  text-align: center;
  padding: 20px 0;
}
.num-item:hover{
    background-color: rgb(248, 236, 214);
    border-radius: 10px;
    cursor: pointer;
}
.num-name {
  font-size: 1.1rem;
}
.num {
  font-size: 1.3rem;
  color: #fc7819;
  line-height: 2;
}
</style>
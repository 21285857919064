<template>
  <div class="container">
    <el-form :inline="true">
         <el-form-item label="状态"> 
          <el-select
            v-model="query.status"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型"> 
          <el-select
            v-model="query.type"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in typeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
         <el-button type="success" @click="toAdd">发布</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <p>{{ row.name }}</p>
          <p>{{ row.phone }}</p>
        </div>
      </template>
      <template #info="{ row }">
        <div>
          <p class="introduction">{{ row.title }}</p>
        </div>
      </template>
      <template #type="{ row }">
        <div>
         {{getByValue(row.type,'typeOption')}}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag  :type="options[row.status].type">{{getByValue(row.status,'options')}}</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" v-if="row.status == 0" size="small" @click="toDetails(row)"
            >审核</el-button
          >
          <el-button type="primary" size="small" @click="toUpdate(row)"
            >编辑</el-button
          >
          <el-button type="primary" v-if="row.status != 0" size="small" @click="toDetails(row)"
            >详情</el-button
          >
          <el-button type="success" v-if="row.status == 4" size="small" @click="updateStatus(row,3)"
            >上架</el-button
          >
          <el-button type="warning" v-if="row.status == 3" size="small" @click="updateStatus(row,4)"
            >下架</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>

export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "联系人", value: "name",type: "custom"},
        { name: "类型", value: "type" ,type: "custom"},
        { name: "城市", value: "city" },
        { name: "内容", value: "info", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
       managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
       query:{status:0},
       typeOption:[
            {
            value: 't_store',
            label: "转店",
            },
            {
            value: 't_device',
            label: "转设备",
            },
             {
            value: 'rent',
            label: "出租",
            },
            //  {
            // value: 'recruit',
            // label: "全职",
            // },
            //   {
            // value: 'recruit_p',
            // label: "兼职",
            // },
       ],
        options: [
        {
          value: 0,
          label: "待审核",
          type:'info'
        },
        {
          value: 1,
          label: "通过",
          type:'success'
        },
        {
          value: 2,
          label: "拒绝",
          type:'danger'
        },
        {
          value: 3,
          label: "上架",
          type:'success'
        },
        {
          value: 4,
          label: "下架",
          type:'danger'
        },
        {
          value: 5,
          label: "已过期",
          type:'info'
        },
         {
          value: 6,
          label: "待支付",
          type:'info'
        },
         {
          value: 7,
          label: "冻结",
          type:'info'
        },
      ],
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getByValue(v,opt){
        for(let i = 0; i < this[opt].length;i++){
            if(this[opt][i].value == v){
                return this[opt][i].label
            }
        }
    },
    toUpdate(row){
        this.$router.push({name:'createDemand',query:{id:row.id}})
    },
    toDetails(row){
        this.$router.push({name:'demandDetails',query:{id:row.id}})
    },
    toAdd(){
         this.$router.push({name:'createDemand'})
    },
    updateStatus(row,status){
        this.$post('/user/demandInfo/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/demandInfo/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status:this.query.status || null,
          type:this.query.type || null,
          // ilk:'transfer'
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.introduction{
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
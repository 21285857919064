<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import * as echarts from "echarts";
import { fontSize } from "@/js/utils";
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: ["#FFC722", "#886EFF", "#008DEC", "#114C90", "#00BFA5"],
      myChart: "",
      serData: [],
      option: {
        title: {
          top: fontSize(0.1),
          text: "",
          textStyle: {
            fontSize: fontSize(0.14),
            color: "#DDEEFF",
          },
        },
        legend: {
          right: "10%",
          top: "2%",
           icon: "roundRect",
          itemWidth: fontSize(0.12),
          itemHeight: fontSize(0.12),
          textStyle: {
            fontSize: fontSize(0.11),
            color: "#fff",
          },
        },
            tooltip: {
            trigger: 'axis'
         },

        grid: {
           right: "5%",
          top: "30%",
          left:'8%',
          bottom:'5%',
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              textStyle: {
                color: "#eee",
                 fontSize: fontSize(0.12),
              },
            },
            axisLine: {
              lineStyle: {
                color: "#4FA6FF60",
              },
            },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              textStyle: {
                color: "#fff",
                 fontSize: fontSize(0.12),
              },
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            zlevel: 3,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: fontSize(0.14),
              },
            },
            itemStyle: {
              normal: {
                color: "#36CE9E",
                lineStyle: {
                  color: "#36CE9E",
                  width: 2,
                },
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(0, 0, 0, 0)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#21da94", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
            },
            data: [],
          },
          {
            name: "",
            type: "line",
            smooth: true,
            // showSymbol: false,
            // symbolSize: 8,
            zlevel: 3,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: fontSize(0.14),
              },
            },
            itemStyle: {
              normal: {
                color: "#3A84FF",
                lineStyle: {
                  color: "#3A84FF",
                  width: 2,
                },
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(0, 0, 0, 0)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#1fb5fc", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
     this.option.title.text = this.opt.title || ''
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
      this.handlerData();
      this.myChart.setOption(this.option);
    },
    handlerData() {
      this.option.xAxis[0].data = this.data.x;
      this.option.series[0].data = this.data.y[0].data;
      this.option.series[0].name = this.data.y[0].name;
      this.option.series[1].data = this.data.y[1].data;
      this.option.series[1].name = this.data.y[1].name;
    },
    getEchartData() {
      this.handlerData();
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="less" scoped>
.a {
  color: #a71a2b;
}
</style>
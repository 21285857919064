<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="addShow = true;form={}">添加</el-button>
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" @click="getList(1)">搜索</el-button> -->
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
 <template #ctype="{ row }">
        <div>
            {{$getLabel(memberTypeList,row.card_type)}}
        </div>
      </template>
    
      <template #handle="{ row }">
        <!-- <el-button type="success" size="small" @click="openUpdate(row)"
          >修改</el-button
        > -->
          <el-button type="danger" size="small" @click="del(row)"
          >删除</el-button
        >
      </template>
    </auto-table>

    <!-- 添加员工 -->
    <el-dialog title="编辑" :visible.sync="addShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="会员卡类型">
            <el-select v-model="form.card_type" placeholder="请选择">
              <el-option v-for="(item,i) in memberTypeList" :key="i" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="名称" required>
         <el-input v-model="form.name" placeholder="请输入内容"></el-input>
        </el-form-item>
         <el-form-item label="类型" required>
         <el-input v-model="form.type" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" v-if="form.id" @click="updateForm">确 定</el-button>
        <el-button type="primary" v-else @click="addForm">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>


<script>
import {memberTypeList} from '../type.js'
export default {
  data() {
    return {
        memberTypeList:memberTypeList,
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "类型", value: "type" },
        { name: "会员卡", value: "ctype",type:'custom' },
        
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      addShow: false,
    form:{},
      
      manager: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },
  watch: {
    mechanism_id: {
      handler(nv) {
        if (nv) {
          this.getList(1);
        }
      },
      immediate: true,
    },
  },
  mounted() {
      this.getList(1)
  },
  methods: {
   del(row){
    this.$confirm("确定删除").then(()=>{
         this.$get('/user/businessActivityType/delete',{id:row.id}).then(res=>{
          this.getList(1);
        })
      })
   },
 
    openUpdate(row) {
        this.form = row
      this.addShow = true
    },

    updateForm() {
      this.$axios({
        url: "/user/memberRight/update",
        method: "post",
        data: this.form
      })
        .then((res) => {
          this.addShow = false;
          this.getList(1);
          this.$message("成功");
        })
        .catch((err) => {
          this.$message.error("失败");
        });
    },
    addForm() {
      this.$axios({
        url: "/user/memberRight/insert",
        method: "post",
        data:this.form
      })
        .then((res) => {
          this.addShow = false;
          this.getList(1);
          this.$message("添加成功");
        })
        .catch((err) => {
          this.$message.error("添加失败");
        });
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/memberRight/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.color {
  color: rgb(238, 145, 57);
}
</style>
<template>
    <div class="tabs-wrap">
        <div class="tabs-item" @click="toggle(i)" v-for="(t,i) in list"  :key="i" :class="i === index ? 't-ac' : ''"> 
            {{t.name}}
        </div>
    </div>
</template>
<script>
export default {
    props:{
        list:{
            type:Array,
            default:()=>{return []}
        },
        current:{
            type:Number,
            default:0
        }
    },
    watch:{
        current:{
            handler:function(nv){
                this.index = nv
            },
            immediate: true
        }
    },
  data() {
    return {
      index:0,
    };
  },

  mounted() {
    
  },

  methods: {
    toggle(i){
        if(this.index != i){
            this.index = i
            this.$emit('change', i);
        }
    }
  },
};
</script>
<style scoped>
.tabs-wrap{
    display: flex;
    border: 1px solid #06F7FF;
}
.tabs-item{
    font-weight: 400;
    font-size: 0.70rem;
    color: #B9C3D6;
    padding: 0 0.6rem;
    height: 1rem;
    line-height: 1rem;
    cursor: pointer;
}
.t-ac{
    background: linear-gradient(0deg, rgba(22,207,251,0.44) 0%, #012D83 100%);
    color: #fff;
}
</style>
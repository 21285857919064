import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

/**
 * meta参数
 *   meta: {
            title: "",路由标题
            Permission: Boolean, 是否需要权限,true:进入页面需要菜单权限，在权限管理-菜单中添加页面，然后在角色管理里给角色添加权限
            keepAlive: Boolean 是否需要被keep-alive缓存 需要配置页面的name和route的name相同
          },
 * 
 */
const router = new Router({
  mode: "hash",
  base: "/user/",
  routes: [
    {
      path: "/",
      redirect: {
        name: "login",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../login.vue"),
    },
    {
      path: "/hhData",
      name: "hhData",
      component: () => import("../views/visualizing/hhData.vue"),
    },
    {
      path: "/spaceData",
      name: "spaceData",
      component: () => import("../views/visualizing/spaceData.vue"),
    },
    {
      path: "/spaceAdminData",
      name: "spaceAdminData",
      component: () => import("../views/visualizing/spaceAdminData.vue"),
    },
    {
      path: "/masterData",
      name: "masterData",
      component: () => import("../views/visualizing/masterData.vue"),
    },
    {
      path: "/coachData",
      name: "coachData",
      component: () => import("../views/visualizing/coachData.vue"),
    },
    {
      path: "/hhSpaceData",
      name: "hhSpaceData",
      component: () => import("../views/visualizing/hhSpaceData.vue"),
    },
    {
      path: "/mecData",
      name: "mecData",
      component: () => import("../views/visualizing/mecData.vue"),
    },
    {
      path: "/main",
      name: "main",
      component: () => import("../main.vue"),
      redirect: "/main/indexDataBoard",
      children: [
        {
          path: "indexDataBoard",
          name: "indexDataBoard",
          component: () => import("../views/dataBoard/indexDataBoard.vue"),
          meta: {
            title: "数据看板",
            Permission: true,
          },
        },
        {
          path: "agentDataBoard",
          name: "agentDataBoard",
          component: () => import("../views/dataBoard/allAgentData.vue"),
          meta: {
            title: "代理商数据看板",
            Permission: true,
          },
        },
        {
          path: "databoard",
          name: "databoard",
          component: () => import("../views/outSchool/databoard.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "mecList",
          name: "mecList",
          component: () => import("../views/outSchool/mecList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "mecList1",
          name: "mecList1",
          component: () => import("../views/outSchool/mecList1.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "mecCouchList",
          name: "mecCouchList",
          component: () => import("../views/outSchool/mecCouchList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "cooperList",
          name: "cooperList",
          component: () => import("../views/cooperation/cooperList.vue"),
          meta: {
            title: "合作商列表",
            Permission: true,
          },
        },
        {
          path: "agentList",
          name: "agentList",
          component: () => import("../views/agentManage/agentList.vue"),
          meta: {
            title: "代理商列表",
            Permission: true,
          },
        },
        {
          path: "agentApply",
          name: "agentApply",
          component: () => import("../views/agentManage/agentApply.vue"),
          meta: {
            title: "代理商申请列表",
            Permission: true,
          },
        },
        {
          path: "agentDetails",
          name: "agentDetails",
          component: () => import("../views/agentManage/agentDetails.vue"),
          meta: {
            title: "代理商详情",
            Permission: false,
          },
        },
        {
          path: "ipRecord",
          name: "ipRecord",
          component: () => import("../views/ipManage/ipRecord.vue"),
          meta: {
            title: "ip开通列表",
            Permission: true,
          },
        },
        {
          path: "ipApplyList",
          name: "ipApplyList",
          component: () => import("../views/ipManage/ipApplyList.vue"),
          meta: {
            title: "教练ip申请",
            Permission: true,
          },
        },
        {
          path: "coachApplyip",
          name: "coachApplyip",
          component: () => import("../views/ipManage/coachApplyip.vue"),
          meta: {
            title: "ip申请",
            Permission: false,
          },
        },
        {
          path: "productList",
          name: "productList",
          component: () => import("../views/productManage/productList.vue"),
          meta: {
            title: "商品列表",
            Permission: true,
          },
        },
        {
          path: "createProduct",
          name: "createProduct",
          component: () => import("../views/productManage/create.vue"),
          meta: {
            title: "创建商品",
            Permission: false,
          },
        },
        {
          path: "auctionManage",
          name: "auctionManage",
          component: () => import("../views/combinationCourse/auctionManage.vue"),
          meta: {
            title: "拍卖管理",
            Permission: true,
          },
        },
        {
          path: "auctionDetails",
          name: "auctionDetails",
          component: () => import("../views/combinationCourse/auctionDetails.vue"),
          meta: {
            title: "拍卖详情",
            Permission: false,
          },
        },
        {
          path: "proApply",
          name: "proApply",
          component: () => import("../views/productManage/applyList.vue"),
          meta: {
            title: "服务申请",
            Permission: true,
          },
        },
        {
          path: "addARecord",
          name: "addARecord",
          component: () => import("../views/mechanismManage/addARecord.vue"),
          meta: {
            title: "添加记录",
            Permission: false,
          },
        },
        {
          path: "productRecord",
          name: "productRecord",
          component: () => import("../views/productManage/productRecord.vue"),
          meta: {
            title: "开通记录",
            Permission: true,
          },
        },
        {
          path: "sharedSpace",
          name: "sharedSpace",
          component: () => import("../views/sharedSpace/space/sharedSpaceList.vue"),
          meta: {
            title: "共享空间",
            Permission: true,
          },
        },
        {
          path: "sharedSpaceDetail",
          name: "sharedSpaceDetail",
          component: () => import("../views/sharedSpace/space/sharedSpaceDetail.vue"),
          meta: {
            title: "共享空间详情",
            Permission: false,
          },
        },
        {
          path: "sharedVenueList",
          name: "sharedVenueList",
          component: () => import("../views/sharedSpace/venue/sharedVenueList.vue"),
          meta: {
            title: "共享场地",
            Permission: true,
          },
        },
        {
          path: "sharedVenueDetail",
          name: "sharedVenueDetail",
          component: () => import("../views/sharedSpace/venue/sharedVenueDetail.vue"),
          meta: {
            title: "共享场地详情",
            Permission: false,
          },
        },
        {
          path: "serviceScheduling",
          name: "serviceScheduling",
          component: () => import("../views/sharedSpace/scheduling/scheduling.vue"),
          meta: {
            title: "共享场地排期",
            Permission: false,
          },
        },
        {
          path: "discountTaskList",
          name: "discountTaskList",
          component: () => import("../views/discountApply/taskList.vue"),
          meta: {
            title: "用户提交砍价",
            Permission: true,
          },
        },
        {
          path: "mytask",
          name: "mytask",
          component: () => import("../views/discountApply/myTask.vue"),
          meta: {
            title: "我的任务",
            Permission: false,
          },
        },
        {
          path: "activeTask",
          name: "activeTask",
          component: () => import("../views/discountApply/activeTask.vue"),
          meta: {
            title: "待领任务",
            Permission: true,
          },
        },
        {
          path: "applyFlowDetail",
          name: "applyFlowDetail",
          component: () => import("../views/discountApply/applyFlowDetail.vue"),
          meta: {
            title: "详情",
            Permission: false,
          },
        },
      
        {
          path: "activeRecord",
          name: "activeRecord",
          component: () => import("../views/CooperatorManage/activeRecord.vue"),
          meta: {
            title: "用户到店记录",
            Permission: true,
          },
        },

        {
          path: "serviceAppointmentTemp",
          name: "serviceAppointmentTemp",
          component: () => import("../views/serviceManage/serviceAppointment/serviceAppointmentTemp.vue"),
          meta: {
            title: "服务排期管理",
            Permission: true,
          },
        },
        {
          path: "serviceCreateAppointment",
          name: "serviceCreateAppointment",
          component: () => import("../views/serviceManage/serviceAppointment/serviceCreateAppointment.vue"),
          meta: {
            title: "创建服务排期",
            Permission: true,
          },
        },
        {
          path: "serviceTemplateEdit",
          name: "serviceTemplateEdit",
          component: () => import("../views/serviceManage/serviceTemplateEdit/serviceTemplateEdit.vue"),
          meta: {
            title: "服务排期编辑",
            Permission: false,
          },
        },
        {
          path: "MerchantIntention",
          name: "MerchantIntention",
          component: () =>
            import("../views/operationsManage/MerchantIntention.vue"),
          meta: {
            title: "机构意向列表",
            Permission: true,
          },
        },
        {
          path: "violation",
          name: "violation",
          component: () =>
            import("../views/operationsManage/violation.vue"),
          meta: {
            title: "用户举报",
            Permission: true,
          },
        },
        {
          path: "agentIncome",
          name: "agentIncome",
          component: () =>
            import("../views/incomeManage/agentIncome.vue"),
          meta: {
            title: "代理商收益列表",
            Permission: true,
          },
        },
        {
          path: "agentTransfer",
          name: "agentTransfer",
          component: () =>
            import("../views/incomeManage/agentTransfer.vue"),
          meta: {
            title: "代理商收益提现",
            Permission: true,
          },
        },
        {
          path: "staffList",
          name: "staffList",
          component: () =>
            import("../views/staffManage/staffList.vue"),
          meta: {
            title: "代理商员工管理",
            Permission: true,
          },
        },

        {
          path: "mecPlaceList",
          name: "mecPlaceList",
          component: () => import("../views/outSchool/mecPlaceList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "mecMap",
          name: "mecMap",
          component: () => import("../views/outSchool/mecMap.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "mecCouList",
          name: "mecCouList",
          component: () => import("../views/courseManage/courseList.vue"),
          meta: {
            title: "课程/场馆服务列表",
            Permission: true,
          },
        },
        {
          path: "mecPayList",
          name: "mecPayList",
          component: () => import("../views/outSchool/mecPayList.vue"),
          meta: {
            title: "兑换列表",
            Permission: true,
          },
        },
        {
          path: "courseTable",
          name: "courseTable",
          component: () => import("../views/outSchool/courseTable.vue"),
          meta: {
            title: "课程表",
            Permission: true,
          },
        },
        {
          path: "createCouTable",
          name: "createCouTable",
          component: () => import("../views/outSchool/createCouTable.vue"),
          meta: {
            title: "预约课表",
            Permission: true,
          },
        },
        {
          path: "manageList",
          name: "manageList",
          component: () => import("../views/outSchool/manageList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "checkMechanism",
          name: "checkMechanism",
          component: () => import("../views/shenhezhongxin/checkMechanism.vue"),
          meta: {
            title: "机构入驻",
            Permission: true,
          },
        },
        {
          path: "checkCourse",
          name: "checkCourse",
          component: () => import("../views/shenhezhongxin/checkCourse.vue"),
          meta: {
            title: "课程添加",
            Permission: true,
          },
        },
        {
          path: "checkPoint",
          name: "checkPoint",
          component: () => import("../views/shenhezhongxin/checkPoint.vue"),
          meta: {
            title: "课程添加",
            Permission: true,
          },
        },
        {
          path: "mecList/mecDetails",
          name: "mecDetails",
          component: () => import("../views/outSchool/mecDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "DetailsPage",
          name: "DetailsPage",
          component: () => import("../views/outSchool/DetailsPage.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },

        {
          path: "TimeManage",
          name: "TimeManage",
          component: () => import("../views/outSchool/TimeManage.vue"),
          meta: {
            title: "课程时间审核",
            Permission: true,
          },
        },
        {
          path: "courseSettlementManage",
          name: "courseSettlementManage",
          component: () =>
            import("../views/outSchool/courseSettlementManage.vue"),
          meta: {
            title: "课程核销管理",
            Permission: true,
          },
        },
        {
          path: "courseTimeManage",
          name: "courseTimeManage",
          component: () => import("../views/outSchool/courseTimeManage.vue"),
          meta: {
            title: "课程时间调整",
            Permission: true,
          },
        },

        {
          path: "UserStates",
          name: "UserStates",
          component: () => import('../views/UserCenter/UserStates.vue'),
          meta: {
            title: "",
            Permission: true,
          },
        },

        {
          path: "createSign",
          name: "createSign",
          component: () => import('../views/esignManage/createSign/createSign.vue'),
          meta: {
            title: "发起签署",
            Permission: false,
          },
        },

        {
          path: "eTemplateList",
          name: "eTemplateList",
          component: () => import('../views/esignManage/eTemplate/eTemplateList.vue'),
          meta: {
            title: "文件模板列表",
            Permission: true,
          },
        },
        {
          path: "signTemplateList",
          name: "signTemplateList",
          component: () => import('../views/esignManage/eSignTemplate/signTemplateList.vue'),
          meta: {
            title: "签署模板列表",
            Permission: true,
          },
        },
        {
          path: "signFlowList",
          name: "signFlowList",
          component: () => import('../views/esignManage/eSignFlow/signFlowList.vue'),
          meta: {
            title: "签署合同列表",
            Permission: true,
          },
        },
        {
          path: "signFlowRecordList",
          name: "signFlowRecordList",
          component: () => import('../views/esignManage/signFlowRecord/signFlowRecordList.vue'),
          meta: {
            title: "签署记录列表",
            Permission: true,
          },
        },
        {
          path: "orgToSign",
          name: "orgToSign",
          component: () => import('../views/esignManage/handlerSign/orgToSign.vue'),
          meta: {
            title: "签署",
            Permission: false,
          },
        },
        
        
        {
          path: "fileCreateSuccess",
          name: "fileCreateSuccess",
          component: () => import('../views/esignManage/eTemplate/createSuccess.vue'),
          meta: {
            title: "文件生成成功",
            Permission: false,
          },
        },
        
        {
          path: "registerUserList",
          name: "registerUserList",
          component: () => import("../views/UserCenter/registerUserList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "Usermanagement",
          name: "Usermanagement",
          component: () => import("../views/UserCenter/Usermanagement.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "UserList",
          name: "UserList",
          component: () => import("../views/UserCenter/UserList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "stuDetails",
          name: "stuDetails",
          component: () => import("../views/DetailsInfo/stuDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "couchDetails",
          name: "couchDetails",
          component: () => import("../views/DetailsInfo/couchDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "CouponGroup",
          name: "CouponGroup",
          component: () => import("../views/CouponManagement/CouponGroup.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "CouponGroupRecord",
          name: "CouponGroupRecord",
          component: () =>
            import("../views/CouponManagement/CouponGroupRecord.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "CouponUseRecord",
          name: "CouponUseRecord",
          component: () =>
            import("../views/CouponManagement/CouponUseRecord.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "CouponList",
          name: "CouponList",
          component: () => import("../views/CouponManagement/CouponList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "purchaseTicketLog",
          name: "purchaseTicketLog",
          component: () =>
            import("../views/CouponManagement/purchaseTicketLog.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "cooperCoupon",
          name: "cooperCoupon",
          component: () => import("../views/CouponManagement/cooperCoupon.vue"),
          meta: {
            title: "合作商发卡",
            Permission: true,
          },
        },
        {
          path: "CouponType",
          name: "CouponType",
          component: () => import("../views/CouponManagement/CouponType.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "couponLog",
          name: "couponLog",
          component: () => import("../views/CouponManagement/couponLog.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },

        {
          path: "BabyShowAudit",
          name: "BabyShowAudit",
          component: () => import("../views/messageManage/BabyShowAudit.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "InformationReview",
          name: "InformationReview",
          component: () =>
            import("../views/messageManage/InformationReview.vue"),
          meta: {
            title: "内容审核",
            Permission: true,
          },
        },
        {
          path: "couponPackage",
          name: "couponPackage",
          component: () =>
            import("../views/Cardandvouchermanagement/couponPackageManage.vue"),
          meta: {
            title: "券包管理",
            Permission: true,
          },
        },
        {
          path: "editCouponPackage",
          name: "editCouponPackage",
          component: () =>
            import("../views/Cardandvouchermanagement/editCouponPackage.vue"),
          meta: {
            title: "券包管理",
            Permission: false,
          },
        },
        {
          path: "settingCooperator",
          name: "settingCooperator",
          component: () =>
            import("../views/activityManage/settingCooperator.vue"),
          meta: {
            title: "合作商",
            Permission: false,
          },
        },
        {
          path: "courseReview",
          name: "courseReview",
          component: () => import("../views/messageManage/courseReview.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "teacherTypeList",
          name: "teacherTypeList",
          component: () =>
            import("../views/categoriesManage/teacherTypeList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "categories",
          name: "categories",
          component: () => import("../views/categoriesManage/categories.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "categories2",
          name: "categories2",
          component: () => import("../views/categoriesManage/categories2.vue"),
          meta: {
            title: "课程类目管理",
            Permission: true,
          },
        },

        {
          path: "ActivityManagement",
          name: "ActivityManagement",
          component: () =>
            import("../views/activityManage/ActivityManagement.vue"),
          meta: {
            title: "活动管理",
            Permission: true,
          },
        },
        {
          path: "addActivity",
          name: "addActivity",
          component: () => import("../views/activityManage/addActivity.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "activityType",
          name: "activityType",
          component: () => import("../views/activityManage/activityType.vue"),
          meta: {
            title: "活动类型管理",
            Permission: true,
          },
        },
        {
          path: "ActivityDetails",
          name: "ActivityDetails",
          component: () =>
            import("../views/activityManage/ActivityDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },

        {
          path: "UCSACourseList",
          name: "UCSACourseList",
          component: () => import("../views/activityManage/UCSACourseList.vue"),
          meta: {
            title: "礼物管理",
            Permission: true,
          },
        },
        {
          path: "UCSAActivities",
          name: "UCSAActivities",
          component: () => import("../views/activityManage/UCSAActivities.vue"),
          meta: {
            title: "赞助商管理",
            Permission: true,
          },
        },
        {
          path: "UCSAOrderList",
          name: "UCSAOrderList",
          component: () => import("../views/activityManage/UCSAOrderList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "admininfoCenter",
          name: "admininfoCenter",
          component: () =>
            import("../views/adminCenter/adminInfoCenter.vue"),
          meta: {
            title: "个人中心",
            Permission: false,
          },
        },
        {
          path: "UCSAOrderRedPackets",
          name: "UCSAOrderRedPackets",
          component: () =>
            import("../views/activityManage/UCSAOrderRedPackets.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "FakeDataManagement",
          name: "FakeDataManagement",
          component: () =>
            import("../views/activityManage/FakeDataManagement.vue"),
          meta: {
            title: "活动数据管理",
            Permission: true,
          },
        },
        {
          path: "UserInvitationList",
          name: "UserInvitationList",
          component: () =>
            import("../views/activityManage/UserInvitationList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "unionList",
          name: "unionList",
          component: () =>
            import("../views/unionManage/unionList.vue"),
          meta: {
            title: "工会列表",
            Permission: true,
          },
        },
        {
          path: "unionApplyList",
          name: "unionApplyList",
          component: () =>
            import("../views/unionManage/unionApplyList.vue"),
          meta: {
            title: "工会申请列表",
            Permission: true,
          },
        },
        {
          path: "unionClassCourse",
          name: "unionClassCourse",
          component: () =>
            import("../views/unionManage/unionClassCourse.vue"),
          meta: {
            title: "公会班级课程",
            Permission: true,
          },
        },
        {
          path: "unionDetails",
          name: "unionDetails",
          component: () =>
            import("../views/unionManage/unionDetails.vue"),
          meta: {
            title: "工会详情",
            Permission: false,
          },
        },
        {
          path: "Daping",
          name: "Daping",
          component: () => import("../views/activityManage/Daping.vue"),
          meta: {
            title: "大屏展示",
            Permission: true,
          },
        },
        {
          path: "Dapingmu",
          name: "Dapingmu",
          component: () => import("../views/activityManage/Dapingmu.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "dyLiveData",
          name: "dyLiveData",
          component: () => import("../views/largeDataShow/dyLiveData.vue"),
          meta: {
            title: "抖音数据大屏",
            Permission: false,
          },
        },
        {
          path: "dyActiveManage",
          name: "dyActiveManage",
          component: () => import("../views/DyLive/activeManage.vue"),
          meta: {
            title: "抖音活动管理",
            Permission: true,
          },
        },
        {
          path: "dyLiveData",
          name: "dyLiveData",
          component: () => import("../views/largeDataShow/dyLiveData.vue"),
          meta: {
            title: "抖音数据大屏",
            Permission: false,
          },
        },
        {
          path: "dyPayOderList",
          name: "dyPayOderList",
          component: () => import("../views/largeDataShow/dyPayOderList.vue"),
          meta: {
            title: "抖音订单列表",
            Permission: false,
          },
        },
        {
          path: "globalInfo",
          name: "globalInfo",
          component: () => import("../views/globalInfo/globalInfo.vue"),
          meta: {
            title: "全局配置",
            Permission: false,
          },
        },
        {
          path: "mecBondCheck",
          name: "mecBondCheck",
          component: () => import("../views/mechanismManage/mecBondCheck.vue"),
          meta: {
            title: "机构保证金审核",
            Permission: false,
          },
        },
        {
          path: "Activityaction",
          name: "Activityaction",
          component: () => import("../views/activityManage/Activityaction.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "Selectcourse",
          name: "Selectcourse",
          component: () => import("../views/activityManage/Selectcourse.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "repairWriteOff",
          name: "repairWriteOff",
          component: () => import("../views/courseManage/repairWriteOff.vue"),
          meta: {
            title: "课程补录管理",
            Permission: false,
          },
        },
        {
          path: "EventDetails",
          name: "EventDetails",
          component: () => import("../views/activityManage/EventDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "TeachFuBaodetails",
          name: "TeachFuBaodetails",
          component: () => import("../views/orgManage/TeachFuBaodetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "createAppointment",
          name: "createAppointment",
          component: () => import("../views/courseManage/createAppointment.vue"),
          meta: {
            title: "生成课表",
            Permission: true,
          },
        },
        {
          path: "taskList",
          name: "taskList",
          component: () => import("../views/task/taskList.vue"),
          meta: {
            title: "任务列表",
            Permission: true,
          },
        },
        {
          path: "mpList",
          name: "mpList",
          component: () => import("../views/managingPartner/managingPartnerList.vue"),
          meta: {
            title: "主理人列表",
            Permission: true,
          },
        },
        {
          path: "mpDetail",
          name: "mpDetail",
          component: () => import("../views/managingPartner/details.vue"),
          meta: {
            title: "主理人详情",
            Permission: false,
          },
        },
        {
          path: "needTask",
          name: "needTask",
          component: () => import("../views/task/needTaskDetails.vue"),
          meta: {
            title: "任务详情",
            Permission: false,
          },
        },
        {
          path: "myTask",
          name: "myTask",
          component: () => import("../views/task/myTask.vue"),
          meta: {
            title: "我的任务",
            Permission: false,
          },
        },
        {
          path: "devtest",
          name: "devtest",
          component: () => import("../views/test/test.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "VIPCard",
          name: "VIPCard",
          component: () => import("../views/Member/VIPCard.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "agentD",
          name: "agentD",
          component: () => import("../views/test/agentData/dataDetails.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "agentDList",
          name: "agentDList",
          component: () => import("../views/test/agentData/dataList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "VIPLevel",
          name: "VIPLevel",
          component: () => import("../views/Member/VIPLevel.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "youxueCardList",
          name: "youxueCardList",
          component: () => import("../views/Member/youxueCardList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "zunxiangCardList",
          name: "zunxiangCardList",
          component: () => import("../views/Member/zunxiangCardList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "zhuanxiangCardList",
          name: "zhuanxiangCardList",
          component: () => import("../views/Member/zhuanxiangCardList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "consumeList",
          name: "consumeList",
          component: () => import("../views/Cardandvouchermanagement/consumeList.vue"),
          meta: {
            title: "消费金列表",
            Permission: true,
          },
        },
        {
          path: "chatCardList",
          name: "chatCardList",
          component: () => import("../views/Cardandvouchermanagement/chatCardList.vue"),
          meta: {
            title: "畅聊卡开通管理",
            Permission: true,
          },
        },
        {
          path: "talentCardList",
          name: "talentCardList",
          component: () => import("../views/Cardandvouchermanagement/talentCard/talentCardList.vue"),
          meta: {
            title: "天赋卡管理",
            Permission: true,
          },
        },
        {
          path: "bindCPCoop",
          name: "bindCPCoop",
          component: () => import("../views/Cardandvouchermanagement/bindCPCoop.vue"),
          meta: {
            title: "绑定合作商",
            Permission: false,
          },
        },
        {
          path: "talentCardCourse",
          name: "talentCardCourse",
          component: () => import("../views/Cardandvouchermanagement/talentCard/talentCardCourse.vue"),
          meta: {
            title: "天赋卡管理",
            Permission: false,
          },
        },
        {
          path: "OrderDataBoard",
          name: "OrderDataBoard",
          component: () => import("../views/financialManage/DataBoard.vue"),
          meta: {
            title: "课程订单",
            Permission: true,
          },
        },
        {
          path: "expendOrderList",
          name: "expendOrderList",
          component: () => import("../views/financialManage/expendOrderList.vue"),
          meta: {
            title: "支出列表",
            Permission: true,
          },
        },
        {
          path: "courseOrderList",
          name: "courseOrderList",
          component: () => import("../views/financialManage/courseOrderList.vue"),
          meta: {
            title: "课程订单",
            Permission: true,
          },
        },
         {
          path: "compareCourseList",
          name: "compareCourseList",
          component: () => import("../views/compareManage/compareCourseList.vue"),
          meta: {
            title: "比价课程",
            Permission: true,
          },
        },
        {
          path: "cooperatorList",
          name: "cooperatorList",
          component: () => import("../views/CooperatorManage/cooperatorList.vue"),
          meta: {
            title: "合作商列表",
            Permission: true,
          },
        },
        {
          path: "classManage",
          name: "classManage",
          component: () => import("../views/CooperatorManage/classManage.vue"),
          meta: {
            title: "班级管理",
            Permission: true,
          },
        },
        {
          path: "allOrderList",
          name: "allOrderList",
          component: () => import("../views/financialManage/allOrderList.vue"),
          meta: {
            title: "全部订单",
            Permission: false,
          },
        },
        {
          path: "invoiceManage",
          name: "invoiceManage",
          component: () => import("../views/financialManage/invoiceManage.vue"),
          meta: {
            title: "发票管理",
            Permission: true,
          },
        },
        {
          path: "billManager",
          name: "billManager",
          component: () => import("../views/financialManage/billManager.vue"),
          meta: {
            title: "财务账单",
            Permission: true,
          },
        },
        {
        path: "communityCourseList",
        name: "communityCourseList",
        component: () => import("../views/courseManage/communityCourseList.vue"),
        meta: {
          title: "财务账单",
          Permission: true,
        },
      },
      {
        path: "cooperatorDetails",
        name: "cooperatorDetails",
        component: () => import("../views/CooperatorManage/cooperatorDetails.vue"),
        meta: {
          title: "合作商详情",
          Permission: false,
        },
      },
      {
        path: "coopShop",
        name: "coopShop",
        component: () => import("../views/CooperatorManage/shopManage.vue"),
        meta: {
          title: "合作商店铺",
          Permission: false,
        },
      },
      
        {
          path: "pointOrderList",
          name: "pointOrderList",
          component: () => import("../views/financialManage/pointOrderList.vue"),
          meta: {
            title: "权益点充值订单",
            Permission: true,
          },
        },
        {
          path: "needOrderList",
          name: "needOrderList",
          component: () => import("../views/financialManage/needOrderList.vue"),
          meta: {
            title: "教练课程订单",
            Permission: true,
          },
        },
        {
          path: "memberOrderList",
          name: "memberOrderList",
          component: () => import("../views/financialManage/memberOrderList.vue"),
          meta: {
            title: "会员卡订单",
            Permission: true,
          },
        },
        {
          path: "lifeOrderList",
          name: "lifeOrderList",
          component: () => import("../views/financialManage/lifeOrderList.vue"),
          meta: {
            title: "休闲生活订单",
            Permission: true,
          },
        },
        {
          path: "couponOrderList",
          name: "couponOrderList",
          component: () => import("../views/financialManage/couponOrderList.vue"),
          meta: {
            title: "优惠券订单",
            Permission: true,
          },
        },
        {
          path: "consumeOrderList",
          name: "consumeOrderList",
          component: () => import("../views/financialManage/consumeOrderList.vue"),
          meta: {
            title: "消费金订单",
            Permission: true,
          },
        },
        {
          path: "venueOrderList",
          name: "venueOrderList",
          component: () => import("../views/financialManage/venueOrderList.vue"),
          meta: {
            title: "场馆订单",
            Permission: true,
          },
        },
        {
          path: "pointList",
          name: "pointList",
          component: () => import("../views/financialManage/pointList.vue"),
          meta: {
            title: "权益点充值",
            Permission: true,
          },
        },
        {
          path: "commission",
          name: "commission",
          component: () => import("../views/financialManage/commission.vue"),
          meta: {
            title: "佣金明细",
            Permission: true,
          },
        },
        {
          path: "refundMange",
          name: "refundMange",
          component: () => import("../views/financialManage/refundManage.vue"),
          meta: {
            title: "机构结算",
            Permission: true,
          },
        },
        {
          path: "settlementList",
          name: "settlementList",
          component: () =>
            import("../views/financialManage/settlementList.vue"),
          meta: {
            title: "流水记录",
            Permission: true,
          },
        },
        {
          path: "orderManage",
          name: "orderManage",
          component: () => import("../views/financialManage/orderManage.vue"),
          meta: {
            title: "订单管理",
            Permission: true,
          },
        },
        {
          path: "orderLog",
          name: "orderLog",
          component: () => import("../views/financialManage/orderLog.vue"),
          meta: {
            title: "转账错误日志",
            Permission: true,
          },
        },
        {
          path: "financialmanagement",
          name: "financialmanagement",
          component: () =>
            import("../views/financialManage/financialmanagement.vue"),
          meta: {
            title: "财务管理",
            Permission: true,
          },
        },

        {
          path: "goodTypeManage",
          name: "goodTypeManage",
          component: () => import("../views/eshopManage/goodTypeManage.vue"),
          meta: {
            title: "",
            Permission: false,
            
          },
        },
        {
          path: "todayPending",
          name: "todayPending",
          component: () => import("../views/dataBoard/todayPending.vue"),
          meta: {
            title: "",
            Permission: false,
            
          },
        },
        {
          path: "PermissionSettings",
          name: "PermissionSettings",
          component: () =>
            import("../views/PermissionManage/PermissionSettings.vue"),
          meta: {
            title: "权限设置",
            Permission: true,
          },
        },
        {
          path: "MenuSettings",
          name: "MenuSettings",
          component: () => import("../views/PermissionManage/MenuSettings.vue"),
          meta: {
            title: "菜单管理",
            Permission: true,
          },
        },
        {
          path: "RoleSettings",
          name: "RoleSettings",
          component: () => import("../views/PermissionManage/RoleSettings.vue"),
          meta: {
            title: "角色管理",
            Permission: true,
          },
        },
        {
          path: "goodManage",
          name: "goodManage",
          component: () => import("../views/eshopManage/goodManage.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "advertisementList",
          name: "advertisementList",
          component: () =>
            import("../views/advertisementManage/advertisementList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "advertisementType",
          name: "advertisementType",
          component: () =>
            import("../views/advertisementManage/advertisementType.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "userDetails/:id",
          name: "userDetails",
          component: () => import("../views/DetailsInfo/userDetails.vue"),
          meta: {
            title: "用户详情",
            Permission: false,
          },
        },
        {
          path: "serveDetails/:id",
          name: "serveDetails",
          component: () => import("../views/DetailsInfo/serveDetails.vue"),
          meta: {
            title: "服务详情",
            Permission: false,
          },
        },
        {
          path: "venueDetails/:id",
          name: "venueDetails",
          component: () => import("../views/DetailsInfo/venueDetails.vue"),
          meta: {
            title: "场馆详情",
            Permission: false,
          },
        },
        {
          path: "courseDetails/:id",
          name: "courseDetails",
          component: () => import("../views/DetailsInfo/courseDetails.vue"),
          meta: {
            title: "用户详情",
            Permission: false,
          },
        },
        {
          path: "consultantDetails/:id",
          name: "consultantDetails",
          component: () => import("../views/DetailsInfo/consultantDetails.vue"),
          meta: {
            title: "顾问详情",
            Permission: false,
          },
        },
        {
          path: "consultantPlanDetails/:id",
          name: "consultantPlanDetails",
          component: () => import("../views/DetailsInfo/consultantPlanDetails.vue"),
          meta: {
            title: "计划详情",
            Permission: false,
          },
        },
        {
          path: "orderDetails/:id",
          name: "orderDetails",
          component: () => import("../views/Ordermanagement/orderDetails.vue"),
          meta: {
            title: "订单详情",
            Permission: false,
          },
        },
        {
          path: "userInfoFeedbck",
          name: "userInfoFeedbck",
          component: () =>
            import("../views/operationsManage/userInfoFeedbck.vue"),
          meta: {
            title: "用户信息反馈",
            Permission: true,
          },
        },
        {
          path: "businessesRecruit",
          name: "businessesRecruit",
          component: () =>
            import("../views/operationsManage/businessesRecruit.vue"),
          meta: {
            title: "商家招募信息",
            Permission: true,
          },
        },
        {
          path: "advertisingManage",
          name: "advertisingManage",
          component: () =>
            import("../views/operationsManage/advertisingManage.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "pageShare",
          name: "pageShare",
          component: () =>
            import("../views/operationsManage/pageShare.vue"),
          meta: {
            title: "页面分享",
            Permission: false,
          },
        },
        {
          path: "Ad",
          name: "Ad",
          component: () => import("../views/operationsManage/Ad.vue"),
          meta: {
            title: "广告位统计",
            Permission: true,
          },
        },
        {
          path: "MobileAd",
          name: "MobileAd",
          component: () => import("../views/operationsManage/MobileAd.vue"),
          meta: {
            title: "移动端广告位",
            Permission: true,
          },
        },
        {
          path: "WebAd",
          name: "WebAd",
          component: () => import("../views/operationsManage/WebAd.vue"),
          meta: {
            title: "Web广告位",
            Permission: true,
          },
        },

        {
          path: "Merchant",
          name: "Merchant",
          component: () => import("../views/Merchant/Merchant.vue"),
          meta: {
            title: "商户管理",
            Permission: true,
          },
        },
        {
          path: "Merchant1",
          name: "Merchant1",
          component: () => import("../views/Merchant/Merchant1.vue"),
          meta: {
            title: "商户管理",
            Permission: true,
          },
        },
        {
          path: "MessageList",
          name: "MessageList",
          component: () => import("../views/Message/messageList.vue"),
          meta: {
            title: "消息列表",
            Permission: false,
          },
        },
        {
          path: "sendMessage",
          name: "sendMessage",
          component: () => import("../views/Message/sendMessage.vue"),
          meta: {
            title: "发送消息",
            Permission: false,
          },
        },
        {
          path: "MerchantDetail",
          name: "MerchantDetail",
          component: () => import("../views/Merchant/MerchantDetail.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "MerchantCourse",
          name: "MerchantCourse",
          component: () => import("../views/Merchant/MerchantCourse.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "MerchantCategory",
          name: "MerchantCategory",
          component: () => import("../views/Merchant/MerchantCategory.vue"),
          meta: {
            title: "商户类目管理",
            Permission: true,
          },
        },
        {
          path: "Merchantcount",
          name: "Merchantcount",
          component: () => import("../views/Merchant/Merchantcount.vue"),
          meta: {
            title: "商户核销限制",
            Permission: true,
          },
        },

        {
          path: "MerchantRecruit",
          name: "MerchantRecruit",
          component: () => import("../views/Merchant/MerchantRecruit.vue"),
          meta: {
            title: "商户审核",
            Permission: true,
          },
        },

        {
          path: "CashAccountManage",
          name: "CashAccountManage",
          component: () =>
            import("../views/financialManage/CashAccountManage.vue"),
          meta: {
            title: "账户管理",
            Permission: true,
          },
        },

        {
          path: "bonusList",
          name: "bonusList",
          component: () =>
            import("../views/bonusManager/bonusList.vue"),
          meta: {
            title: "员工分成列表",
            Permission: true,
          },
        },
        {
          path: "bonusDetails",
          name: "bonusDetails",
          component: () =>
            import("../views/bonusManager/bonusDetails.vue"),
          meta: {
            title: "员工分成详情",
            Permission: false,
          },
        },

        {
          path: "RecommenderList",
          name: "RecommenderList",
          component: () =>
            import("../views/RecommenderManage/RecommenderList.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },

        {
          path: "wang",
          name: "wang",
          component: () => import("../views/wang.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "Taskranking",
          name: "Taskranking",
          component: () => import("../views/RecommenderManage/Taskranking.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "RecommenderRule",
          name: "RecommenderRule",
          component: () =>
            import("../views/RecommenderManage/RecommenderRule.vue"),
          meta: {
            title: "",
            Permission: true,
          },
        },
        {
          path: "databoard1",
          name: "databoard1",
          component: () => import("../views/outSchool/databoard1.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "membershipcard",
          name: "membershipcard",
          component: () =>
            import("../views/Cardandvouchermanagement/membershipcard.vue"),
          meta: {
            title: "会员卡管理",
            Permission: false,
          },
        },
        {
          path: "consumerCoupon",
          name: "consumerCoupon",
          component: () =>
            import("../views/Cardandvouchermanagement/consumerCoupon.vue"),
          meta: {
            title: "消费券管理",
            Permission: false,
          },
        },
        {
          path: "courseLeaveManage",
          name: "courseLeaveManage",
          component: () =>
            import("../views/courseManage/courseLeaveManage.vue"),
          meta: {
            title: "用户请假管理",
            Permission: true,
          },
        },
        {
          path: "groupClassManage",
          name: "groupClassManage",
          component: () =>
            import("../views/groupClassManage/groupList.vue"),
          meta: {
            title: "课程拼班列表",
            Permission: true,
          },
        },
        
        {
          path: "Refundaudit",
          name: "Refundaudit",
          component: () => import("../views/Ordermanagement/Refundaudit.vue"),
          meta: {
            title: "退款审核",
            Permission: true,
          },
        },
        {
          path: "refundDetails",
          name: "refundDetails",
          component: () => import("../views/Ordermanagement/refundDetails.vue"),
          meta: {
            title: "售后订单详情",
            Permission: false,
          },
        },
        {
          path: "compensateDetails",
          name: "compensateDetails",
          component: () => import("../views/Ordermanagement/compensateDetails.vue"),
          meta: {
            title: "补偿申请详情",
            Permission: false,
          },
        },
        {
          path: "ConsultantSettings",
          name: "ConsultantSettings",
          component: () =>
            import("../views/Consultantmanagement/ConsultantSettings.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "planList",
          name: "planList",
          component: () =>
            import("../views/Consultantmanagement/planList.vue"),
          meta: {
            title: "任务列表",
            Permission: false,
          },
        },
        {
          path: "planObjectList",
          name: "planObjectList",
          component: () =>
            import("../views/Consultantmanagement/planObjectList.vue"),
          meta: {
            title: "访问对象列表",
            Permission: false,
          },
        },
        {
          path: "userPlanList",
          name: "userPlanList",
          component: () =>
            import("../views/Consultantmanagement/userPlanList.vue"),
          meta: {
            title: "访问对象列表",
            Permission: false,
          },
        },
        {
          path: "BusinessSettings",
          name: "BusinessSettings",
          component: () =>
            import("../views/Consultantmanagement/BusinessSettings.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "planEndList",
          name: "planEndList",
          component: () =>
            import("../views/Consultantmanagement/planEndList.vue"),
          meta: {
            title: "遗忘提醒列表",
            Permission: false,
          },
        },
        {
          path: "Morecourses",
          name: "Morecourses",
          component: () =>
            import("../views/Consultantmanagement/Morecourses.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "appdownload",
          name: "appdownload",
          component: () => import("../views/appManage/appdownload.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "appVersionList",
          name: "appVersionList",
          component: () => import("../views/appManage/appVersionList.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },

        {
          path: "picUpdate",
          name: "picUpdate",
          component: () => import("../views/appManage/picUpdate.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "coupExchange",
          name: "coupExchange",
          component: () =>
            import("../views/Cardandvouchermanagement/coupExchange.vue"),
          meta: {
            title: "兑换码管理",
            Permission: false,
          },
        },
        {
          path: "draw",
          name: "draw",
          component: () => import("../views/draw/draw.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "groupingActivity",
          name: "groupingActivity",
          component: () =>
            import("../views/activityManage/groupingActivity.vue"),
          meta: {
            title: "",
            Permission: false,
          },
        },
        {
          path: "activityCourseManage",
          name: "activityCourseManage",
          component: () =>
            import("../views/activityManage/activityCourseManage.vue"),
          meta: {
            title: "活动课程管理",
            Permission: false,
          },
        },
        {
          path: "giftManage",
          name: "giftManage",
          component: () =>
            import("../views/activityManage/giftManage.vue"),
          meta: {
            title: "活动礼品管理",
            Permission: true,
          },
        },
      
        {
          path: "settlementListCS",
          name: "settlementListCS",
          component: () =>
            import("../views/customerServiceManage/settlementList.vue"),
          meta: {
            title: "核销列表",
            Permission: true,
          },
        },
        {
          path: "courseSettleManage",
          name: "courseSettleManage",
          component: () =>
            import("../views/customerServiceManage/courseSettleManage.vue"),
          meta: {
            title: "课程核销管理",
            Permission: true,
          },
        },
        {
          path: "generateCourse",
          name: "generateCourse",
          component: () =>
            import("../views/combinationCourse/generateCourse.vue"),
          meta: {
            title: "发布课程",
            Permission: false,
          },
        },
        {
          path: "receiveOrderManage",
          name: "receiveOrderManage",
          component: () =>
            import("../views/combinationCourse/receiveOrderManage.vue"),
          meta: {
            title: "接单管理",
            Permission: true,
          },
        },
        {
          path: "transferNeed",
          name: "transferNeed",
          component: () =>
            import("../views/combinationCourse/transferNeed.vue"),
          meta: {
            title: "转单审核管理",
            Permission: true,
          },
        },
        {
          path: "venueNeed",
          name: "venueNeed",
          component: () =>
            import("../views/combinationCourse/venueNeed.vue"),
          meta: {
            title: "找场地审核管理",
            Permission: true,
          },
        },
        {
          path: "combinationCourseList",
          name: "combinationCourseList",
          component: () =>
            import("../views/combinationCourse/combinationCourseList.vue"),
          meta: {
            title: "需求列表",
            Permission: true,
          },
        },
        {
          path: "userNeedList",
          name: "userNeedList",
          component: () =>
            import("../views/combinationCourse/userNeedList.vue"),
          meta: {
            title: "用户需求列表",
            Permission: true,
          },
        },
        {
          path: "needCourseList",
          name: "needCourseList",
          component: () =>
            import("../views/combinationCourse/needCourseList.vue"),
          meta: {
            title: "教练课包列表",
            Permission: true,
          },
        },
        {
          path: "needOrderManageList",
          name: "needOrderManageList",
          component: () =>
            import("../views/combinationCourse/needOrderManageList.vue"),
          meta: {
            title: "需求订单列表",
            Permission: true,
          },
        },
        {
          path: "timHome",
          name: "timHome",
          component: () => import("../views/timMessage/timHome.vue"),
          meta: {
            title: "消息",
            Permission: false,
            keepAlive:false
          },
        },
        {
          path: "coachList",
          name: "coachList",
          component: () => import("../views/coachManage/coachList.vue"),
          meta: {
            title: "教练列表",
            Permission: true,
            keepAlive:true
          },
        },
        {
          path: "coachIdentity",
          name: "coachIdentity",
          component: () => import("../views/coachManage/coachIdentityManage.vue"),
          meta: {
            title: "会员开通记录",
            Permission: true,
            keepAlive:true
          },
        },
        
        {
          path: "coachViolation",
          name: "coachViolation",
          component: () => import("../views/coachManage/coachViolation.vue"),
          meta: {
            title: "教练违规列表",
            Permission: true,
          },
        },
        {
          path: "coachNewModeJoin",
          name: "coachNewModeJoin",
          component: () => import("../views/coachManage/coachNewModeJoin.vue"),
          meta: {
            title: "新模式意向教练",
            Permission: true,
          },
        },
        {
          path: "coachMechanism",
          name: "coachMechanism",
          component: () => import("../views/coachManage/coachMechanism.vue"),
          meta: {
            title: "签约教练店铺",
            Permission: true,
          },
        },
        {
          path: "demandDetails",
          name: "demandDetails",
          component: () => import("../views/demandManage/demandDetails.vue"),
          meta: {
            title: "信息需求详情",
            Permission: false,
          },
        },
        {
          path: "demandList",
          name: "demandList",
          component: () => import("../views/demandManage/demandList.vue"),
          meta: {
            title: "转让发布列表",
            Permission: true,
          },
        },
        {
          path: "hireList",
          name: "hireList",
          component: () => import("../views/demandManage/hireList.vue"),
          meta: {
            title: "招聘发布列表",
            Permission: true,
          },
        },
        {
          path: "createDemand",
          name: "createDemand",
          component: () => import("../views/demandManage/createDemand.vue"),
          meta: {
            title: "信息发布",
            Permission: false,
          },
        },
        {
          path: "tcreateTask",
          name: "tcreateTask",
          component: () => import("../views/hmTaskManage/task/createTask.vue"),
          meta: {
            title: "创建任务",
            Permission: false,
          },
        },
        {
          path: "ttaskList",
          name: "ttaskList",
          component: () => import("../views/hmTaskManage/task/taskList.vue"),
          meta: {
            title: "任务列表",
            Permission: true,
          },
        },
        {
          path: "coachMeDetails",
          name: "coachMeDetails",
          component: () => import("../views/coachManage/coachMechanismDetails.vue"),
          meta: {
            title: "签约教练店铺详情",
            Permission: false,
          },
        },
        {
          path: "coachCard",
          name: "coachCard",
          component: () => import("../views/coachManage/coachCard.vue"),
          meta: {
            title: "教练信息审核",
            Permission: true
          },
        },
         {
          path: "coachAuth",
          name: "coachAuth",
          component: () => import("../views/coachManage/coachApplyAuthList.vue"),
          meta: {
            title: "教练认证审核",
            Permission: true
          },
        },
        {
          path: "coachLogout",
          name: "coachLogout",
          component: () => import("../views/coachManage/coachLogout.vue"),
          meta: {
            title: "教练注销",
            Permission: true
          },
        },
        {
          path: "coachBond",
          name: "coachBond",
          component: () => import("../views/coachManage/coachBond.vue"),
          meta: {
            title: "教练保证金",
            Permission: true
          },
        },
        {
          path: "coach_invitation",
          name: "coach_invitation",
          component: () => import("../views/coachManage/coach_invitation.vue"),
          meta: {
            title: "教练邀请",
            Permission: true
          },
        },
        {
          path: "coach_vip",
          name: "coach_vip",
          component: () => import("../views/coachManage/coach_vip.vue"),
          meta: {
            title: "Vip教练",
            Permission: true,
          },
        },
        {
          path: "coach_arrears",
          name: "coach_arrears",
          component: () => import("../views/coachManage/coach_arrears.vue"),
          meta: {
            title: "教练欠款",
            Permission: true,
          },
        },
        {
          path: "coachDetails",
          name: "coachDetails",
          component: () => import("../views/DetailsInfo/coachDetails.vue"),
          meta: {
            title: "教练详情",
            Permission: false,
          },
        },
        {
          path: "Coachmodification",
          name: "Coachmodification",
          component: () => import("../views/DetailsInfo/Coachmodification.vue"),
          meta: {
            title: "修改教练信息",
            Permission: false,
          },
        },
        {
          path: "coachCardDetails",
          name: "coachCardDetails",
          component: () => import("../views/DetailsInfo/coachCardDetails.vue"),
          meta: {
            title: "教练认证详情",
            Permission: false,
          },
        },
        {
          path: "courseNeedDetails",
          name: "courseNeedDetails",
          component: () => import("../views/DetailsInfo/courseNeedDetails.vue"),
          meta: {
            title: "需求详情",
            Permission: false,
          },
        },
        {
          path: "noticeEdit",
          name: "noticeEdit",
          component: () => import("../views/noticeManage/edit.vue"),
          meta: {
            title: "添加咨询",
            Permission: false,
          },
        },
        {
          path: "noticeIndex",
          name: "noticeIndex",
          component: () => import("../views/noticeManage/index.vue"),
          meta: {
            title: "咨询列表",
            Permission: false,
          },
        },
        {
          path: "test",
          name: "test",
          component: () => import("../views/test.vue"),
          meta: {
            title: "",
            Permission: false,
            
          },
        },
        {
          path: "tim",
          name: "tim",
          component: () => import("../views/timManage/index.vue"),
          meta: {
            title: "即时通讯",
            Permission: false,
          },
        },
        // {
        //   path: '15_3',
        //   name: 'userCard',
        //   component: () => import('../views/accountManage/userCard.vue')
        // }
        // {path:'4_2/userDetails'}
      ],
    },
    {
      path: "/403",
      name: "403",
      component: () => import("../views/error/403.vue"),
      meta: {
        title: "",
        Permission: false,
      },
    },
    {
      path: "*",
      component: () => import("../views/error/404.vue"),
      meta: {
        title: "",
        Permission: false,
      },
    },
  ],
});

export default router;

<template>
  <!-- 商品新增，修改 -->
  <div>
    <el-form :model="form" label-width="120px">
      <el-form-item label="商品名称" required>
        <el-input v-model="form.title" placeholder="商品名称"></el-input>
      </el-form-item>
      <el-form-item label="价格" required>
        <el-input v-model="form.price" placeholder="价格"></el-input>
      </el-form-item>
      <el-form-item label="封面">
        <avatar-uploader
          ref="imgs"
          @getImgUrl="(v) => (form.image = v)"
          :url="form.image"
        >
        </avatar-uploader>
      </el-form-item>
      <el-form-item label="" v-if="!id">
        <el-button type="primary" @click="next">下一步</el-button>
      </el-form-item>
      <el-form-item label="" v-if="id">
        <el-button type="primary" v-loading="loading" @click="updateForm"
          >更新</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
      temp: {},
      skus: [],
        loading:false,
        id:''
    };
  },
  watch: {
    info: {
      handler: function (nv) {
        if (nv) {
          this.form = { ...nv[0] };
          this.temp = { ...nv[0] };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    next() {
      if(!this.form.title){
        this.$message('请填写商品名称')
        return
      }
      this.$emit("next", { next: "spec", data: [this.form] });
    },
    updateForm() {
      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.loading = true;
      let url  = '/user/productSku/insert'
      let params = {
          ...data,
          p_id:this.id
        }
        if(this.form.id){
            params.id = this.form.id
            url  = '/user/productSku/update'
        }
      this.$axios({
        url: url,
        method: "post",
        data: params
      })
        .then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          if(!this.form.id){
             this.form.id = res.data.data
        }
          this.temp = { ...this.form };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
<template>
  <!-- 商品新增，修改 -->
  <div>
    <el-form :model="form" ref="formp" :rules="rules" label-width="120px">
      <el-form-item label="上架模块" required prop="ilk">
        <el-select v-model="form.ilk" >
          <el-option
            v-for="(item, i) in ilkList"
            :key="i"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="所属店铺" required>
        <cooperatorSelect :info="shopName" :condition="{type:'shop'}" @change="changeCoop"></cooperatorSelect>
        <el-link type="primary" @click="toAddShop">新增店铺</el-link>
      </el-form-item>
       <el-form-item label="收货地址" required prop="address_if">
          <el-radio-group v-model="form.address_if">
          <el-radio :label="true">需要填写</el-radio>
          <el-radio :label="false">不需要填写</el-radio>
        </el-radio-group>
      </el-form-item>
       <el-form-item label="付款方式" required prop="pay_way">
          <el-radio-group v-model="form.pay_way">
          <el-radio :label="1">线上付款</el-radio>
          <el-radio :label="2">线下</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签">
        <el-tag
        @click="changeTag(tag)"
        v-for="(tag,i) in tags"
        :key="i"
        :effect="selectTags.includes(tag) ? 'dark' : 'plain' ">
            {{ tag }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+标签</el-button>
      </el-form-item>

      <el-form-item label="" v-if="!id">
        <el-button type="primary" @click="save">添加商品</el-button>
      </el-form-item>
      <el-form-item label="" v-if="id">
             <el-button type="primary" v-loading="loading" @click="updateForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { ilkList } from "../type.js";
import { ObjectChangedValue } from "@/js/utils.js";
import { platFormRule } from "./rules.js";
import cooperatorSelect from '../../../../components/select/cooperatorSelect.vue';
export default {
     props:{
        info : {
            type:Object,
            default:()=>{return {}}
        }
    },
    components:{
      cooperatorSelect
    },
  data() {
    return {
        id:'',
        rules: platFormRule,
        ilkList:ilkList,
        form: {
          address_if:true,
          pay_way:1
        },
        selectTags:[],
        tags:[],
        loading:false,
        inputVisible: false,
        inputValue: '',
        shopName:''
    };
  },
   watch:{
    info:{
        handler:function(nv){
            if(nv.id){
                if(nv.tags){
                    this.selectTags = nv.tags.split(',')
                }
                this.form = {...nv}
                this.temp = {...nv}
                if(nv.map && nv.map.shop){
                  this.shopName = nv.map.shop.name
                }
            }
        },
        immediate:true,
        deep:true
    }
  },
  mounted() {
    this.id = this.$route.query.id
  },


  methods: {
    toAddShop(){
      this.$router.push({name:'coopShop'})
    },
      changeCoop(v){
          this.form.shop_id = v.id
      },
      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.tags.push(inputValue);
          this.selectTags.push(inputValue)
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
     //选择标签
    changeTag(v){
            const i = this.selectTags.indexOf(v)
            if(i == -1){
                this.selectTags.push(v)
            }else{
                this.selectTags.splice(i,1)
            }
		},
    save() {
        if(!this.form.shop_id){
            this.$message('请选择店铺')
            return
        }
        this.$refs.formp.validate((valid) => {
            if (valid) {
              this.$emit("save", {data: this.form });
            }
        }) 
      
    },
    updateForm() {
      if(!this.form.shop_id){
            this.$message('请选择店铺')
            return
        }
        this.form.tags = this.selectTags.join(',')
      this.$refs.formp.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true
          this.$axios({
            url: "/user/product/update",
            method: "post",
            data: {
              id: this.id,
              ...data,
            },
          }).then((res) => {
            this.$notify({
              message: "更新成功",
              duration: 2000,
              type: "success",
            });
            this.temp = {...this.form}
            this.loading = false
          }).catch(()=>{
            this.loading = false
          })
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
</style>
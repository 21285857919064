export const statusList = [
    {
      value: 0,
      label: "待上架",
    },
    {
      value: 1,
      label: "已上架",
    },
    {
      value: 2,
      label: "已下架",
    }
  ]

  export const ilkList = [
    {
      value: 'shop',
      label: "商城",
    },
    {
      value: 'cert',
      label: "考证",
    },
    {
      value: 'storeCert',
      label: "办证",
    },
    {
      value: 'promote',
      label: "培训提升",
    },
    {
      value: 'insure',
      label: "保险",
    },
    {
      value: 'clearService',
      label: "场地清理",
    },
    {
      value: 'union',
      label: "联营社",
    },
  ]

  export const mainList = [
    {
      value: '商城',
      label: "商城",
    },
    {
      value: '考证',
      label: "考证",
    },
    {
      value: '培训',
      label: "培训",
    },
    {
      value: '办证',
      label: "办证",
    },
    {
      value: '联营社',
      label: "联营社",
    },
  ]

  export const typeList = [
    {
      value: 'product',
      label: "商品",
    },
    {
      value: 'service',
      label: "服务",
    },
    {
      value: 'course',
      label: "课程",
    },
    {
      value: 'mec_union',
      label: "门店联营",
    },
    {
      value: 'coop',
      label: "类目联营",
    }
  ]

  /**
   * 获取需求类型全名称
   * @param {*} row 
   * @returns 
   */
 export function getFullTypeLabel(row) {
    return `${getLabel(createTypeList,row.create_type)}${getLabel(orderTypeList,row.order_type)}${getLabel(typeList,row.type)}`;
  }


  /**
   * 
   * @param {属性映射列表} list 
   * @param 属性值 v 
   * @returns 
   */
  export function getLabel(list,v){
    for (let i = 0; i < list.length; i++) {
        if (list[i].value === v) {
          return list[i].label;
        }
      }
      return v;
  }



var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('content-title',{attrs:{"nav":_vm.nav}}),_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"商品名称"}},[_c('el-input',{attrs:{"placeholder":"输入商品名称"},model:{value:(_vm.query.name),callback:function ($$v) {_vm.$set(_vm.query, "name", $$v)},expression:"query.name"}})],1),_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-select',{model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},_vm._l((_vm.typeList),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{model:{value:(_vm.query.status),callback:function ($$v) {_vm.$set(_vm.query, "status", $$v)},expression:"query.status"}},_vm._l((_vm.statusList),function(item,i){return _c('el-option',{key:i,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("新增")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.$getLabel(_vm.typeList,row.type)))])]}},{key:"ilk",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm.$getLabel(_vm.ilkList,row.ilk)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("待上架")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("上架中")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("已下架")]):_vm._e()]}},{key:"handle",fn:function(ref){
var row = ref.row;
return [(row.type != 'consume')?_c('div',[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.update(row)}}},[_vm._v("编辑")]),(row.status != 1)?_c('el-button',{attrs:{"size":"small","type":"success"},on:{"click":function($event){return _vm.updateStatus(row, 1)}}},[_vm._v("上架")]):_vm._e(),(row.status === 1)?_c('el-button',{attrs:{"size":"small","type":"warning"},on:{"click":function($event){return _vm.updateStatus(row, 2)}}},[_vm._v("下架")]):_vm._e()],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="手机号">
          <el-input v-model="formInline.region"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status" clearable>
            <el-option label="已支付" :value="2"></el-option>
            <el-option label="待支付" :value="0"></el-option>
            <el-option label="已取消" :value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单渠道">
          <el-select v-model="source" clearable>
            <el-option
              v-for="(item, index) in sourceList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="timeDate"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="contentList">
      <el-table
        v-loading="is_loading"
        :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        highlight-current-row
      >
      <el-table-column align="center" label="订单号" width="150">
          <template v-slot="{ row }">
            <p class="textell">{{ row.flowing_no }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="商品名称">
          <div>消费金</div>
        </el-table-column>
        <el-table-column
          prop="course_num"
          align="center"
          label="数量"
        ></el-table-column>
        <el-table-column align="center" label="用户昵称">
          <template v-slot="{ row }">
            <link-to :to="{ name: 'userDetails', params: { id: row.user_id } }">{{ row.map.userinfo ? row.map.userinfo.nick_name :'' }}</link-to>
          </template>
        </el-table-column>
        <el-table-column
          prop="amount"
          align="center"
          label="付款金额"
        ></el-table-column>
        <el-table-column
          prop="pay_type"
          align="center"
          label="付款方式"
          :formatter="Paytype"
        ></el-table-column>
        <el-table-column
          prop="source"
          align="center"
          label="订单渠道"
          :formatter="Paysource"
        ></el-table-column>
        <el-table-column
          prop="finished_time"
          align="center"
          label="下单时间"
          width="200px"
        ></el-table-column>
        <el-table-column prop="city" align="center" label="线下收款图片">
          <template slot-scope="{ row }">
            <div class="flex-col">
              <el-image
                v-if="row.offline_collection_img"
                style="width: 88px; height: 49px"
                :src="row.offline_collection_img"
                :fit="cover"
                :preview-src-list="[row.offline_collection_img]"
              ></el-image>
              <!-- <span v-if="row.status == 0" style="color: #409eff" @click="openCollection(row)"
                >补录</span
              > -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 2" style="color: #67c23a"
              >已付款</span
            >
            <span v-if="scope.row.status == 4" style="color: #f56c6c"
              >退款</span
            >
            <span v-if="scope.row.status == 1" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 0" style="color: #409eff"
              >待付款</span
            >
            <span v-if="scope.row.status == 8" style="color: #409eff"
              >已取消</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="{ row }">
            <link-to :to="'orderDetails/' + row.id">
              <p>详情</p>
            </link-to>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :visible.sync="dialogVisible" :destroy-on-close="true" width="400px" title="补录线下收款">
      <el-form>
        <el-form-item label="收款金额">
          <el-input v-model="price" placeholder="请输入收款金额"></el-input>
        </el-form-item>
        <el-form-item label="线下收款图片">
          <avatar-uploader @getImgUrl="getpic" :url="pic"></avatar-uploader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="collection"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 分页 -->
    <paging-fy
      @currentPageChange="changeCurrentPage"
      :currentPage="currentPage"
      :total="total"
    ></paging-fy>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      price:'',
      curOrder:{},
      nav: { firstNav: "财务管理中心", secondNav: "课程订单" },
      list: [],
      is_loading: true,
      finshed: true,
      dialogVisible: false,
      total: 0,
      timeDate: [],
      formInline: {
        user: "",
        region: "",
      },
      sourceList: [
        {
          value: "sport",
          label: "微信小程序",
        },
        {
          value: "dy_sport",
          label: "抖音小程序",
        },
        {
          value: "android",
          label: "安卓APP",
        },
        {
          value: "ios",
          label: "ios APP",
        },
        {
          value: "pc",
          label: "pc",
        },
      ],
      pic: "",
      status: "",
      source: "",
      currentPage: 1,
    };
  },

  mounted() {
    this.getRefoundList();
  },

  methods: {
    collection(){
      if(this.price == '' || this.pic == ''){
        this.$message('请填写收款资料')
        return
      }
      if(this.price > this.curOrder.amount){
        this.$message('补录金额不能大于订单金额')
        return
      }
      this.$axios({
        url:'user/pay/offlineCollection',
        data:{
          flowing_no : this.curOrder.flowing_no,
          offline_collection : this.price,
          offline_collection_img : this.pic
        },
        method:'post'
      }).then(res=>{
        this.$message(res.data.message)
        if(res.data.code == 0){
          this.getRefoundList();
           this.dialogVisible = false
        }
      })
    },
    openCollection(row){
       this.dialogVisible = true
       this.curOrder = row
    },
    coupontype(row) {
      let type = row.studycard_id;
      if (type == 49) {
        return "天赋课程券";
      } else if (type == "44") {
        return "消费券";
      } else {
        return "优惠券";
      }
    },
    Paytype(row, column) {
      let type = row.pay_type;
      if (type == "ali") {
        return "支付宝";
      } else if (type == "wx") {
        return "微信";
      } else if (type == "point") {
        return "权益点";
      } else if (type == "dy") {
        return "抖音";
      } else {
        return type;
      }
    },
    Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else {
        return source;
      }
    },
    search() {
      this.currentPage = 1;
      this.list = [];
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    //查询订单
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/rechargerecord/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        rcharge_type: "consume",
        is_teach_paypal: true,
      };
      if (this.status !== "") {
        params.status = this.status;
      }
      if (this.source) {
        if (this.source == "dy_sport") {
          params.source = "sport";
          params.pay_type = "dy";
        } else {
          params.source = this.source;
        }
      }

      if (this.timeDate && this.timeDate.length == 2) {
        params.start_time = this.timeDate[0]; //时间
        params.end_time = this.timeDate[1];
      }
      if (this.formInline.region) {
        params.login_name = this.formInline.region; //手机号
      }
      if (this.formInline.user) {
        params.mechanism_name = this.formInline.user; //机构名
      }
      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.total = res.data.data.total;
          this.list = res.data.data.rows;
          this.is_loading = false;
        });
    },
    getpic(v) {
      this.pic = v;
    },
  },
};
</script>
  
  <style lang="less" scoped>
</style>
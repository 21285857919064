<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option :label="item.label" v-for="(item,i) in  auctionStatusList" :value="item.value" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
    <template #price="{ row }">
        <div>
         <p> 起拍：{{row.start_price}}</p>
         <p> 加价幅度{{row.add_step}}</p>
        </div>
      </template>
      <template #type="{ row }">
        <div>
          {{$getLabel(targetTypeList,row.target_type)}}
        </div>
      </template>
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.auction_start_time }}</p>
          <p>结束:{{ row.auction_end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" >  {{$getLabel(auctionStatusList,row.status)}}</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="toDetail(row)"
            >详情</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
import {auctionStatusList,targetTypeList} from './need.js'
export default {
  data() {
    return {
      targetTypeList:targetTypeList,
      auctionStatusList:auctionStatusList,
      DataList: [],
      Option: [
        { name: "拍卖标题", value: "title" },
        { name: "拍卖金额", value: "price", type: "custom" },
        { name: "拍品类型", value: "type", type: "custom" },
        { name: "状态", value: "status", type: "custom" },
        { name: "拍卖时间", value: "activity_time", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    toDetail(row) {
      this.$router.push({ name: "auctionDetails", query: { id: row.id } });
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/auction/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
export const statusList = [
    {
      value: 0,
      label: "待审核",
    },
    {
      value: 1,
      label: "通过",
    },
    {
      value: 2,
      label: "拒绝",
    },
    {
      value: 3,
      label: "上架中",
    },
    {
      value: 4,
      label: "已下架",
    },
    {
      value: 5,
      label: "接单中",
    },
    {
      value: 6,
      label: "已接单",
    },
    {
      value: 7,
      label: "上课中",
    },
    {
      value: 8,
      label: "已完成",
    },
    {
      value: 11,
      label: "已取消",
    },
    {
      value: 12,
      label: "售后中",
    },
    {
        value: 20,
        label: "待支付",
      },
  ]

  export const createTypeList = [
    {
      value: 1,
      label: "后台",
    },
    {
      value: 2,
      label: "教练",
    },
    {
      value: 3,
      label: "用户",
    },
    {
      value: 4,
      label: "公会",
    },
    {
        value: 5,
        label: "机构",
      },
  ]

  export const orderTypeList = [
    {
      value: 0,
      label: "常规",
    },
    {
      value: 1,
      label: "校园",
    },
    {
      value: 2,
      label: "转单",
    }
  ]

  export const typeList = [
    {
      value: 'need',
      label: "需求",
    },
    {
      value: 'order',
      label: "订单",
    },
    {
      value: 'course',
      label: "课程",
    },
    {
      value: 'venue',
      label: "场地",
    }
  ]


  export const auctionPriceTypeList = [
    {
      value: 1,
      label: "价格",
      suf:'元'
    },
    {
      value: 2,
      label: "服务费",
      suf:'%'
    },
  ]

  export const targetTypeList = [
    {
      value: 'need',
      label: "需求",
    }
  ]

  export const auctionStatusList = [
    {
      value: 0,
      label: "未开始",
    },
    {
      value: 1,
      label: "竞拍中",
    },
    {
      value: 4,
      label: "竞拍结束",
    },
    {
      value: 5,
      label: "尾款待支付",
    },
    {
      value: 6,
      label: "拍卖成功",
    },
    {
      value: 7,
      label: "流拍",
    }
  ]

  /**
   * 获取需求类型全名称
   * @param {*} row 
   * @returns 
   */
 export function getFullTypeLabel(row) {
    return `${getLabel(createTypeList,row.create_type)}${getLabel(orderTypeList,row.order_type)}${getLabel(typeList,row.type)}`;
  }


  /**
   * 
   * @param {属性映射列表} list 
   * @param 属性值 v 
   * @returns 
   */
  export function getLabel(list,v){
    for (let i = 0; i < list.length; i++) {
        if (list[i].value === v) {
          return list[i].label;
        }
      }
      return v;
  }



//人群数量
const ageData = [{
    name: '成人',
    value: 10,
},{
    name: '青少年',
    value: 20
}]
//性别数量
const sexData = [{
    name: '男',
    value: 12,
},{
    name: '女',
    value: 18
}]
//教练数量
const coachCount = {
    total:156,
    today:14
}
//用户预约人数
const userCount = {
    today:30,
    cur:12,
}

//顶部数据
const topData = [
    {
        name: '当前总人数',
        value: 68,
    },{
        name: '今日预约人数',
        value: 132
    },{
        name: '今日课程数量',
        value: 35,
    },{
        name: '已上课课程',
        value: 12
    }
]
//收入数据
const incomeData = [
    {
        name: '总收入(元)',
        value: 848500,
    },{
        name: '今日收入(元)',
        value: 5650
    },{
        name: '今年收入',
        value: 458400,
    }
]

//时间段预约数量
const timeCountData = {
    x:['10:00','11:00','13:00','14:00','17:00','18:00','19:00','20:00','21:00'],
    y:[{name:'用户预约',data:[0,0,0,2,1,5,10,6,1]},{name:'教练预约',data:[0,1,3,0,1,2,5,2,0]}]
}

//教室预约数量
const areaCountData = {
    x:['健身房','瑜伽教室','艺术教室','文化教室','篮球场','体适能区','轮滑区','武术区','交流中心'],
    y:[{name:'用户',data:[12,4,5,6,4,5,7,3,2]},{name:'教练',data:[2,1,3,2,3,4,2,2,0]}]
}

const areaTotalCountData = {
    x:[],
    y:[{name:'用户',data:[47]},{name:'教练',data:[12]}]
}

//教室数据
const roomData = [
    {
        name: '健身房',
        use:42,
        income: 2300,
    },{
        name: '瑜伽教室',
        use:21,
        income: 1523,
    },{
        name: '艺术教室',
        use:15,
        income: 965,
    },{
        name: '文化教室',
        use:36,
        income: 594,
    },{
        name: '篮球场',
        use:24,
        income: 1523,
    },{
        name: '体适能教室',
        use:54,
        income: 1410,
    },{
        name: '轮滑教室',
        use:36,
        income: 900,
    },{
        name: '武术教室',
        use:25,
        income: 890,
    },{
        name: '游玩区',
        use:42,
        income: 700,
    },{
        name: '交流中心',
        use:36,
        income: 0,
    }
]

const roomList =  [
    {
        name: '健身房',
        value:'3'
    },{
        name: '瑜伽教室-1',
        value:'3'
    },{
        name: '艺术教室-1',
        value:'2'
    },{
        name: '文化教室-1',
        value:'2'
    },{
        name: '文化教室-2',
        value:'2'
    },{
        name: '篮球场',
        value:'2'
    },{
        name: '体适能教室',
        value:'2'
    },
    {
        name: '艺术教室-2',
        value:'2'
    },
    {
        name: '艺术教室-3',
        value:'1'
    },{
        name: '轮滑教室',
        value:'2'
    },{
        name: '武术教室',
        value:'1'
    },{
        name: '瑜伽教室-2',
        value:'2'
    },{
        name: '游玩区',
        value:'2'
    },{
        name: '交流中心',
        value:'1'
    }
]


const areaRowbarData = [
    {
        name: '健身房',
        value: 24,
    },
    {
        name: '篮球场',
        value: 17,
    },
    {
        name: '体适能',
        value: 13,
    },
    {
        name: '瑜伽教室',
        value: 10,
    }, {
        name: '文化教室',
        value: 10,
    }]


//时间段预约数量
const roomIncomeBarData = {
    x:['健身房','篮球场','体适能','瑜伽教室','文化教室'],
    y:[{name:'收入',data:[1320,998,745,710,432]}]
}


const noteList = [
    {name:'19:30 张老师健身房健身基础课开课'},
    {name:'19:00 王老师武术教室武术基础课开课'},
    {name:'19:00 文老师体适能教室体适能课'},
    {name:'18:30 刘老师篮球场篮球提升课开课'}, 
    {name:'18:30 李老师瑜伽教室瑜伽基础课开课'},
    {name:'14:00 张老师健身房健身基础课开课'},
    {name:'14:00 刘老师轮滑基础课开课'},
    {name:'14:00 赵老师体适能基础课开课'}
]

const courseInfoList = [
    {name:'健身基础课',coach:'张老师',time:'19:30-20:30',class:'健身房'},
    {name:'体适能基础课',coach:'王老师',time:'19:00-20:00',class:'体适能教室'},
    {name:'体适能基础课',coach:'刘老师',time:'19:00-20:00',class:'篮球场'},

]

export default {
    ageData,
    sexData,
    coachCount,
    timeCountData,
    userCount,
    topData,
    incomeData,
    areaCountData,
    roomData,
    areaRowbarData,
    roomIncomeBarData,
    roomList,
    noteList,
    courseInfoList,
    areaTotalCountData
}
<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
import * as echarts from "echarts";
import { fontSize } from "@/js/utils";
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    opt: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
         color: [
          "#FFC722",
          "#886EFF",
          "#008DEC",
          "#114C90",
          "#00BFA5",
        ],
      myChart: "",
      serData: [],
      option: {
        title: {
          top: fontSize(0.1),
          text: "",
          textStyle: {
            fontSize: fontSize(0.14),
            color: "#DDEEFF",
          },
        },
        legend: {
          selectedMode: false,
        },
        grid: {
          left: "20%",
          right: "25%",
          bottom: "3%",
          top:'20%',
          containLabel: true,
        },
        xAxis: {
        show: false
    },
        yAxis: [
          {
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            type: "category",
            axisTick: {
              show: false,
            },
            data: [],
            axisLabel: {
                interval: 0,
                color: '#fff',
                fontSize: fontSize(0.12),
            },
           
          },
          {
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            type: "category",
            axisTick: {
              show: false,
            },
            data: [],
            axisLabel: {
                interval: 0,
                color: '#fff',
                fontSize: fontSize(0.12),
                formatter: (value,i) => {
                  return `${value} (${this.serData[i].rate}%)`;
                },
            },
           
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: fontSize(0.12), // 柱子宽度
            label: {
              show: false,
            },
            itemStyle: {
              color: (val) => {
              return this.color[val.dataIndex]
            }
            },
            data: []
          },
        ],
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
     this.option.title.text = this.opt.title || ''
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听
    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      this.$emit("click", e);
    },
    updateOption() {
      this.handlerData();
      this.myChart.setOption(this.option);
    },
    handlerData() {
        let total = 0
      let serData = []
      this.data.forEach(t=>total += Number(t.value))
      this.data.forEach(t=>{
        if(total == 0){
          t.rate = 0
        }else{
          t.rate = (t.value/total*100).toFixed(0)
        }
        
        serData.push(t)
      })
      this.serData = serData
      this.option.yAxis[0].data = this.data.map((t) => t.name);
      this.option.series[0].data = this.data.map((t) => t.value);
      this.option.yAxis[1].data = this.data.map((t) => t.value);

    },
    getEchartData() {
      this.handlerData();
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="less" scoped>
.a {
  color: #a71a2b;
}
</style>
<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="违规类型">
        <el-select v-model="type" placeholder="选择违规类型" clearable>
          <el-option
            v-for="(v, i) in typeMap"
            :key="i"
            :label="v.label"
            :value="v.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="举报来源">
        <el-select v-model="submint_type" placeholder="选择举报来源" clearable>
          <el-option
            v-for="(v, i) in submitTypeMap"
            :key="i"
            :label="v.label"
            :value="v.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
          <link-to :to="{ name: 'userDetails', query: { id: row.violation_user_id} }">
            {{ row.map.user ? row.map.user.nick_name :'' }}</link-to
          >
        </div>
      </template>

      <template #context="{ row }">
        <div>
          <div class="ell">{{ row.context }}</div>
        </div>
      </template>
      <template #pic="{ row }">
        <div v-if="row.pic">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.pic.split(',')[0]"
          ></el-image>
        </div>
      </template>
      <template #type="{ row }">
        <div>
          {{ getLabel("typeMap", row.type) }}
        </div>
      </template>
      <template #submit_type="{ row }">
        <div>
          {{ getLabel("submitTypeMap", row.submit_type) }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag v-if="row.status === 0" type="info">待处理</el-tag>
          <el-tag v-if="row.status === 1" type="success">已处理</el-tag>
        </div>
      </template>
    </auto-table>

    <!-- 处理违规 -->
    <el-dialog title="处理违规" :visible.sync="handlerShow" width="50%">
      <el-form>
        <el-form-item label="违规类型">
          {{ getLabel("typeMap", changeRecord.type) }}
        </el-form-item>
        <el-form-item label="违规内容">
          {{ changeRecord.context }}
        </el-form-item>
        <el-form-item label="图片说明">
          <div v-if="changeRecord.pic">
            <el-image
              v-for="(url, i) in changeRecord.pic.split(',')"
              :key="i"
              :preview-src-list="changeRecord.pic.split(',')"
              style="width: 100px; height: 100px"
              :src="url"
            ></el-image>
          </div>
          <div v-else>无</div>
        </el-form-item>
        <el-form-item label="处理方式">
          <el-radio v-model="form.type" label="none">无惩罚</el-radio>
          <el-radio v-model="form.type" label="muted">禁言</el-radio>
        </el-form-item>
        <el-form-item label="惩罚时间">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="惩罚说明">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="处理违规后给教练发送的违规提示消息"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitHandler">处理违规</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlerShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "违规人", value: "name", type: "custom" },
        { name: "违规内容", value: "context", type: "custom" },
        { name: "图片", value: "pic", type: "custom" },
        { name: "类型", value: "type", type: "custom" },
        { name: "提交方", value: "submit_type", type: "custom" },
        { name: "类型", value: "status", type: "custom" },
        { name: "提交时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      submint_type: "",
      typeMap: [
       {
						value: 'infraction',
						label: '违法违规行为',
					},
					{
						value: 'fraud',
						label: '涉嫌诈骗'
					},
					{
						value: 'untruth',
						label: '招聘和职位信息不符'
					},
					{
						value: 'attack',
						label: '人身攻击/歧视'
					},
					{
						value: 'charge',
						label: '向求职者收费'
					},
					{
						value: 'others',
						label: '其他违规行为'
					}
      ],
      submitTypeMap: [
        {
          label: "系统检测",
          value: "system",
        },
        {
          label: "用户提交",
          value: "user",
        },
      ],
      coachid:'',
      changeRecord: {},
      handlerShow: false,
      form: {},
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    submitHandler(){
         if(!this.form.type){
             this.$message("未选择处理方式")
                return
         }
         let data = {
            handler_id:this.adminInfo.id,
              handler_name:this.adminInfo.name,
              vid:this.changeRecord.id,
              coach_id:this.changeRecord.coach_id,
              type:this.form.type,
              remark:this.form.remark
        }

        if(this.form.type == 'muted'){
            if(this.form.time == null || this.form.time.length == 0 ){
                this.$message("请选择处罚时间")
                return
            }else{
                data.start_time = this.form.time[0]
                 data.end_time = this.form.time[1]
            }
        }
       
        this.$post("/user/violationHandler/insert",data).then(res=>{
                this.form = {}
                this.handlerShow = false
                this.getList(1)
        })
    },
    openHandler(row) {
      this.changeRecord = row;
      this.handlerShow = true;
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/violation/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type: this.type || null,
          coach_id:this.coachid || null,
          submint_type : this.submint_type || null,
          violation_type:'user'
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style scoped>
.ell {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
<template>
<div class="vis-container">
    <div class="vis-grid">
         <div class="vis-left"></div>

    <div class="vis-center">

        <div class="vis-map">
            <mapEcharts></mapEcharts>
        </div>
    </div>

    <div class="vis-right"></div>
    </div>
   
</div>
</template>

<script>
import mapEcharts from './components/mapEcharts.vue';


export default {
  components: {
    mapEcharts,
  },
  data() {
    return {
      
    };
  },

  mounted() {},
  methods: {
     
  },
};
</script>
<style lang="less" scoped>
.vis-container{
    width: 100vw;
    height: 100vh;
    background-color: rgb(171, 188, 224);
}

.vis-grid{
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100vh;
}
.vis-left{
    background-color: rgb(212, 162, 162);
}
.vis-center{
    background-color: rgb(63, 150, 82);

}
.vis-right{
    background-color: rgb(170, 87, 143);

}
</style>